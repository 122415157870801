import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { RetryLink } from 'apollo-link-retry'

import { getTokenSilently, getImpersonator } from '../auth0'
import { getPlatform } from '../utils/utils'

const API_URL = process.env.REACT_APP_SUBSCRIPTION_API_URL
const USER_URL = `${API_URL}/graphql/user`
const MENU_SERVICE_URL = process.env.REACT_APP_MENU_SERVICE_URL

const subscriptionBackHttpLink = createHttpLink({
  uri: USER_URL,
  credentials: 'include'
})

const menuServiceHttpLink = createHttpLink({
  uri: MENU_SERVICE_URL,
  credentials: 'include'
})

const authLink = setContext(async (_, { headers }) => {
  const impersonator = getImpersonator()
  if (impersonator) {
    return {
      headers: {
        ...headers,
        'x-cook-impersonation-secret': impersonator.secret,
        'x-cook-impersonation-email': impersonator.email
      }
    }
  }

  const token = await getTokenSilently()
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : ''
    }
  }
})

const customHeaders = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'cu-platform': getPlatform()
    }
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 500,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: error => !!error
  }
})

const subscriptionBackClient = new ApolloClient({
  link: retryLink
    .concat(authLink)
    .concat(customHeaders)
    .concat(subscriptionBackHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})
const menuServiceClient = new ApolloClient({
  link: retryLink
    .concat(authLink)
    .concat(customHeaders)
    .concat(menuServiceHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

const publicMenuServiceClient = new ApolloClient({
  link: retryLink.concat(customHeaders).concat(menuServiceHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  query: (query, variables = {}, useMenuService = false) => {
    return (useMenuService ? menuServiceClient : subscriptionBackClient).query({
      query: gql(query),
      variables
    })
  },
  publicQuery: (query, variables = {}) =>
    publicMenuServiceClient.query({
      query: gql(query),
      variables
    }),
  mutation: (mutation, variables = {}, useMenuService = false) =>
    (useMenuService ? menuServiceClient : subscriptionBackClient).mutate({
      mutation: gql(mutation),
      variables
    })
}
