import { useFlag, useVariant } from '@unleash/proxy-client-react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FF_CALENDAR_VIEW } from 'src/constants/feature-flags'
import { useTracking } from 'src/hooks/useTracking'
import { getSubscriptionInfo } from 'src/modules/MyAccount/selectors'

export function useTrackCalendarViewExperimentViewed() {
  const subscription = useSelector(state => getSubscriptionInfo(state))
  const isExperimentEnabled = useFlag(FF_CALENDAR_VIEW.name)
  const variant = useVariant(FF_CALENDAR_VIEW.name)
  const { track } = useTracking()

  useEffect(() => {
    if (!isExperimentEnabled || !subscription) {
      return
    }
    track('Experiment Viewed', {
      user_email: subscription.email,
      page_name: 'Orders',
      experiment_id: 'onb-sc-46293',
      experiment_name: 'Engagement-Orders-Calendar View',
      variation_name: variant.name,
      experiment_feature_flag: FF_CALENDAR_VIEW.name
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, variant])
}
