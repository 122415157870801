export const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
export const CHEF_IMG_URL_SOURCE = process.env.REACT_APP_CHEF_IMG_URL_SOURCE
export const FEATURED_TITLE = 'Collections'
export const FEATURED_CATEGORY = 111
export const HIGHEST_MEALS_PER_DELIVERY_PLAN = 16
export const ORDER_CONFIRMATION_PAGE_NAME = 'Order Confirmation'
export const ORDER_CURRENCY = 'USD'
export const FEATURED_ICON =
  'https://d1ureao5dcqpo4.cloudfront.net/media/categories/icons/featured.svg'
export const MEALS_CATEGORY = 0

export const STORES = {
  NY: 1,
  LA: 2,
  AU: 3,
  CHI: 4,
  ATL: 5,
  SEA: 6,
  MIA: 7
}

export const CHEF_HOLIDAY_BANNER_SHOW_DATES = {
  ALL: '2022-12-27',
  LA: '2022-12-28'
}

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}
