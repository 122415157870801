import { handleActions } from 'redux-actions'

import * as actions from './actions'

const initialState = {
  isOpen: false,
  deliveryDate: ''
}

export const manageOrderModalReducer = handleActions(
  {
    [actions.openManageOrderModal]: (_, { payload }) => {
      return {
        isOpen: true,
        // { deliveryDate: string }
        ...payload
      }
    },
    [actions.closeManageOrderModal]: () => {
      return {
        isOpen: false
      }
    }
  },
  initialState
)
