import * as R from 'ramda'

import {
  getOrderStatus,
  getBrowseEventCtaCopyFromOrderStatus
} from '../components/DeliveryCard/utils'
import tracking from '../shared/tracking'
import store from '../store'
import {
  findSpecificationDetail,
  formatMealRating,
  getProteinTag,
  isNew
} from '../componentsV2/MenuMealCard/utils'

import { checkIfMealExistIntoWishListProfiles } from './utils'
import {
  FILTER_TYPES,
  MEALS_CATEGORY_ID
} from '../modules/MyPlan/components/Filters/constants'
import { isCategoryWithFilters } from '../modules/MyPlan/components/Filters/utils'
import { orderToTrackMapper } from './mappers'
import { getCurrentDate } from 'src/modules/MyPlan/selectors'

export const getProductProps = product => {
  return {
    product_id: product.entity_id,
    product_chef_name: `${product.chef_firstname} ${product.chef_lastname}`,
    product_category: product.category,
    collection: product.collectionName,
    product_image_url: product.imageUrl || product.imageFullUrl,
    product_ingredients:
      product.ingredients_data || product.ingredientsStatement,
    product_price: product.price,
    product_rating: product.stars,
    product_rating_count: product.reviews,
    ratio_carb: R.path(['nutritional_facts', 'carbs'], product),
    ratio_fat: R.path(['nutritional_facts', 'fat'], product),
    ratio_protein: R.path(['nutritional_facts', 'protein'], product),
    product_specification:
      product.specificationsDetails &&
      product.specificationsDetails.map(({ label }) => label),
    product_premium: product.is_premium,
    product_line: product.menu_category_label
  }
}

// Tags: Rating, Calories, Protein and Spicy
export const getProductBottomTags = product => {
  return [
    !isNew(product) &&
      parseInt(
        Array.isArray(product.reviews) ? product.reviews_count : product.reviews
      ) > 0 &&
      parseInt(formatMealRating(product.stars)) > 0 &&
      'Rating',
    product.calories && 'Calories',
    getProteinTag(product.protein_type || product.meatType) && 'Protein',
    findSpecificationDetail(product.specificationsDetails, 'spicy') && 'Spicy'
  ].filter(tag => Boolean(tag))
}

const getProfilesInfo = state =>
  R.pathOr(null, ['api', 'subscription', 'info', 'profiles'], state)

export const trackAddProduct = (
  product,
  quantityAdded,
  pageName,
  productAddSource
) => {
  const state = store.getState()
  const profiles = getProfilesInfo(state)
  const topTag = product.is_back_in_the_menu
    ? 'back_in_menu'
    : R.path(['feature', 'name'], product)

  tracking.track('Product Added', {
    list_name: product.collectionName || product.menu_category_label,
    list_type: product.collectionName ? 'Collection' : 'Regular',
    cart_quantity_added: quantityAdded,
    cart_product_quantity: product.quantity + quantityAdded,
    ...getProductProps(product),
    product_add_source: productAddSource,
    product_top_tag: topTag,
    product_bottom_tag: getProductBottomTags(product),
    product_line:
      product.categories && product.categories.map(({ label }) => label),
    product_is_favorite: checkIfMealExistIntoWishListProfiles(
      product.id,
      profiles
    ),
    product_name: product.name,
    page_name: pageName,
    page_position: product.index + 1,
    menu_by: product.Menu_by || '',
    menu_by_option: product.Menu_by_option || ''
  })
}

export const trackRemoveProduct = (
  product,
  quantityRemoved,
  pageName,
  productRemoveSource
) => {
  tracking.track('Product Removed', {
    list_name: product.collectionName || product.menu_category_label,
    list_type: product.collectionName ? 'Collection' : 'Regular',
    cart_quantity_removed: quantityRemoved,
    cart_product_quantity: product.quantity - quantityRemoved,
    // cart_total_quantity:,
    ...getProductProps(product),
    product_initial_capacity: product.stock,
    product_remaining_capacity: product.stock,
    product_remove_source: productRemoveSource,
    page_name: pageName,
    page_position: product.index + 1
  })
}

export const trackAddProductCrossSell = (
  relatedMealId,
  quantityAdded,
  pageName,
  productAddSource
) => {
  const state = store.getState()
  const relatedMeal = state.menu.menu.meals
    .filter(_meal => _meal.id === relatedMealId)
    .pop()
  if (relatedMeal) {
    trackAddProduct(relatedMeal, quantityAdded, pageName, productAddSource)
  }
}

export const trackRemoveProductCrossSell = (
  relatedMealId,
  quantityAdded,
  pageName,
  productAddSource
) => {
  const state = store.getState()
  const relatedMeal = state.menu.menu.meals
    .filter(_meal => _meal.id === relatedMealId)
    .pop()
  if (relatedMeal) {
    trackRemoveProduct(relatedMeal, quantityAdded, pageName, productAddSource)
  }
}

export const trackBackToTopBtnClicked = (props, context) => {
  tracking.track('BackToTop Clicked', {
    ...props,
    ...context
  })
}

export const trackCrossSellProductClicked = (
  relatedMealId,
  pageName,
  action
) => {
  const state = store.getState()
  const profiles = getProfilesInfo(state)
  const relatedMeal = state.menu.menu.meals
    .filter(_meal => _meal.id === relatedMealId)
    .pop()

  if (relatedMeal) {
    tracking.track('Cross Sell Product Clicked', {
      list_name: relatedMeal.collectionName || relatedMeal.menu_category_label,
      list_type: relatedMeal.collectionName ? 'Collection' : 'Regular',
      ...getProductProps(relatedMeal),
      product_add_source: 'Cross Sell In Meal Card',
      product_top_tag: R.path(['feature', 'name'], relatedMeal),
      product_bottom_tag: getProductBottomTags(relatedMeal),
      product_line:
        relatedMeal.categories &&
        relatedMeal.categories.map(({ label }) => label),
      product_is_favorite: checkIfMealExistIntoWishListProfiles(
        relatedMeal.id,
        profiles
      ),
      product_name: relatedMeal.name,
      page_name: pageName,
      page_position: relatedMeal.index + 1,
      menu_by: relatedMeal.Menu_by || '',
      menu_by_option: relatedMeal.Menu_by_option || '',
      action: action
    })
  }
}

export const trackClickedProduct = (product, href, pageName) => {
  tracking.track('Product Clicked', {
    href,
    page_name: pageName,
    ...getProductProps(product),
    page_position: product.index + 1
  })
}

export const trackModuleViewed = (
  moduleName,
  pageName,
  moduleType,
  productId
) => {
  tracking.track('Content Module Viewed', {
    module_name: moduleName,
    page_name: pageName,
    module_type: moduleType,
    product_id: productId
  })
}

export const trackProductTileSeen = (
  productId,
  pagePosition,
  countProductsDisplayed,
  pageName,
  stock,
  sku,
  premium,
  isLiked,
  bottomTag,
  topTag,
  productListName,
  productListType
) => {
  tracking.track('Product Tile Seen', {
    page_name: pageName,
    product_id: productId,
    page_position: pagePosition,
    count_products_displayed: countProductsDisplayed,
    product_is_in_stock: stock > 0,
    product_sku: sku,
    product_is_user_favorite: isLiked,
    product_premium: premium,
    product_top_tag: topTag,
    product_bottom_tag: bottomTag,
    list_name: productListName,
    list_type: productListType
  })
}

export const trackClearedCart = (allProducts, mealsCount, pageName) => {
  const products = allProducts.map(product => getProductProps(product))
  tracking.track('Cart Cleared', {
    products,
    cart_quantity_removed: mealsCount,
    page_name: pageName
  })
}

export const trackEventOrderConfirmed = (
  currentOrder,
  currentDate,
  selectedTimeslot,
  pageName
) => {
  tracking.track(
    'Order Confirmed',
    orderToTrackMapper({
      currentOrder,
      currentDate,
      selectedTimeslot,
      pageName
    })
  )
}

/*
page_name - if it's web, name of the page where the user saw the experiment - doesn't apply if it's the mobile app
screen_name - if it's mobile app, name of the screen where the user saw the experiment - doesn't apply if it's web
experiment_id - gro-sc-52902
experiment_name - Growth - Resurrection - Redirect to Meal Selection
variation_name - control / treatment-1
*/

export const trackReactivatedUserRedirectedToOrder = screenName => {
  tracking.trackExperiment(
    'gro-sc-52902',
    'Growth - Resurrection - Redirect to Meal Selection',
    'treatment-1',
    screenName
  )
}

export const trackReactivatedUserNotRedirectedToOrder = screenName => {
  tracking.trackExperiment(
    'gro-sc-52902',
    'Growth - Resurrection - Redirect to Meal Selection',
    'control',
    screenName
  )
}

export const trackResurrectionFunnelExperiment = (
  screenName,
  variant = 'control'
) => {
  tracking.trackExperiment(
    'gro-sc-49096',
    'Growth - Resurrection - Resurrection Funnel',
    variant,
    screenName
  )
}

export const trackResurrectionButtonClicked = (action, screenName) => {
  tracking.track(action, {
    page_name: screenName
  })
}

export const trackResurrectionLandingPage = (action, screenName) => {
  tracking.track(action, {
    page_name: screenName
  })
}

const getFilters = (
  currentFilters,
  currentChefFilter,
  currentQuickFilter,
  searchInput,
  menuBySelectedName,
  isSticky,
  selectedCategory,
  currentPersonalizedFilter
) => {
  const filterTypeLabel = isSticky ? FILTER_TYPES.STICKY : FILTER_TYPES.QUICK

  const listCurrentFilters = Object.entries(currentFilters).flatMap(
    ([key, value]) =>
      value.map(item => {
        return {
          filter_type: key.includes(menuBySelectedName.toLowerCase())
            ? filterTypeLabel
            : FILTER_TYPES.ADVANCED,
          filter_group: key,
          filter_value: item.name
        }
      })
  )

  const listCurrentChefs = currentChefFilter.reduce((accum, elem) => {
    if (elem.label) {
      return [
        ...accum,
        {
          filter_type:
            'chef' === menuBySelectedName.toLowerCase()
              ? filterTypeLabel
              : FILTER_TYPES.ADVANCED,
          filter_group: 'Chef',
          filter_value: elem.label
        }
      ]
    }
    return accum
  }, [])

  const currentQuick = currentQuickFilter
    ? [
        {
          filter_type: filterTypeLabel,
          filter_group:
            selectedCategory.id === MEALS_CATEGORY_ID ? 'All' : undefined,
          filter_value: currentQuickFilter.label ?? 'All'
        }
      ]
    : []

  const currentSearchInput =
    searchInput && searchInput !== ''
      ? [
          {
            filter_type: FILTER_TYPES.SEACRH,
            filter_group: '',
            filter_value: searchInput
          }
        ]
      : []

  const currentPersonalized = currentPersonalizedFilter
    ? [
        {
          filter_type: FILTER_TYPES.PERSONALIZED,
          filter_group: '',
          filter_value: currentPersonalizedFilter?.firstname || ''
        }
      ]
    : []

  return [
    ...listCurrentFilters,
    ...listCurrentChefs,
    ...currentQuick,
    ...currentSearchInput,
    ...currentPersonalized
  ]
}

export const trackFilterSelected = (
  filteredMeals,
  currentFilters,
  currentChefFilter,
  currentQuickFilter,
  selectedCategory,
  currentSortBy,
  currentPreferenceProfile,
  searchInput,
  currentDate,
  pageName = 'Menu'
) => {
  if (
    selectedCategory.id !== MEALS_CATEGORY_ID &&
    !isCategoryWithFilters(selectedCategory.id)
  ) {
    return
  }

  const state = store.getState()
  const { name: menuBySelectedName = '' } = state.filters.menuSelected || {}
  const isMealPage = selectedCategory.title === 'Meals'
  const pastFilteredMeals = state.filters.filteredMeals
  const isSticky = state.filters.isSticky
  const isDefaultMenuByFilter = state.filters.isDefaultMenuByFilter

  const getSortName = currentSortBy => {
    const sortName = currentSortBy?.[0] && currentSortBy[0].name
    const sortLabel =
      (currentSortBy?.[0].order && `: ${currentSortBy[0].order.label}`) || ''
    return `${sortName}${sortLabel}`
  }

  tracking.track('Product List Filtered', {
    filters: getFilters(
      currentFilters,
      currentChefFilter,
      currentQuickFilter,
      searchInput,
      menuBySelectedName,
      isSticky,
      selectedCategory,
      currentPreferenceProfile
    ),
    filter_default_menu_by: isDefaultMenuByFilter,
    delivery_date: currentDate,
    list_category: selectedCategory.title,
    sort_name: getSortName(currentSortBy),
    list_name: selectedCategory.title,
    list_type: !!selectedCategory ? 'Regular' : 'Collection',
    list_menu_by: isMealPage ? `Show Menu By ${menuBySelectedName}` : undefined,
    count_products_displayed_before_filter: pastFilteredMeals.length,
    count_products_displayed_after_filter: filteredMeals.length,
    count_products_available_before_filter: pastFilteredMeals.filter(
      item => item.stock > 0
    ).length,
    count_products_available_after_filter: filteredMeals.filter(
      item => item.stock > 0
    ).length,
    page_name: pageName
  })
}

export const trackListSorted = (sortBy, deliveryDate, list_name, page_name) => {
  tracking.track('Product List Sorted', {
    delivery_date: deliveryDate.date,
    sort_name: sortBy.map(sort => sort.name),
    list_name,
    page_name
  })
}

export const formatProduct = products => {
  return products.map(elem => {
    return {
      id: elem.entity_id,
      sku: elem.sku,
      category: elem.category,
      name: elem.title,
      chef_name: elem.chefName,
      price: elem.price,
      url: `/products/${elem.entity_id}`,
      image_url: elem.imageUrl
    }
  })
}

export const trackExperimentViewed = experimentTrackingProperties => {
  tracking.track('Experiment Viewed', experimentTrackingProperties)
}

export const trackProductListViewed = (
  pageName,
  mealsData = [],
  props = {}
) => {
  const state = store.getState()
  const currentDate = getCurrentDate(state)

  props = {
    ...props,
    delivery_date_cutoff_time: currentDate?.cutoff?.time,
    delivery_display_date: currentDate?.displayDate,
    delivery_date_is_paused: currentDate?.isPaused,
    delivery_date_order_status: currentDate?.order?.orderStatus?.status,
    delivery_date_recommendation_date: currentDate?.recommendation?.date,
    delivery_date_recommendation_id: currentDate?.recommendation?.id,
    delivery_date_rescheduled: currentDate?.rescheduled,
    delivery_date_scheduled: currentDate?.scheduled,
    delivery_date_skip: currentDate?.skip
  }

  const isMenuListSelection = pageName === 'Menu / Product Selection'

  tracking.track('Product List Viewed', {
    product_viewed: isMenuListSelection
      ? mealsData.map(({ magento_id, weight }) => ({
          magento_id,
          weight
        }))
      : mealsData,
    count_products_displayed: mealsData.length,
    count_products_available: isMenuListSelection
      ? mealsData.filter(item => item.stock > 0).length
      : mealsData.length,
    page_name: pageName,
    ...props
  })
}

export const trackRelatedMealTooltipViewed = () => {
  tracking.track('Overlay Viewed', {
    overlay_name: 'Cross Sell Product Tooltip',
    overlay_class: 'Tooltip'
  })
}

export const trackSortingFilterButtonClicked = forYouActive => {
  tracking.track('CTA Button Clicked', {
    cta_text: forYouActive ? 'Sort and Filters' : 'Filters'
  })
}

export const trackSortingFilterViewed = forYouActive => {
  tracking.track('Overlay Viewed', {
    overlay_name: forYouActive ? 'Sort and Filters' : 'Filters'
  })
}
export const trackSortingFilterClosed = forYouActive => {
  tracking.track('Overlay Closed', {
    overlay_name: forYouActive ? 'Sort and Filters' : 'Filters'
  })
}

export const trackSortingFilterClearAllClicked = forYouActive => {
  tracking.track('Overlay Clicked', {
    overlay_name: forYouActive ? 'Sort and Filters' : 'Filters',
    overlay_action: `Clear All`
  })
}

export const trackAddProductFromModule = (
  product,
  quantityAdded,
  moduleName
) => {
  const state = store.getState()

  tracking.track('Product Added', {
    cart_quantity_added: quantityAdded,
    cart_product_quantity: product.qty,
    product_remaining_capacity: product.stock,
    ...getProductProps(product),
    product_add_source: moduleName,
    product_initial_capacity: product.stock,
    page_position: product.index + 1,
    list_name: product.collectionName || state.filters.selectedCategoryLabel,
    list_type: product.collectionName ? 'collection' : 'regular'
  })
}

export const trackClickedProductFromModule = (product, moduleName) => {
  tracking.track('Product Clicked', {
    product_add_source: moduleName,
    ...getProductProps(product),
    page_position: product.index + 1
  })
}

/** Tracking event for Content Tile Clicked
 * @param {Object} props
 * @param {String} props.pageName
 * @param {Number} props.pagePosition
 * @param {String} props.contentName
 * @param {String} props.contentDescription
 * @param {String} props.deliveryDate
 * @param {String} props.productId
 */
export const trackContentTileClicked = props => {
  const {
    pageName,
    pagePosition,
    contentName,
    contentDescription,
    deliveryDate = '',
    productId
  } = props

  tracking.track('Content Tile Clicked', {
    page_name: pageName,
    page_position: pagePosition,
    content_name: contentName,
    content_description: contentDescription,
    delivery_date: deliveryDate,
    product_id: productId || undefined
  })
}

export const trackRaffleViewed = (
  pageName,
  bannerName,
  bannerType,
  bannerCampaign
) => {
  tracking.track('Banner Viewed', {
    page_name: pageName,
    banner_name: bannerName,
    banner_type: bannerType,
    banner_campaign: bannerCampaign
  })
}

export const trackRaffleClicked = (
  pageName,
  bannerName,
  bannerType,
  bannerCampaign
) => {
  tracking.track('Banner Clicked', {
    page_name: pageName,
    banner_name: bannerName,
    banner_type: bannerType,
    banner_campaign: bannerCampaign
  })
}

export const trackMenuByClicked = ({ pageName = '', filterName = '' }) => {
  tracking.track('Show Menu By Clicked', {
    page_name: pageName,
    filter_name: filterName
  })
}

export const trackMenuBySelected = ({ selected = '' }) => {
  tracking.track('Show Menu By Selected', {
    page_name: 'Menu',
    list_name: 'Meals',
    menu_by_selected: selected
  })
}

export const trackBrowseMenu = ({
  shippingDate,
  deliveryDate,
  ...orderStatusProps
}) => {
  const orderStatus = getOrderStatus(orderStatusProps)
  const ctaDescription = getBrowseEventCtaCopyFromOrderStatus(orderStatus)
  tracking.track('Browse Menu Clicked', {
    page_name: 'Orders',
    shipping_date: shippingDate,
    order_status: orderStatus,
    cta_description: ctaDescription,
    delivery_date: deliveryDate
  })
}

export const trackViewReceiptClicked = ({ orderStatus, orderId }) => {
  tracking.track('View Receipt Clicked', {
    page_name: 'History',
    order_status: orderStatus,
    order_id: orderId
  })
}

export const trackPersonalizedFilterViewed = () => {
  tracking.track('Overlay Viewed', {
    overlay_name: 'Personalized Filter',
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'button',
    page_name: 'Meal Selection',
    overlay_trigger: 'manually',
    overlay_step: 1
  })
}

export const trackPersonalizedFilterClicked = ({ optionSelected }) => {
  tracking.track('Overlay Clicked', {
    overlay_name: 'Personalized Filter',
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'button',
    page_name: 'Meal Selection',
    overlay_trigger: 'manually',
    overlay_step: 1,
    overlay_options: [
      'Select a Partner: when the user select one of the partners or none',
      'Add a Partner: when the user clicks on "add partner"',
      'Set Preferences: when the user clicks on "Set Preferences Now"',
      'Edit Preferences: when the user clicks on "Edit"'
    ],
    overlay_action: optionSelected
  })
}

export const trackSwitchViewButton = ({
  prev_view,
  view_selected,
  list_name
}) => {
  tracking.track('Visual Switcher Clicked', {
    prev_view,
    view_selected,
    list_name
  })
}

export const trackRecipeClicked = ({
  page_name,
  product_id,
  product_line,
  recipe_id,
  recipe_name
}) => {
  tracking.track('Recipe Clicked', {
    page_name,
    product_id,
    product_line,
    recipe_id,
    recipe_name
  })
}
