import { handleActions } from 'redux-actions'

import * as actions from './actions'

const initialState = {
  isOpen: false,
  date: ''
}

export const orderSkippedModalReducer = handleActions(
  {
    [actions.openOrderSkippedModal]: (_, { payload }) => {
      return {
        isOpen: true,
        // { date: string }
        ...payload
      }
    },
    [actions.closeOrderSkippedModal]: () => {
      return {
        isOpen: false
      }
    }
  },
  initialState
)
