import { ModalFeedback } from './ModalFeedback'
import { bindActionCreators } from 'redux'
import { compose } from '../../../../utils/recompose-migration'
import { connect } from 'react-redux'
import { sendSatisfactionResponse } from '../../../../redux-api-bridge'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendSatisfactionResponse
    },
    dispatch
  )

export default compose(connect(null, mapDispatchToProps))(ModalFeedback)
