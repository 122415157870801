import { MENU_CATEGORIES, SORTING_TYPES } from 'src/constants/menu'
import * as api from '../../api'
import { getUserAlreadyRequestedMeals } from '../../api/Filters'

import * as actions from './actions'
import {
  CATEGORY_LABEL,
  CATEGORY_PATTERN
} from 'src/modules/MyPlan/components/MenuBy/FiltersExperimentV3/constantsV3'
import { buildImgUrl } from 'src/utils/utils'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const MS_IMG_URL_SOURCE = process.env.REACT_APP_MS_IMG_URL_SOURCE

const menuMapper = menu => {
  const menuByV3Enabled = menu.menuByV3
  menu.categories.forEach(category => {
    category.meals = []

    if (category.coverInfo) {
      category.cover_title = category.coverInfo.title
      category.cover_text = category.coverInfo.text
      category.cover_image = category.coverInfo.image
      category.cover_image_mobile = category.coverInfo.imageMobile
      category.cover_image_macro = category.coverInfo.imageMacro
    }
  })

  menu.meals.forEach((meal, index) => {
    const mealCategory = getCategory(menu.categories, meal, menuByV3Enabled)
    const formattedMeal = formatMeal(meal)
    menu.meals[index] = formattedMeal

    if (mealCategory && !mealCategory.meals?.some(m => m.id === meal.id)) {
      mealCategory.meals.push(formattedMeal)
    }
  })

  const forYouMealIds = menu?.sorting?.find(
    s => s.type === SORTING_TYPES.FOR_YOU
  )?.sort
  const forYouMeals = []
  if (forYouMealIds && forYouMealIds.length > 0) {
    forYouMealIds.forEach(mealId => {
      const meal = menu.meals.find(m => m.id === mealId)
      if (meal) {
        forYouMeals.push(formatMeal(meal))
      }
    })
  }
  return { ...menu, forYouMeals, available: true }
}

const formatMeal = meal => ({
  ...meal,
  all_tags: meal.tags,
  entity_id: meal.id,
  magento_id: meal.id,
  batch_id: meal.batchId,
  short_description: meal.shortDescription,
  calories: +meal.nutritionalFacts.calories,
  image_path: meal.imagePath,
  is_premium: !!meal.premiumFee,
  is_back_in_the_menu: !!meal.isBackInTheMenu,
  is_meal_restaurant_exclusive: meal.isMealRestaurantExclusive,
  premium_fee: meal.premiumFee,
  premium_special: meal.premiumSpecial,
  category_id: meal.categoryId,
  chef_id: meal.chef && meal.chef.id,
  chef_firstname: meal.chef && meal.chef.firstName,
  chef_lastname: meal.chef && meal.chef.lastName,
  is_celebrity_chef: meal.chef && meal.chef.isCelebrityChef,
  warnings: {
    message: meal.warnings && meal.warnings.message,
    restrictions_applied: meal.warnings && meal.warnings.restrictionsApplied,
    allergens_not_matching: meal.warnings && meal.warnings.allergensNotMatching,
    diets_not_matching: meal.warnings && meal.warnings.dietsNotMatching
  },
  nutritional_facts: meal.nutritionalFacts && {
    calories: meal.nutritionalFacts.calories
  },
  logopic: meal.chef && meal.chef.logoPic,
  bannerpic: meal.chef && meal.chef.bannerPic,
  inStock: meal.stock > 0,
  filter_by: [
    ...meal.filter_by,
    ...(getCategoryTag(meal) ? [getCategoryTag(meal)] : [])
  ],
  meat_type: meal.meatType,
  user_rating: meal.userRating,
  sidedish: meal.sideDish && {
    id: meal.sideDish.id
  },
  quantity: meal.qty || 0,
  imageUrl: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_meal_image: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_chef_image: `${IMG_URL_SOURCE}${meal.chef.bannerPic}`,
  protein_type: meal.meatType,
  secondary_image: (meal.media && meal.media.secondaryImage) || null,
  gif: gifUrl(meal),
  specifications_detail: meal.specificationsDetails
    ? meal.specificationsDetails.map(specification => ({
        ...specification
      }))
    : [],
  relatedMeal: meal.relatedMeal
    ? {
        ...meal.relatedMeal,
        entity_id: meal.relatedMeal.id,
        batch_id: meal.relatedMeal.batchId,
        magento_id: meal.relatedMeal.id,
        is_related_meal: true,
        image_path: meal.relatedMeal.imagePath,
        category_id: meal.relatedMeal.categoryId
      }
    : null,
  inventoryId: meal.inventories?.[0]?.id
})

const gifUrl = meal =>
  meal.media?.gif ? `${MS_IMG_URL_SOURCE}/${meal.media.gif}` : null

const getCategory = (categories, meal, menuByV3Enabled) => {
  if (
    menuByV3Enabled &&
    (+meal.category.id === MENU_CATEGORIES.SIDES ||
      +meal.category.id === MENU_CATEGORIES.BREAKFAST)
  ) {
    return categories.find(category => +category.id === MENU_CATEGORIES.MEALS)
  } else {
    return categories.find(category => +category.id === +meal.category.id)
  }
}

const getCategoryTag = meal => {
  if (
    +meal.category.id === MENU_CATEGORIES.SIDES ||
    !!meal.categories?.find(c => c.label === CATEGORY_LABEL.SIDES)
  ) {
    return CATEGORY_PATTERN.SIDES
  }
  if (
    +meal.category.id === MENU_CATEGORIES.BREAKFAST ||
    !!meal.categories?.find(c => c.label === CATEGORY_LABEL.BREAKFAST)
  ) {
    return CATEGORY_PATTERN.BREAKFAST
  }
  return
}

export const getUserMenuByDate = (date, fromPrefetch) => dispatch => {
  dispatch(actions.getUserMenuStart({ fromPrefetch }))

  return api
    .getUserMenu({ date }, true)
    .then(async ({ data }) => {
      const menuData = menuMapper(data.menu)
      dispatch(
        actions.getUserMenuSuccess({
          menu: menuData,
          date
        })
      )
    })
    .catch(err => dispatch(actions.getUserMenuFail(err)))
}

export const getAlreadyRequestedMeals = date => (dispatch, getState) => {
  const state = getState()
  if (
    state.menu.alreadyRequestedMeals &&
    state.menu.alreadyRequestedMeals.date === date
  ) {
    return state.menu.alreadyRequestedMeals.meals
  }
  dispatch(actions.getUserAlreadyRequestedMealsStart())

  return getUserAlreadyRequestedMeals(date)
    .then(async ({ data }) => {
      const menu = menuMapper(data.menu, false)
      const meals = menu && menu.available && menu.meals
      dispatch(
        actions.getUserAlreadyRequestedMealsSuccess({
          meals: meals || [],
          date
        })
      )
      return meals
    })
    .catch(err => dispatch(actions.getUserAlreadyRequestedMealsFail(err)))
}

export const getCrossSelling = (date, cartItems) => dispatch => {
  dispatch(actions.getCrossSellingItemsStart())
  return api
    .getCrossSelling({ date, cartItems })
    .then(async ({ data }) => {
      dispatch(
        actions.getCrossSellingItemsSuccess({
          date,
          items: data?.crossSellingItems?.meals,
          category: data?.crossSellingItems?.category
        })
      )
    })
    .catch(err => dispatch(actions.getCrossSellingItemsFail(err)))
}
