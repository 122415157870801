import { equals } from 'ramda'
import moment from 'moment'

export const getFinishDate = pauseDuration => {
  const today = moment()
  const finishDate = today.add(pauseDuration, 'weeks').format(' ddd, MMM DD')

  return finishDate
}

export const getAddressByOrders = ordersAfterCutoff => {
  const odersAddress =
    ordersAfterCutoff &&
    ordersAfterCutoff.reduce((accum, orderAfterCutoff) => {
      if (accum.length === 0) {
        return [
          ...accum,
          {
            street: orderAfterCutoff.order.address.street,
            postcode: orderAfterCutoff.order.address.postcode
          }
        ]
      }
      if (
        accum.length &&
        !accum.find(elem =>
          equals(elem.street, orderAfterCutoff.order.address.street)
        )
      ) {
        return [
          ...accum,
          {
            street: orderAfterCutoff.order.address.street,
            postcode: orderAfterCutoff.order.address.postcode
          }
        ]
      }
      return accum
    }, [])

  return (
    odersAddress &&
    odersAddress.map((address, index) => {
      const and = address[index + 1] && address[index + 1] ? ' &' : '.'
      return `${address.street}, ${address.postcode} ${and}`
    })
  )
}

export const getDateByOrders = ordersAfterCutoff => {
  const ordersFormated =
    ordersAfterCutoff &&
    ordersAfterCutoff.map((orderAfterCutoff, index) => {
      const and =
        ordersAfterCutoff[index + 1] && ordersAfterCutoff[index + 1].order
          ? ' &'
          : '.'
      return ` ${moment(orderAfterCutoff.order.delivery_date).format(
        'ddd, MMM DD'
      )}${and}`
    })
  return ordersFormated
}
