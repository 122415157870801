import { handleActions } from 'redux-actions'

import * as actions from './actions'

const initialState = {
  type: null,
  payload: null,
  // Request status
  inFlight: false,
  succeeded: false,
  error: false
}

export const nextModalReducer = handleActions(
  {
    [actions.fetchNextModalStart]: () => {
      return {
        type: null,
        payload: null,
        inFlight: true,
        succeeded: false,
        error: false
      }
    },
    [actions.fetchNextModalSuccess]: (_, { payload }) => {
      return {
        ...payload.nextModal,
        inFlight: false,
        succeeded: true,
        error: false
      }
    },
    [actions.fetchNextModalError]: () => {
      return {
        type: null,
        payload: null,
        inFlight: false,
        succeeded: false,
        error: true
      }
    }
  },
  initialState
)
