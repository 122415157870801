import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getReactivating } from '../../shared/reducers/reactivation'
import { reactivateSubscriptionInit } from '../../shared/actions/reactivation'

import ResurrectionPage from './ResurrectionPage'

const mapStateToProps = state => ({
  subscriptionInfo: state.api.subscription.info,
  isReactivating: getReactivating(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ reactivateSubscriptionInit }, dispatch)
})

export const Resurrection = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResurrectionPage)
