import './Header.scss'
import { useEmbedded } from '../../embedded'

import HeaderDesktop from './components/header/HeaderDesktop'
import HeaderMobile from './components/header/HeaderMobile'
import UserMenu from './components/userMenu/UserMenu'
import { useHeader } from './useHeader'
import { useIsMobile } from 'src/hooks/useIsMobile'

const Header = () => {
  const { isMenuOpen, handleCloseMenu, ...headerProps } = useHeader()
  const { isAppEmbedded } = useEmbedded()
  const isMobile = useIsMobile()
  const HeaderComponent = isMobile ? HeaderMobile : HeaderDesktop

  if (isAppEmbedded) {
    return null
  }

  return (
    <>
      <HeaderComponent {...headerProps} />
      <UserMenu isMenuOpen={isMenuOpen} handleCloseMenu={handleCloseMenu} />
    </>
  )
}

export default Header
