import tracking from '../../shared/tracking'
import localStorage from '../../utils/localStorage'
import {
  isMobileDevice,
  isAndroidDevice,
  isIOSDevice,
  isResurrectionLanding
} from '../../utils/utils'
import { dateDiffInDays, getDate, YYYY_MM_DD } from '../../utils/date'

const SMART_APP_BANNER_VIEWED = 'SmartAppBannerViewed'
const LIMIT_TO_SHOW_SMART_APP = 5

export const getBannerViewDate = () => localStorage.get(SMART_APP_BANNER_VIEWED)

export const setBannerViewDate = () =>
  localStorage.set(SMART_APP_BANNER_VIEWED, getDate(YYYY_MM_DD))

export const shouldShowBanner = ({ isAppEmbedded, dateViewed }) => {
  let show = false
  if (
    !isAppEmbedded &&
    !isResurrectionLanding &&
    isMobileDevice() &&
    (isAndroidDevice() || isIOSDevice())
  ) {
    if (!dateViewed) show = true
    else {
      const diffDays = dateDiffInDays(new Date(), new Date(dateViewed))
      if (diffDays >= LIMIT_TO_SHOW_SMART_APP) show = true
    }
  }

  return show
}

export const trackEvent = (text, attrs) => {
  tracking.track(text, {
    overlay_id: 'ads-app-download-banner',
    overlay_name: 'Android App Download',
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'button',
    overlay_trigger: 'automatic',
    overlay_url: (window && window.location.href) || '',
    ...attrs
  })
}
