import { put, call, takeEvery, all } from 'redux-saga/effects'

import { GQL } from '../services'
import history from '../history'
import {
  REACTIVATE_SUBSCRIPTION_INIT,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE
} from '../actions/types'
import { toaster } from '../../utils/toaster'

export function* reactivateSubscription(action) {
  try {
    const subscriptionData = yield call(GQL.reactivateSubscription)
    if (action.coupon) {
      yield call(GQL.consumeCoupon, action.coupon, true)
    }
    yield put({
      type: REACTIVATE_SUBSCRIPTION_SUCCESS,
      payload: subscriptionData
    })
    toaster.success(
      <span>
        Your subscription was successfully <b>reactivated</b>. See what's new
        and order your next delivery!
      </span>
    )
    // TODO Find a way to reload subscription data using redux from this saga or remove sagas.
    setTimeout(() => {
      history.push('/validate-cc')
      window.location.reload()
      // Enough time to read the toast message
    }, 2000)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    yield put({
      type: REACTIVATE_SUBSCRIPTION_FAILURE,
      error
    })
  }
}

export default function* rootInitialSaga() {
  yield all([takeEvery(REACTIVATE_SUBSCRIPTION_INIT, reactivateSubscription)])
}
