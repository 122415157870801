import React, { useEffect, useState } from 'react'
import { CuiSmartAppBanner } from 'front-lib'

import { useEmbedded } from '../../embedded'
import { isAndroidDevice } from '../../utils/utils'

import {
  shouldShowBanner,
  getBannerViewDate,
  setBannerViewDate,
  trackEvent
} from './utils'

import './styles.scss'

export const URL_APP_CU_ANDROID = process.env.REACT_APP_CU_ANDROID
export const URL_APP_CU_IOS = process.env.REACT_APP_CU_IOS

const SmartAppBannerContainer = () => {
  const { isAppEmbedded } = useEmbedded()
  const dateViewed = getBannerViewDate()
  const isAndroid = isAndroidDevice()
  const showBanner = shouldShowBanner({ isAppEmbedded, dateViewed })
  const [urlAppCU, setURLAppCU] = useState(URL_APP_CU_IOS)
  const [deviceTitle, setDeviceTitle] = useState('iOS')
  const screenName = '' // just for now, this attr is not possible to track due an test error.

  useEffect(() => {
    if (showBanner) {
      trackEvent('Overlay Viewed', { screen_name: screenName })
    }
  }, [showBanner])

  useEffect(() => {
    if (isAndroid) {
      setURLAppCU(URL_APP_CU_ANDROID)
      setDeviceTitle('Android')
    }
  }, [isAndroid])

  if (!showBanner) return null

  const onClick = () => {
    trackEvent('Overlay Clicked', {
      screen_name: screenName,
      overlay_action: 'click',
      overlay_options: 'Download'
    })
    setTimeout(() => window.open(urlAppCU, '_blank'), 300)
    setBannerViewDate()
  }

  const onClickClose = () => {
    setBannerViewDate()
    trackEvent('Overlay Closed', { screen_name: screenName })
  }

  return (
    <CuiSmartAppBanner
      title={`CookUnity ${deviceTitle} App`}
      subTitle="Eat well without effort"
      textButton="Download"
      handleOnClick={onClick}
      handleOnClickClose={onClickClose}
      className="smart-app-banner"
    />
  )
}

export default SmartAppBannerContainer
