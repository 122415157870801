import { createAction } from 'redux-actions'

export const fastOrderStart = createAction('FAST_ORDER_START')
export const fastOrderSuccess = createAction('FAST_ORDER_SUCCESS')
export const fastOrderError = createAction('FAST_ORDER_ERROR')

export const fastOrderModalOpen = createAction('FAST_ORDER_MODAL_OPEN')
export const fastOrderModalClose = createAction('FAST_ORDER_MODAL_CLOSE')

export const fastOrderTooltipClose = createAction('FAST_ORDER_TOOLTIP_CLOSE')
export const fastOrderToastClose = createAction('FAST_ORDER_TOAST_CLOSE')

export const fastOrderClearState = createAction('FAST_ORDER_CLEAR_STATE')
