export const INIT_SIGN_UP = '@signup/INIT_SIGN_UP'
export const INIT_SIGN_UP_SUCCESS = '@signup/INIT_SIGN_UP_SUCCESS'
export const INIT_SIGN_UP_FAILURE = '@signup/INIT_SIGN_UP_FAILURE'

export const GET_SIGN_UP_PUBLIC_DATA_INIT =
  '@signup/GET_SIGN_UP_PUBLIC_DATA_INIT'
export const GET_SIGN_UP_PUBLIC_DATA_SUCCESS =
  '@signup/GET_SIGN_UP_PUBLIC_DATA_SUCCESS'
export const GET_SIGN_UP_PUBLIC_DATA_FAILURE =
  '@signup/GET_SIGN_UP_PUBLIC_DATA_FAILURE'
export const GET_SIGN_UP_MEALS_PLAN_DATA_INIT =
  '@signup/GET_SIGN_UP_MEALS_PLAN_DATA_INIT'
export const GET_SIGN_UP_MEALS_PLAN_DATA_SUCCESS =
  '@signup/GET_SIGN_UP_MEALS_PLAN_DATA_SUCCESS'
export const GET_SIGN_UP_MEALS_PLAN_DATA_FAILURE =
  '@signup/GET_SIGN_UP_MEALS_PLAN_DATA_FAILURE'

export const SIGNUP_LOGIN_INIT = '@signup/SIGNUP_LOGIN_INIT'
export const SIGNUP_LOGIN_SUCCESS = '@signup/SIGNUP_LOGIN_SUCCESS'
export const SIGNUP_LOGIN_FAILURE = '@signup/SIGNUP_LOGIN_FAILURE'

export const PUBLIC_ADD_ADDRESS_INIT = '@signup/PUBLIC_ADD_ADDRESS_INIT'
export const PUBLIC_ADD_ADDRESS_SUCCESS = '@signup/PUBLIC_ADD_ADDRESS_SUCCESS'
export const PUBLIC_ADD_ADDRESS_FAILURE = '@signup/PUBLIC_ADD_ADDRESS_FAILURE'

export const PUBLIC_ADD_CARD_INIT = '@signup/PUBLIC_ADD_CARD_INIT'
export const PUBLIC_ADD_CARD_SUCCESS = '@signup/PUBLIC_ADD_CARD_SUCCESS'
export const PUBLIC_ADD_CARD_FAILURE = '@signup/PUBLIC_ADD_CARD_FAILURE'

export const CREATE_SIGNUP_INIT = '@signup/CREATE_SIGNUP_INIT'
export const CREATE_SIGNUP_SUCCESS = '@signup/CREATE_SIGNUP_SUCCESS'
export const CREATE_SIGNUP_FAILURE = '@signup/CREATE_SIGNUP_FAILURE'

export const REACTIVATE_SUBSCRIPTION_INIT = '@root/REACTIVATE_SUBSCRIPTION_INIT'
export const REACTIVATE_SUBSCRIPTION_SUCCESS =
  '@root/REACTIVATE_SUBSCRIPTION_SUCCESS'
export const REACTIVATE_SUBSCRIPTION_FAILURE =
  '@root/REACTIVATE_SUBSCRIPTION_FAILURE'

export const GET_SAMPLE_MENU_INIT = '@root/GET_SAMPLE_MENU_INIT'
export const GET_SAMPLE_MENU_SUCCESS = '@root/GET_SAMPLE_MENU_SUCCESS'
export const GET_SAMPLE_MENU_FAILURE = '@root/GET_SAMPLE_MENU_FAILURE'

export const GET_COUPON_DATA_INIT = '@root/GET_COUPON_DATA_INIT'
export const GET_COUPON_DATA_SUCCESS = '@root/GET_COUPON_DATA_SUCCESS'
export const GET_COUPON_DATA_FAILURE = '@root/GET_COUPON_DATA_FAILURE'

export const SET_USER_DATA_INIT = '@signup/SET_USER_DATA_INIT'
export const SET_USER_DATA_SUCCESS = '@signup/SET_USER_DATA_SUCCESS'
export const SET_USER_DATA_FAILURE = '@signup/SET_USER_DATA_FAILURE'

export const APPLY_COUPON_INIT = '@signup/APPLY_COUPON_INIT'
export const APPLY_COUPON_SUCCESS = '@signup/APPLY_COUPON_SUCCESS'
export const APPLY_COUPON_FAILURE = '@signup/APPLY_COUPON_FAILURE'

export const ERASE_COUPON_ERROR = '@signup/ERASE_COUPON_ERROR'

export const HANDLE_INPUT_CHANGE = '@signup/HANDLE_INPUT_CHANGE'

export const HANDLE_ORDER_CHANGE = '@signup/HANDLE_ORDER_CHANGE'

export const SET_UUID = '@signup/SET_UUID'

export const SIGN_UP_LOG = '@signup/SIGN_UP_LOG'
export const SIGN_UP_LOG_SUCCESS = '@signup/SIGN_UP_LOG_SUCCESS'
export const SIGN_UP_LOG_FAILURE = '@signup/SIGN_UP_LOG_FAILURE'
