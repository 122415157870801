export const signupData = `
query initSignup($zipcode: String) {
    tastes {
        id
        name
    }
    dietaryPreferences {
        id
        name
        description
    }
    diets {
        id
        name
        description
        image
    }
    timeslots (zipcode: $zipcode) {
        start
        end
        available
        label
    }
    upcomingDays (zipcode: $zipcode, weeks: 2) {
      available
      date
      displayDate
      cutoff {
        time
        utcOffset
        userTimeZone
      }
    }
}`

export const mealPlans = `
fragment Plan on Plan {
    id
    sidedishes
    price
    deliveryFee
    discountPrice
    mealsPerDelivery
    deliveries
    productDiscountRate
    planGiftId
    available
    featured
    type
  }

  query getAvailablePlans($zipcode: String) {
    allPlans: availablePlans(zipcode: $zipcode) {
      ...Plan
    }
    featuredPlans: availablePlans(zipcode: $zipcode, featured: true) {
      ...Plan
    }
  }
`
export const reactivateSubscription = `
mutation reactivateSubscription($date: Date!) {
    publicCreateSubscriptionEvent(input: {
        event: subscribe,
        flow: reactivation,
        date: $date
    }){
        ... on CreateSubscriptionEventErrorType {
            error
        }
        ... on CreateSubscriptionEventSuccesType {
            id
            userId
            event
            date
        }
    }
}
`

export const getCouponData = `
{
    query getCouponData($coupon: String!) {
        coupon (coupon: $coupon) {
        promotion {
            id
            title
            credit {
            id
            amount
            }
            discount {
            id
            rate
            duration
            }
        }
      }
    }
}
`

export const consumeCoupon = `
mutation consumeCoupon($coupon: String!, $isResurrected: Boolean!) {
    consumeCoupon(coupon: $coupon, isResurrected: $isResurrected) {
      __typename
      ... on GenericResponse {
        result
      }
      ... on GenericError {
        error
      }
    }
  }
`

export const sampleMenu = `
  query sampleMenu {
    sampleMenu {
            entity_id
            premium_fee
            name
            short_description
            calories
            image
            image_path
            price
            sku
            category_id
            chef_id
            chef_firstname
            chef_lastname
            logopic
            bannerpic
            stock
            inStock
            meat_type
            stars
            reviews
            is_celebrity_chef
            sidedish {
              id
            }
            specifications_detail {
              label
            }
      }
  }
`

export const loginLegacyUser = `
  mutation signupLegacyUser($email: String!, $password: String!, $firstname: String!, $lastname: String!) {
    signupLegacyUser(email: $email, password: $password, firstname: $firstname, lastname: $lastname) {
      ...on SignupType {
          userId,
        addresses {
          id
          isActive
          city
          country
          region
          regionId
          postcode
          street
        }
        cards {
          id
          company
          last4
          owner
          cardType
          ccNumber
          expirationMonth
          expirationYear
          customerId
        }
      }
      ... on GenericError {
        error
      }
    }
  }
`

export const publicAddAddress = `
  mutation publicAddAddress($email: String!, $password: String!, $addressInfo: ChangeUserAddressInput!) {
    publicAddAddress(email: $email, password: $password, addressInfo: $addressInfo){
      ... on AddressType {
            id
            city
            isActive,
            country,
            region,
            regionId,
            postcode,
            telephone,
            street
        }
      ... on userAddressChangeErrorType {
          error
      }
    }
  }
`

export const publicAddCard = `
  mutation publicAddCard($email: String!, $password: String!, $cardInfo: ChangeUserCardInput!) {
    publicAddCard(email: $email, password: $password, cardInfo: $cardInfo) {
      ... on CardType {
        id
        company
        cardType,
        last4
        owner,
        expirationMonth
        expirationYear
        customerId
      }
      ... on userCardChangeErrorType {
        error
      }
    }
  }
`

export const createSignupUser = `
  mutation createSignupUser($email: String!, $password: String!, $subscriptionCharge: Boolean!, $user: CreateSignupUserInputType!) {
    createSignupUser(email: $email, password: $password, subscriptionCharge: $subscriptionCharge, user: $user){
        ... on User {
          id
          name
          magento_id
          profiles {
              id
          }
        }
        ... on GenericError {
          error
        }
    }
}
`

export const checkCoupon = `
query coupon ($coupon: String!){
  coupon(coupon: $coupon) {
    promotion {
      id
      title
      discount {
        rate
        duration
      }
      credit {
        amount
      }
    }
  }
}
`
export const deliveryDays = `
query deliveryDays($zipcode: String) {
    deliveryDays (zipcode: $zipcode) {
      deliveryDays
    }
}`

export const sendSignUpLog = `
mutation sendSignUpLog($uuid: String!, $message: Object!) {
    sendSignUpLog(uuid: $uuid, message: $message) {
      __typename
      ... on GenericResponse {
        result
      }
      ... on GenericError {
        error
      }
    }
  }
`
