import { FILTER_IDS } from '../Filters/constants'

import MenuAll from '../../../../../assets/icons/menuby/menu-all.svg'
import Sides from '../../../../../assets/icons/menuby/sides.svg'
import Breakfast from '../../../../../assets/icons/menuby/breakfast.svg'
import YouveTried from '../../../../../assets/icons/menuby/you_ve-tried.svg'
import Favorites from '../../../../../assets/icons/menuby/favorites.svg'

import FingerFood from '../../../../../assets/svg/filters/sides-finger-food.svg'
import Grains from '../../../../../assets/svg/filters/sides-grains-starch.svg'
import Protein from '../../../../../assets/svg/filters/sides-protein.svg'
import Pasta from '../../../../../assets/svg/filters/sides-pasta-bread.svg'
import SidesSoup from '../../../../../assets/svg/filters/sides-soups.svg'
import Veggies from '../../../../../assets/svg/filters/sides-veggies-salads.svg'

import Pizza from '../../../../../assets/icons/menuby/pizza.svg'
import Balanced from '../../../../../assets/icons/menuby/Balanced.svg'
import Burgers from '../../../../../assets/icons/menuby/Burgers.svg'
import Noodles from '../../../../../assets/icons/menuby/Noodles.svg'
import ProteinStarch from '../../../../../assets/icons/menuby/Protein-and-starch.svg'
import Salad from '../../../../../assets/icons/menuby/Salad.svg'
import Soup from '../../../../../assets/icons/menuby/Soup.svg'
import Taco from '../../../../../assets/icons/menuby/Taco.svg'
import Veggie from '../../../../../assets/icons/menuby/Veggie.svg'

import Foryou from '../../../../../assets/icons/menuby/foryou.svg'

export const CATEGORY_PATTERN = {
  SIDES: 'category_sides',
  BREAKFAST: 'category_breakfast'
}

export const CATEGORY_LABEL = {
  SIDES: 'Sides',
  BREAKFAST: 'Breakfast'
}

export const GENERIC_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    hasSub: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.FAVORITES,
    name: 'Favorites',
    pattern: 'favorite',
    label: 'Favorites',
    image: Favorites,
    isFavorite: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.YOUVE_TRIED,
    name: 'You’ve Tried',
    pattern: 'alreadyRequested',
    label: "You've Tried",
    image: YouveTried,
    withTabs: false
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SALADS_AND_BOWLS,
    name: 'Salads/Bowls',
    pattern: 'Salads & Bowls',
    label: 'Salads/Bowls',
    image: Salad,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_BALANCED_PLATE,
    name: 'Balanced',
    pattern: 'Balanced Plate',
    label: 'Balanced',
    image: Balanced,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.BREAKFAST,
    name: 'Breakfast',
    image: Breakfast,
    pattern: CATEGORY_PATTERN.BREAKFAST,
    withTabs: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PASTA_AND_NOODLES,
    name: 'Pasta',
    pattern: 'Pasta & Noodles',
    label: 'Pasta',
    image: Noodles,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_TACOS_AND_BURRITOS,
    name: 'Tacos/Burritos',
    pattern: 'Tacos & Burritos',
    label: 'Tacos/Burritos',
    image: Taco,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.SIDES,
    name: 'Sides',
    pattern: CATEGORY_PATTERN.SIDES,
    image: Sides,
    withTabs: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_VEG_MEALS,
    name: 'Veggie',
    pattern: 'Veg Meals',
    label: 'Veggie',
    image: Veggie,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SANDWICH_AND_WRAPS,
    name: 'Sandwiches',
    pattern: 'Sandwich & Wraps, Burgers',
    label: 'Sandwiches',
    image: Burgers,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PROTEIN_STARCH,
    name: 'Protein/Starch',
    pattern: 'Protein / Starch',
    label: 'Protein/Starch',
    image: ProteinStarch,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PIZZA,
    name: 'Pizza',
    pattern: 'Pizza',
    label: 'Pizza',
    image: Pizza,
    withTabs: false,
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SOUPS_AND_STEWS,
    name: 'Soups',
    pattern: 'Soups & Stews',
    label: 'Soups',
    image: Soup,
    withTabs: false,
    mealTypeFilter: true
  }
]

export const SIDES_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.SIDES_ALL,
    image: Sides,
    name: 'All sides',
    label: 'All sides',
    pattern: CATEGORY_PATTERN.SIDES
  },
  {
    id: FILTER_IDS.SIDES_VEGGIES_SALADS,
    image: Veggies,
    name: 'Veggies and Salads',
    label: 'Veggies and Salads',
    pattern: 'Veggies & Salads'
  },
  {
    id: FILTER_IDS.SIDES_GRAINS_STARCH,
    image: Grains,
    name: 'Grains and Starch',
    label: 'Grains and Starch',
    pattern: 'Grains & Starches'
  },
  {
    id: FILTER_IDS.SIDES_PASTA_BREAD,
    image: Pasta,
    name: 'Pasta and Bread',
    label: 'Pasta and Bread',
    pattern: 'Pasta & Bread'
  },
  {
    id: FILTER_IDS.SIDES_FINGER_FOOD,
    image: FingerFood,
    name: 'Finger Food',
    label: 'Finger Food',
    pattern: 'Finger Foods'
  },
  {
    id: FILTER_IDS.SIDES_SOUPS,
    image: SidesSoup,
    name: 'Side Soups',
    label: 'Side Soups',
    pattern: 'Side Soups'
  },
  {
    id: FILTER_IDS.SIDES_PROTEIN,
    image: Protein,
    name: 'Protein',
    label: 'Protein',
    pattern: 'Protein'
  }
]

export const BREAKFASTS_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.BREAKFAST_ALL,
    image: Breakfast,
    name: 'All breakfast',
    label: 'All breakfast',
    pattern: CATEGORY_PATTERN.BREAKFAST
  },
  {
    id: FILTER_IDS.BREAKFAST_EGGS,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-eggs.svg',
    name: 'Eggs',
    label: 'Eggs',
    pattern: 'Eggs'
  },
  {
    id: FILTER_IDS.BREAKFAST_PANCAKES_WAFFLES,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-pancakes-waffles.svg',
    name: 'Pancakes & Waffles',
    label: 'Pancakes & Waffles',
    pattern: 'Pancakes & Waffles'
  },
  {
    id: FILTER_IDS.BREAKFAST_YOGURT_GRANOLA,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-yogurt-granola.svg',
    name: 'Yogurt, Granola & Oatmeal',
    label: 'Yogurt, Granola & Oatmeal',
    pattern: 'Yogurt'
  },
  {
    id: FILTER_IDS.BREAKFAST_BREAD_PASTRIES,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-bread-pastries.svg',
    name: 'Bread & Pastries',
    label: 'Bread & Pastries',
    pattern: 'Bread & Pastries'
  },
  {
    id: FILTER_IDS.BREAKFAST_SMOOTHIES_JUICES,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-juices.svg',
    name: 'Smoothies & Juices',
    label: 'Smoothies & Juices',
    pattern: 'Smoothies & Juices'
  },
  {
    id: FILTER_IDS.BREAKFAST_BARS,
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-bar.svg',
    name: 'Bars',
    label: 'Bars',
    pattern: 'Bars'
  }
]

export const FILTER_BY_FIELD = {
  TAGS: 'typesTags',
  FILTER_BY: 'filter_by'
}

export const MENU_ITEMS_V3 = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    tabs: [],
    hasGenericTabs: true
  },
  {
    id: FILTER_IDS.FAVORITES,
    name: 'Favorites',
    pattern: 'favorite',
    label: 'Favorites',
    image: Favorites,
    isFavorite: true,
    tabs: [],
    hasGenericTabs: true
  },
  {
    id: FILTER_IDS.YOUVE_TRIED,
    name: 'You’ve Tried',
    pattern: 'alreadyRequested',
    label: "You've Tried",
    image: YouveTried,
    tabs: [],
    hasGenericTabs: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SALADS_AND_BOWLS,
    name: 'Salads/Bowls',
    pattern: 'Salads & Bowls',
    label: 'Salads/Bowls',
    image: Salad,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_BALANCED_PLATE,
    name: 'Balanced',
    pattern: 'Balanced Plate',
    label: 'Balanced',
    image: Balanced,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.BREAKFAST,
    name: 'Breakfast',
    pattern: '',
    label: 'Breakfast',
    image: Breakfast,
    tabs: BREAKFASTS_MENU_ITEMS_TABS,
    hasGenericTabs: false
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PASTA_AND_NOODLES,
    name: 'Pasta',
    pattern: 'Pasta & Noodles',
    label: 'Pasta',
    image: Noodles,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_TACOS_AND_BURRITOS,
    name: 'Tacos/Burritos',
    pattern: 'Tacos & Burritos',
    label: 'Tacos/Burritos',
    image: Taco,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.SIDES,
    name: 'Sides',
    pattern: '',
    label: 'Sides',
    image: Sides,
    tabs: SIDES_MENU_ITEMS_TABS,
    hasGenericTabs: false
  },
  {
    id: FILTER_IDS.MEAL_TYPE_VEG_MEALS,
    name: 'Veggie',
    pattern: 'Veg Meals',
    label: 'Veggie',
    image: Veggie,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SANDWICH_AND_WRAPS,
    name: 'Sandwiches',
    pattern: 'Sandwich & Wraps, Burgers',
    label: 'Sandwiches',
    image: Burgers,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PROTEIN_STARCH,
    name: 'Protein/Starch',
    pattern: 'Protein / Starch',
    label: 'Protein/Starch',
    image: ProteinStarch,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_PIZZA,
    name: 'Pizza',
    pattern: 'Pizza',
    label: 'Pizza',
    image: Pizza,
    hasGenericTabs: true,
    tabs: [],
    mealTypeFilter: true
  },
  {
    id: FILTER_IDS.MEAL_TYPE_SOUPS_AND_STEWS,
    name: 'Soups',
    pattern: 'Soups & Stews',
    label: 'Soups',
    image: Soup,
    tabs: [],
    hasGenericTabs: true,
    mealTypeFilter: true
  }
]

export const MEAL_TYPES_LABEL = {
  PIZZA: 'Pizza',
  SALADS_AND_BOWLS: 'Salads & Bowls',
  SANDWICH_AND_WRAPS: 'Sandwich & Wraps',
  TACOS_AND_BURRITOS: 'Tacos & Burritos',
  PASTA_AND_NOODLES: 'Pasta & Noodles',
  PROTEIN_STARCH: 'Protein / Starch',
  VEG_MEALS: 'Veg Meals',
  BALANCED_PLATE: 'Balanced Plate',
  BURGERS: 'Burgers',
  SOUPS_AND_STEWS: 'Soups & Stews',
  BREAKFAST: 'Breakfast'
}

export const FOR_YOU_FILTER = {
  id: FILTER_IDS.FOR_YOU,
  name: 'For you',
  label: 'For you',
  image: Foryou,
  pattern: 'foryou',
  tabs: [],
  hasGenericTabs: true
}

export const GENERIC_FOR_YOU_TAB = {
  id: FILTER_IDS.FOR_YOU,
  name: 'For you',
  label: 'For you',
  image: Foryou,
  hasSub: true,
  pattern: 'foryou',
  withTabs: false
}
