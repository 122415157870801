import 'react-app-polyfill/ie11'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react'

import store from './store'
import Auth0Provider from './auth0'
import EmbeddedProvider from './embedded'
import history from './shared/history'
import { unregister } from './registerServiceWorker'
import App from './App'
import './tools'
import './i18n'
import MaintenanceModeProvider from './maintenanceModeProvider/MaintenanceModeProvider'

const onRedirectCallback = appState => {
  // Use the router's history module to replace the url
  history.replace((appState && appState.returnTo) || window.location.pathname)
}

const unleashConfig = {
  url: process.env.REACT_APP_FEATURE_FLAG_PROXY_URL,
  clientKey: process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY,
  refreshInterval: 120,
  appName: process.env.REACT_APP_FEATURE_FLAG_APP_NAME,
  environment: process.env.REACT_APP_FEATURE_FLAG_ENVIRONMENT
}

export const client = new UnleashClient(unleashConfig)

const root = createRoot(document.getElementById('root'))

root.render(
  <Router history={history}>
    <EmbeddedProvider>
      <FlagProvider unleashClient={client}>
        <MaintenanceModeProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            useRefreshTokens
            onRedirectCallback={onRedirectCallback}>
            <Provider store={store}>
              <App />
            </Provider>
          </Auth0Provider>
        </MaintenanceModeProvider>
      </FlagProvider>
    </EmbeddedProvider>
  </Router>
)

unregister()
