export const FF_ONBOARDING_HAPPY_PATH = 'onboarding-happy-path'
export const FF_ONBOARDING_HAPPY_PATH_PREFERENCES_PAGE =
  'onboarding-happy-path-preferences-page'
export const FF_REFERRAL_PAGE_EXPERIMENT = 'referral-page-experiment'
export const FF_RAFFLE = 'RAFFLE'
export const FF_ONBOARDING_HAPPY_PATH_PREFERENCES_MODAL =
  'ONBOARDING-HAPPY-PATH-PREFERENCES-MODAL'
export const FF_ONBOARDING_SKIP_MODAL = 'ONBOARDING_SKIP_MODAL'
export const FF_MENU_PROMOTIONS = 'PROMOTIONS_IN_MENU'
export const FF_AVALARA_TAXES = 'avalara-taxes'
export const FF_NEW_ORDERS_PAGE = 'new-orders-page'
export const FF_PERSONALIZED_FILTER = 'personalized-filter'
export const FF_NEWNESS_IN_MENU = 'NEWNESS_IN_MENU'
export const FF_RESURRECTION_FUNNEL = 'growth-resurrection-funnel'
export const FF_MENU_SERVICE_MIGRATE_COLLECTIONS =
  'MENU_SERVICE_MIGRATE_COLLECTIONS'
export const FF_MEALS_ALLERGENS = 'meals-service-allergens'
export const FF_REPLACE_IMAGES_WITH_IMGIX =
  'subscription-front-replace-with-imgix'
export const FF_CALENDAR_VIEW = {
  name: 'CALENDAR_VIEW',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}
export const FF_DELETE_ACCOUNT = 'delete-account'
export const FF_FAST_ORDERING = 'FAST_ORDERING'
export const FF_USE_ALLERGENS = 'meals-service-allergens'

export const FF_MENU_BY_V3 = {
  name: 'menu_by_v3',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}
export const FF_PAYMENT_METHOD_CAMPAIGN = 'PAYMENT_METHOD_CAMPAIGN'
export const FF_CART_LOADING_BAR = 'CART_LOADING_BAR'
export const FF_PAUSE_PLAN_REMOVE_ONE_WEEK = 'PAUSE_PLAN_REMOVE_ONE_WEEK'

export const FF_MENU_RECIPES = 'MENU_RECIPES'
export const FF_MEAL_DETAIL_VIDEOS = 'MEAL_DETAIL_VIDEOS'
export const FF_CROSS_SELLING_MODAL = 'CROSS_SELLING_MODAL'
export const FF_BANNER_RULES_ENGINE = 'BANNERS-RULES-ENGINE'
export const FF_RESTAURANT_SPECIALS = 'RESTAURANT_SPECIALS'

export const FF_PAUSE_FLOW_ENABLED = 'FF_PAUSE_FLOW_ENABLED'

export const FF_GIFS_IN_MEAL_CARDS = {
  name: 'GIFS_IN_MEAL_CARDS',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_USE_CHEF_SERVICE = 'use-chef-service'
export const FF_GROWTH_WELCOME_BACK_RESURRECTION_MODAL =
  'GROWTH_WELCOME_BACK_RESURRECTION_MODAL'

export const FF_SKU_LINES_TEST = {
  name: 'SKU_LINES_TEST',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}

export const FF_USE_FOR_YOU = {
  name: 'USE-FOR-YOU',
  variantsMap: {
    control: 'control',
    treatment1: 'treatment-1'
  }
}
