import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useEmbedded } from '../../../../embedded'
import BackIcon from '../../../../assets/icons/back.png'
import './View.scss'
import { RoutePaths } from 'src/routes/routePaths'

const View = props => {
  const { title, children, hideBack, testId } = props
  const { isAppEmbedded } = useEmbedded()

  return (
    <div className="cookunity__setting_view" data-test-id={testId}>
      {!isAppEmbedded && !hideBack && (
        <div className="setting_view_header">
          <Link className="back" to={RoutePaths.PLAN} replace>
            <img src={BackIcon} alt="back icon" />
          </Link>
        </div>
      )}
      {title && <h1 className="title">{title}</h1>}
      {children}
    </div>
  )
}
View.defaultProps = {
  hideBack: false,
  title: ''
}

View.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  hideBack: PropTypes.bool
}

export default View
