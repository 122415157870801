import React from 'react'
import { Route } from 'react-router-dom'

import withAuthenticationRequired from '../../../auth0/withAuthenticationRequired'

const PrivateRoute = ({ component, ...rest }) => (
  <Route component={withAuthenticationRequired(component)} {...rest} />
)

export default PrivateRoute
