import { combineReducers } from 'redux'

import app from '../shared/app/reducer'
import api from '../redux-api-bridge/reducer'
import inbox from '../redux-api-bridge/inbox/reducer'
import mealsMenuReducer from '../shared/reducers/mealsMenu'
import signupReducer from '../shared/reducers/signup'
import reactivationReducer from '../shared/reducers/reactivation'
import mealDetail from '../redux-api-bridge/mealDetail/reducer'
import orders from '../redux-api-bridge/order/reducer'
import payments from '../redux-api-bridge/payments/reducer'
import filters from '../redux-api-bridge/filters/reducer'
import changeAddress from '../redux-api-bridge/CheckChangeAddress/reducer'
import account from '../redux-api-bridge/account/reducer'
import menu from '../redux-api-bridge/menu/reducer'
import merchandiseSets from '../redux-api-bridge/merchandiseMeals/reducer'
import chefs from '../redux-api-bridge/chefs/reducer'
import share from '../redux-api-bridge/share/reducer'
import referralReducer from '../redux-api-bridge/referral/reducer'
import happyPathReducer from '../redux-api-bridge/happy-path/reducer'
import scheduleReducer from '../redux-api-bridge/schedule/reducer'
import { nextModalReducer } from '../redux-api-bridge/nextModal/reducer'
import modalsReducer from '../redux-api-bridge/modals/reducer'
import { manageOrderModalReducer } from '../redux-api-bridge/manageOrderModal/reducer'
import { orderSkippedModalReducer } from '../redux-api-bridge/orderSkippedModal/reducer'
import ordersSwimlanes from '../redux-api-bridge/orders-swimlanes/reducer'
import recipes from '../redux-api-bridge/recipes/reducer'
import fastOrdering from '../redux-api-bridge/fast-ordering/reducer'

import localstorage from './localstorage.js'
import { selectedDeliveryReducer } from 'src/redux/slices/selectedDelivery/slice'
import { trackDeliveryPanelReducer } from 'src/redux/slices/trackDeliveryPanel/slice'
import { orderDetailsOverlayReducer } from 'src/redux/slices/orderDetailsOverlay/slice'

const rootReducer = combineReducers({
  app,
  localstorage,
  api,
  inbox,
  mealDetail,
  orders,
  signup: signupReducer,
  mealsMenu: mealsMenuReducer,
  reactivation: reactivationReducer,
  payments,
  filters,
  changeAddress,
  account,
  menu,
  merchandiseSets,
  chefs,
  share,
  happyPath: happyPathReducer,
  schedule: scheduleReducer,
  nextModal: nextModalReducer,
  modals: modalsReducer,
  manageOrderModal: manageOrderModalReducer,
  orderSkippedModal: orderSkippedModalReducer,
  ordersSwimlanes,
  referralConfig: referralReducer,
  selectedDelivery: selectedDeliveryReducer,
  trackDeliveryPanel: trackDeliveryPanelReducer,
  recipes,
  fastOrdering,
  orderDetailsOverlay: orderDetailsOverlayReducer
})

export default rootReducer
