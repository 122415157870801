import { createAction } from 'redux-actions'

export const clearOrder = createAction('ORDER_CLEARED')
export const discardOrderChanges = createAction('ORDER_DISCARD_CHANGES')
export const skipOrder = createAction('ORDER_SKIPPED')

export const addProduct = createAction('PRODUCT_ADDED')
export const removeProduct = createAction('PRODUCT_REMOVED')
export const syncProducts = createAction('PRODUCTS_SYNCED')

export const getOrderDetail = createAction('ORDER_DETAIL')
