import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PrivateRoute from '../PrivateRoute'
import PublicRouteOnly from '../PublicRouteOnly'
import { RoutePaths } from '../../../routes/routePaths'
import routes from '../../../routes'
import { useAuth0 } from '@auth0/auth0-react'

const Routes = () => {
  const { isAuthenticated: authed } = useAuth0()

  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        {routes.map(route => {
          const RouteType = (() => {
            if (route.private) {
              return PrivateRoute
            }
            if (route.publicOnly) {
              return PublicRouteOnly
            }
            return Route
          })()
          return (
            <RouteType
              key={route.path}
              authed={authed}
              {...route}
              component={props => (
                <route.component authed={authed} {...props} />
              )}
            />
          )
        })}
        <Route
          component={() => {
            return <Redirect to={RoutePaths.ORDERS} />
          }}
          exact
        />
      </Switch>
    </Suspense>
  )
}

export default Routes
