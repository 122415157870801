import * as R from 'ramda'

import { checkIfMealExistIntoWishListProfiles } from '../../../../utils/utils'

import {
  ID_QUICK_FILTER_FAVORITES,
  ID_QUICK_FILTER_ALREADY_REQUESTED,
  ID_QUICK_FILTER_PREMIUM,
  SIDES_CATEGORY_ID,
  BREAKFAST_CATEGORY_ID,
  SAUCES_CATEGORY_ID
} from './constants'
import {
  FILTER_IDS,
  MEAL_TYPES_FILTERS,
  SUBCATEGORIES_FILTERS
} from '../MenuBy/Filters/constants'
import store from 'src/store'
import { MENU_CATEGORIES } from 'src/constants/menu'

const compareNumbers = (a, b) => {
  return parseInt(a, 16) - parseInt(b, 16)
}

const trimAndLower = str => (str || '').trim().toLowerCase()

const sortStars = meals => {
  return (
    meals &&
    meals.sort((a, b) => {
      const aStars = a.stars
      const bStars = b.stars

      if (aStars > bStars) return -1
      if (aStars < bStars) return 1
      return 0
    })
  )
}

const sortMeals = (meals, filterBy) => {
  return (
    meals &&
    meals.sort((a, b) => {
      const aNutritionalFact = a.nutritionalFacts[filterBy]
      const bNutritionalFact = b.nutritionalFacts[filterBy]

      return compareNumbers(aNutritionalFact, bNutritionalFact)
    })
  )
}

export const sortBy = (meals, currentSortFilter) => {
  if (!currentSortFilter) {
    return meals
  }

  const _meals_ = [...meals]
  const filterBy =
    currentSortFilter[0] && currentSortFilter[0].filterBy.toLowerCase()
  const order = currentSortFilter[0].order

  // Order Low to High
  if (
    order &&
    order.sort[0].order === 'ASC' &&
    order.sort[1].order === 'DESC'
  ) {
    return sortMeals(_meals_, filterBy)
  } else if (filterBy.includes('review')) {
    return sortStars(_meals_)
  } else {
    return _meals_
  }
}

const filterByChef = (meals, chefs) => {
  return chefs.length
    ? meals.filter(meal => chefs.find(chef => chef.value === meal.chef_id))
    : meals
}

const filterMealsByMealName = (meals, searchInputText) => {
  return searchInputText.length
    ? meals.filter(meal =>
        meal.name.toLocaleLowerCase().includes(searchInputText)
      )
    : meals
}

const filterMealsByShortDescription = (meals, searchInputText) => {
  return searchInputText.length
    ? meals.filter(meal => {
        if (meal.short_description) {
          return meal.short_description
            .toLocaleLowerCase()
            .includes(searchInputText)
        }
        return false
      })
    : meals
}

const filterMealsByChefName = (meals, searchInputText) => {
  return searchInputText.length
    ? meals.filter(meal => {
        const chefName = `${meal.chef_firstname} ${meal.chef_lastname}`
        return chefName.toLocaleLowerCase().includes(searchInputText)
      })
    : meals
}

const filterMealsBySearchInputText = (filteredMeals, searchInput) => {
  const mealsByMealName = filterMealsByMealName(filteredMeals, searchInput)
  const mealsByShortDescription = filterMealsByShortDescription(
    filteredMeals,
    searchInput
  )
  const mealsByChefName = filterMealsByChefName(filteredMeals, searchInput)

  const joinFilteredMeals = mealsByMealName.concat(
    mealsByShortDescription,
    mealsByChefName
  )

  return joinFilteredMeals.filter((item, index) => {
    return joinFilteredMeals.indexOf(item) === index
  })
}

const getQuickFiltersMeals = (
  quickFilterId,
  quickFilterPattern,
  profiles,
  filteredMeals
) => {
  switch (true) {
    case quickFilterId === ID_QUICK_FILTER_ALREADY_REQUESTED:
      return filteredMeals
    case quickFilterId === ID_QUICK_FILTER_PREMIUM:
      return filterOnlyPremiumMeals(filteredMeals)
    case MEAL_TYPES_FILTERS.includes(quickFilterId):
      return filterMealsByMealType(filteredMeals, quickFilterPattern)
    case SUBCATEGORIES_FILTERS.includes(quickFilterId):
      return filterMealsBySubcategory(filteredMeals, quickFilterPattern)
    default:
      return (
        filteredMeals &&
        filteredMeals.filter(meal => {
          let matches = true
          const pattern = quickFilterPattern
          const regex = new RegExp(pattern)
          if (pattern) {
            matches = regex.test(meal.filter_by)
          }
          if (quickFilterId === ID_QUICK_FILTER_FAVORITES && profiles) {
            matches = checkIfMealExistIntoWishListProfiles(
              meal.entity_id,
              profiles
            )
          }

          return matches
        })
      )
  }
}

const filterMealsByMealType = (meals, pattern) =>
  meals.filter(meal =>
    meal.typesTags.some(e => pattern.split(', ').includes(e))
  )

const filterMealsBySubcategory = (meals, pattern) =>
  meals.filter(meal => meal.sidesSubCategoryNames?.includes(pattern))

const filterOnlyPremiumMeals = meals =>
  meals.filter(meal => meal.premiumSpecial || meal.premium_special)

export const filterMeals = (
  filters,
  meals,
  currentChefFilter,
  currentQuickFilter,
  searchInput,
  profiles
) => {
  let filteredMeals = meals || []
  const state = store.getState()
  if (
    state.filters?.menuSelected?.id === FILTER_IDS.FOR_YOU &&
    state.filters?.selectedCategoryId === MENU_CATEGORIES.MEALS
  ) {
    filteredMeals = state.menu?.menu?.forYouMeals ?? []
  }
  const filtersByType = []

  if (!filters || R.isEmpty(filters)) return meals

  if (currentQuickFilter && !R.isEmpty(currentQuickFilter.pattern)) {
    filteredMeals = getQuickFiltersMeals(
      currentQuickFilter.id,
      currentQuickFilter.pattern,
      profiles,
      filteredMeals
    )
  }

  if (filters.proteins && filters.proteins.length) {
    filtersByType.push(
      filters.proteins.map(elem => elem.types || trimAndLower(elem.name))
    )
  }

  if (filters.diets && filters.diets.length) {
    filtersByType.push(filters.diets.map(diet => trimAndLower(diet.name)))
  }

  if (filters.cuisines && filters.cuisines.length) {
    filtersByType.push(
      filters.cuisines.map(cuisine => trimAndLower(cuisine.label))
    )
  }

  filteredMeals = !filtersByType.length
    ? filteredMeals
    : filteredMeals.filter(meal =>
        filtersByType.every(filter =>
          filter.some(value =>
            Array.isArray(value)
              ? value
                  .map(v => v.toLowerCase())
                  .includes(meal.meatType?.toLowerCase()) ||
                value.some(v => meal.filter_by.includes(v.toLowerCase()))
              : meal.filter_by.includes(value.toLowerCase())
          )
        )
      )

  if (filters.preferences && filters.preferences.length) {
    filteredMeals = filteredMeals.filter(meal =>
      filters.preferences.every(preference =>
        meal.filter_by.includes(trimAndLower(preference.name))
      )
    )
  }

  if (searchInput && searchInput.length > 0) {
    filteredMeals = filterMealsBySearchInputText(filteredMeals, searchInput)
  }

  if (currentChefFilter && !R.isEmpty(currentChefFilter)) {
    return filterByChef(filteredMeals, currentChefFilter)
  }

  return filteredMeals
}

export const filterCategory = (meals, currentQuickFilter) => {
  let filteredMeals = [...meals]
  if (currentQuickFilter && currentQuickFilter.pattern) {
    filteredMeals =
      filteredMeals &&
      filteredMeals.filter(
        meal =>
          meal.sidesSubCategoryNames &&
          meal.sidesSubCategoryNames.includes(currentQuickFilter.pattern)
      )
  }
  return filteredMeals
}

export const shouldUpdateFilters = (
  currentFilters,
  prevCurrentFilters,
  currentChefFilter,
  prevCurrentChefFilter,
  selectedCategoryId,
  prevSelectedCategoryId,
  currentQuickFilter,
  prevCurrentQuickFilter,
  searchInput,
  prevSearchInput,
  menuByFilter,
  prevMenuByFilter
) => {
  if (
    !R.equals(currentFilters, prevCurrentFilters) ||
    !R.equals(currentChefFilter, prevCurrentChefFilter) ||
    !R.equals(selectedCategoryId, prevSelectedCategoryId) ||
    !R.equals(currentQuickFilter, prevCurrentQuickFilter) ||
    !R.equals(searchInput, prevSearchInput) ||
    !R.equals(menuByFilter, prevMenuByFilter)
  ) {
    return true
  }
  return false
}

export const isAppliedFilters = (currentFilters, currentChefFilter) => {
  if (
    currentFilters &&
    (currentFilters.diets.length ||
      currentFilters.preferences.length ||
      currentFilters.proteins.length) &&
    currentChefFilter.length
  ) {
    return true
  }
  return false
}

const isDifferentSortCriteria = (prevCurrentSortBy, currentSortBy) => {
  return (
    currentSortBy.length !== prevCurrentSortBy.length || // different length
    currentSortBy.some(
      currentElem =>
        prevCurrentSortBy.findIndex(
          prevElem =>
            currentElem.name.toLowerCase() !== prevElem.name.toLowerCase()
        ) > -1
    )
  )
}

export const isApliedSortBy = (prevCurrentSortBy, currentSortBy) => {
  return (
    prevCurrentSortBy &&
    currentSortBy &&
    isDifferentSortCriteria(prevCurrentSortBy, currentSortBy)
  )
}

export const isApliedSearch = (searchInput, prevSearchInput) =>
  searchInput && !R.equals(searchInput, prevSearchInput)

const categoriesWithFilters = [
  SIDES_CATEGORY_ID,
  BREAKFAST_CATEGORY_ID,
  SAUCES_CATEGORY_ID
]

export const isCategoryWithFilters = id => categoriesWithFilters.includes(id)
