import {
  REACTIVATE_SUBSCRIPTION_INIT,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  GET_SAMPLE_MENU_INIT
} from './types'

export const reactivateSubscriptionInit = coupon => ({
  type: REACTIVATE_SUBSCRIPTION_INIT,
  coupon
})

export const reactivateSubscriptionSuccess = payload => ({
  type: REACTIVATE_SUBSCRIPTION_SUCCESS,
  payload
})

export const reactivateSubscriptionFailure = error => ({
  type: REACTIVATE_SUBSCRIPTION_FAILURE,
  error
})

export const getSampleMenuInit = payload => ({
  type: GET_SAMPLE_MENU_INIT,
  payload
})
