import React from 'react'
import * as Sentry from '@sentry/react'

import { FullScreenError } from '../components/Errors/FullScreenError'

export const WithErrorBoundary = Component =>
  Sentry.withErrorBoundary(Component, {
    showDialog: false,
    fallback: ({ resetError }) => <FullScreenError reset={resetError} />
  })
