import React, { useState } from 'react'

import Analytics from '../../../../utils/analytics'
import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'
import { CuiLink } from 'front-lib'
import { useTranslation } from 'react-i18next'
import { getUserLifespan, getUserOrdersCompleted } from '../../../../api/index'
import { usePromotions } from '../../../../hooks/usePromotions'

const ECONOMIC_PLAN_SEGMENTS = [4, 6]
const PROMOTION_ECONOMIC_PLAN_IDS = [173, 176]

export const PauseSubscription = ({ pauseDuration }) => {
  const [loadingPrecancel, setLoadingPrecancel] = useState(false)
  const { storePromotions, availablePromotions } = usePromotions()

  const { t } = useTranslation()

  const user = useSelector(state => pathOr({}, ['api', 'user', 'info'], state))
  const userId = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'id'], state)
  )

  const magentoId = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'magento_id'], state)
  )

  const isLocal = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'ring', 'is_local'], state)
  )

  const shippingDays = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'ring', 'shipping_days'], state)
  )

  const plan = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'userPlan'], state)
  )

  const getCompletedOrders = async () => {
    const completedOrders = await getUserOrdersCompleted(userId)
    return pathOr(null, ['data', 'countOrdersCompleted'], completedOrders)
  }

  const planChanges = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'planChanges'], state)
  )

  const getLifespanSegment = async () => {
    const userLifespan = await getUserLifespan()
    return pathOr(
      null,
      ['data', 'weeksSinceFirstDeliveryWhileActive'],
      userLifespan
    )
  }

  const hadEconomicPlan = () => {
    const promotionsFiltered = storePromotions.filter(promotion =>
      PROMOTION_ECONOMIC_PLAN_IDS.includes(promotion.promotionId)
    )
    const economicPlans = planChanges.filter(planChange =>
      ECONOMIC_PLAN_SEGMENTS.includes(planChange?.plan?.segmentId)
    )
    return promotionsFiltered.length > 0 || economicPlans.length > 0
  }

  const hasAvailablePromotions = () => {
    return availablePromotions && availablePromotions.length > 0
  }

  const pauseSubscription = async () => {
    if (!loadingPrecancel && window.Brightback) {
      setLoadingPrecancel(true)
      const brightBackVariables = {
        app_id: process.env.REACT_APP_BRIGHTBACK_PAUSE_FLOW_APP_ID,
        email: user.email,
        first_name: user.firstname,
        last_name: user.lastname,
        save_return_url: process.env.REACT_APP_BRIGHTBACK_PAUSE_FLOW_SAVE_URL,
        cancel_confirmation_url:
          process.env.REACT_APP_BRIGHTBACK_PAUSE_FLOW_CANCEL_URL,
        account: {
          internal_id: magentoId,
          subscription_id: userId
        },
        custom: {
          delivery_type: isLocal
            ? `Local ${shippingDays}`
            : `Regional ${shippingDays}`,
          completedOrders: await getCompletedOrders(),
          lifespan_segment: await getLifespanSegment(),
          weeksPause: pauseDuration.value,
          hasEconomicPlan: ECONOMIC_PLAN_SEGMENTS.includes(plan.segmentId),
          hadEconomicPlan: hadEconomicPlan(),
          hasAvailablePromotions: hasAvailablePromotions()
        }
      }
      window.Brightback.handleDataPromise(brightBackVariables)
        .then(res => {
          setLoadingPrecancel(false)
          if (res.valid) {
            Analytics.track('Pause Subscription', {
              email: user.email,
              first_name: user.firstname,
              last_name: user.lastname,
              cancel: true
            })
            window.location.href = res.url
          }
        })
        .catch(() => {
          setLoadingPrecancel(false)
        })
    }
  }

  return (
    <div className="pause_subscription">
      <CuiLink style={{ marginRight: '15px' }} onClick={pauseSubscription}>
        {t('pages.my_plan.pause')}
      </CuiLink>
    </div>
  )
}
