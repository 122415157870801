import './EconomicPlanModal.scss'

import { FC, useEffect } from 'react'
import { getPlatformText, isMobileDevice } from '../../../../utils/utils'

import { CuiModal } from 'front-lib'
import tracking from '../../../../shared/tracking'
import { useTranslation } from 'react-i18next'

const SUPPORT_EMAIL = 'support@cookunity.com'

interface Props {
  showEconomicPlanModal: boolean
  setShowEconomicPlanModal: (showEconomicPlanModal: boolean) => void
}

export const EconomicPlanModal: FC<Props> = ({
  showEconomicPlanModal,
  setShowEconomicPlanModal
}) => {
  const { t } = useTranslation()
  const platform = getPlatformText(isMobileDevice())

  useEffect(() => {
    tracking.track('Overlay Viewed', {
      overlay_name: `$8.5 Meal Offer - Learn more modal`,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'My Plan',
      overlay_trigger: 'manual'
    })
  }, [])

  const handleClose = () => {
    tracking.track('Overlay Closed', {
      overlay_name: `$8.5 Meal Offer - Learn more modal`,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'My Plan',
      overlay_trigger: 'manual'
    })
    setShowEconomicPlanModal(false)
  }

  return (
    <CuiModal
      isOpen={showEconomicPlanModal}
      onRequestClose={handleClose}
      iconClassName="economic-plan-modal__close-icon">
      <div className="economic-plan-modal__title-container">
        <div className="economic-plan-modal__title">
          {t(`pages.my_plan.economic_meal_modal.title`)}
        </div>
      </div>
      <div className="economic-plan-modal__text-container">
        <div className="economic-plan-modal__text">
          {t(`pages.my_plan.economic_meal_modal.text_1.${platform}`)}
        </div>
        <div className="economic-plan-modal__text">
          {t(`pages.my_plan.economic_meal_modal.text_2.${platform}`)}
          <a target="_new" href="mailto:support@cookunity.com">
            <u>{SUPPORT_EMAIL}</u>
          </a>
        </div>
      </div>
    </CuiModal>
  )
}
