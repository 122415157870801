import React from 'react'
import { useSelector } from 'react-redux'

const withSelectorForTracking = selector => Component => {
  return props => {
    const selectorWithUse = useSelector(selector)

    const previousTrackingInfo = props.trackingInfo || {}

    const extendedProps = {
      ...props,
      trackingInfo: {
        ...previousTrackingInfo,
        ...selectorWithUse
      }
    }

    return <Component {...extendedProps} />
  }
}

export default withSelectorForTracking
