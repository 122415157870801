import { FILTER_IDS } from '../../../modules/MyPlan/components/MenuBy/Filters/constants'

export const FILTERS = {
  PROTEINS: 'proteins',
  DIETS: 'diets',
  PREFERENCES: 'preferences',
  CUISINES: 'cuisines',
  CHEFS: 'chefs'
}

export const FILTERS_SECTIONS_TITLES = {
  [FILTERS.PROTEINS]: 'Protein type',
  [FILTERS.DIETS]: 'Diets',
  [FILTERS.PREFERENCES]: 'Preferences',
  [FILTERS.CUISINES]: 'Cuisines',
  [FILTERS.CHEFS]: 'Chefs'
}

export const MENU_BY_ADVANCED_FILTER_MAP = {
  [FILTER_IDS.PROTEIN]: FILTERS.PROTEINS,
  [FILTER_IDS.DIET]: FILTERS.DIETS,
  [FILTER_IDS.PREFERENCES]: FILTERS.PREFERENCES,
  [FILTER_IDS.CUISINE]: FILTERS.CUISINES,
  [FILTER_IDS.CHEF]: FILTERS.CHEFS
}

export const ALLERGENS_IDS = {
  DAIRY_FREE: 2,
  SOY_FREE: 5,
  TREENUT_FREE: 1,
  PEANUT_FREE: 14
}

export const PREFERENCES_IDS = {
  DAIRY_FREE: 1,
  SOY_FREE: 4,
  NUT_FREE: 10
}
