import { createSelector } from 'reselect'
import { head, pathOr } from 'ramda'

import {
  SIGNUP_LOGIN_INIT,
  SIGNUP_LOGIN_SUCCESS,
  SIGNUP_LOGIN_FAILURE,
  PUBLIC_ADD_ADDRESS_INIT,
  PUBLIC_ADD_ADDRESS_SUCCESS,
  PUBLIC_ADD_ADDRESS_FAILURE,
  PUBLIC_ADD_CARD_INIT,
  PUBLIC_ADD_CARD_SUCCESS,
  PUBLIC_ADD_CARD_FAILURE,
  CREATE_SIGNUP_INIT,
  CREATE_SIGNUP_SUCCESS,
  CREATE_SIGNUP_FAILURE,
  SET_USER_DATA_INIT,
  APPLY_COUPON_INIT,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  ERASE_COUPON_ERROR,
  INIT_SIGN_UP,
  INIT_SIGN_UP_SUCCESS,
  INIT_SIGN_UP_FAILURE,
  GET_SIGN_UP_PUBLIC_DATA_INIT,
  GET_SIGN_UP_PUBLIC_DATA_SUCCESS,
  GET_SIGN_UP_PUBLIC_DATA_FAILURE,
  GET_SIGN_UP_MEALS_PLAN_DATA_INIT,
  GET_SIGN_UP_MEALS_PLAN_DATA_SUCCESS,
  GET_SIGN_UP_MEALS_PLAN_DATA_FAILURE,
  HANDLE_INPUT_CHANGE,
  HANDLE_ORDER_CHANGE,
  SET_UUID,
  SIGN_UP_LOG,
  SIGN_UP_LOG_SUCCESS,
  SIGN_UP_LOG_FAILURE
} from '../actions/types'

const initialState = {
  loading: true,
  dietaryPreferences: [],
  diets: [],
  tastes: [],
  mealPlans: {},
  timeslots: [],
  zipcode: '',
  deliveryDays: [],
  upcomingDays: [],
  coupon: '',
  error: {},
  loginLoading: false,
  addressLoading: false,
  cardLoading: false,
  createLoading: false,
  couponLoading: false,
  userData: {
    zipcode: '',
    utm: '',
    startDay: {},
    days: [],
    timeslot: {},
    delivery_options: 'Leave at my door',
    order: {},
    dietaryPreferences: [],
    dietaryRestrictions: [],
    tastes: [],
    selectedPlan: {},
    diet: null,
    flow: 'eat-everything',
    experiment: false
  },
  order: {},
  credentials: {},
  address: {},
  cards: {},
  createdUser: {},
  couponData: {},
  errors: {
    login: '',
    address: '',
    cards: '',
    createUser: '',
    coupon: ''
  },
  uuid: null,
  log: {
    message: '',
    error: '',
    loading: false
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case INIT_SIGN_UP:
      return { ...state, ...action.payload }
    case GET_SIGN_UP_PUBLIC_DATA_INIT:
      return { ...state, loading: true }
    case INIT_SIGN_UP_SUCCESS:
    case GET_SIGN_UP_PUBLIC_DATA_SUCCESS: {
      const { selectedPlan, timeslot, days, startDay, ...rest } = action.payload
      return {
        ...state,
        loading: false,
        userData: { ...state.userData, selectedPlan, timeslot, days, startDay },
        ...rest
      }
    }
    case INIT_SIGN_UP_FAILURE:
    case GET_SIGN_UP_PUBLIC_DATA_FAILURE:
      return { ...state, loading: false, error: { ...action.error } }
    case GET_SIGN_UP_MEALS_PLAN_DATA_INIT:
      return { ...state, loading: true }
    case GET_SIGN_UP_MEALS_PLAN_DATA_SUCCESS:
      return { ...state, loading: false, mealPlans: [...action.payload] }
    case GET_SIGN_UP_MEALS_PLAN_DATA_FAILURE:
      return { ...state, loading: false, error: { ...action.error } }
    case SIGNUP_LOGIN_INIT:
      return {
        ...state,
        loginLoading: true
      }
    case SIGNUP_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        credentials: { ...state.credentials, ...action.payload },
        address: {
          ...state.address,
          ...head(
            action.payload.addresses.filter(
              address => address.isActive && !!address.street
            )
          )
        },
        cards: { ...state.cards, ...head(action.payload.cards) }
      }
    case SIGNUP_LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        errors: { ...state.errors, login: action.error.error }
      }
    case PUBLIC_ADD_ADDRESS_INIT:
      return {
        ...state,
        addressLoading: true
      }
    case PUBLIC_ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        addressLoading: false,
        address: action.payload
      }
    case PUBLIC_ADD_ADDRESS_FAILURE:
      return {
        ...state,
        addressLoading: false,
        errors: { ...state.errors, address: action.error.error }
      }
    case PUBLIC_ADD_CARD_INIT:
      return { ...state, cardLoading: true }
    case PUBLIC_ADD_CARD_SUCCESS:
      return {
        ...state,
        cardLoading: false,
        cards: action.payload
      }
    case PUBLIC_ADD_CARD_FAILURE:
      return {
        ...state,
        cardLoading: false,
        errors: { cards: action.error.error }
      }
    case CREATE_SIGNUP_INIT:
      return { ...state, createLoading: true, loading: false }
    case CREATE_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        createLoading: false,
        createdUser: action.payload
      }
    case CREATE_SIGNUP_FAILURE:
      return {
        ...state,
        createLoading: false,
        loading: false,
        cards: '',
        errors: { createUser: action.error.error }
      }
    case SET_USER_DATA_INIT:
      return {
        ...state,
        userData: {
          ...action.payload
        }
      }
    case APPLY_COUPON_INIT:
      return {
        ...state,
        couponLoading: true,
        errors: { ...state.errors, coupon: '' }
      }
    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        couponLoading: false,
        couponData: { ...action.payload },
        errors: { ...state.errors, coupon: '' }
      }
    case APPLY_COUPON_FAILURE:
      return {
        ...state,
        couponLoading: false,
        errors: { ...state.errors, coupon: action.error }
      }
    case ERASE_COUPON_ERROR:
      return {
        ...state,
        errors: { ...state.errors, coupon: '' }
      }
    case HANDLE_INPUT_CHANGE:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload
        }
      }
    case HANDLE_ORDER_CHANGE:
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload
        }
      }
    case SET_UUID:
      return { ...state, uuid: action.uuid }
    case SIGN_UP_LOG:
      return {
        ...state,
        log: { ...state.log, loading: true, message: action.message }
      }
    case SIGN_UP_LOG_SUCCESS:
      return { ...state, log: { ...state.log, loading: false } }
    case SIGN_UP_LOG_FAILURE:
      return {
        ...state,
        log: { ...state.log, loading: false, error: action.error }
      }
    default:
      return state
  }
}

const getSignup = state => state.signup
export const getLoginLoading = createSelector(
  getSignup,
  signup => signup.loginLoading
)
export const getAddressLoading = createSelector(
  getSignup,
  signup => signup.addressLoading
)
export const getCardLoading = createSelector(
  getSignup,
  signup => signup.cardLoading
)
export const getCreateLoading = createSelector(
  getSignup,
  signup => signup.createLoading
)
export const getCouponLoading = createSelector(
  getSignup,
  signup => signup.couponLoading
)
export const getCredentials = createSelector(
  getSignup,
  signup => signup.credentials
)
export const getAddress = createSelector(getSignup, signup => signup.address)
export const getCards = createSelector(getSignup, signup => signup.cards)

export const getUserData = createSelector(getSignup, signup => signup.userData)

export const getOrder = createSelector(getSignup, signup => signup.order)

export const getCreatedUser = createSelector(
  getSignup,
  signup => signup.createdUser
)

export const getCoupon = createSelector(getSignup, signup => signup.couponData)

export const getCheckoutErrors = createSelector(
  getSignup,
  signup => signup.errors
)
export const getLoginError = createSelector(
  getCheckoutErrors,
  errors => errors.login
)
export const getAddressError = createSelector(
  getCheckoutErrors,
  errors => errors.address
)
export const getCardsError = createSelector(
  getCheckoutErrors,
  errors => errors.cards
)
export const getCreateUserError = createSelector(
  getCheckoutErrors,
  errors => errors.createUser
)
export const getCouponError = createSelector(
  getCheckoutErrors,
  errors => errors.coupon
)

export const getIsLoading = createSelector(getSignup, signup => signup.loading)
export const getDietaryPreferences = createSelector(
  getSignup,
  signup => signup.dietaryPreferences
)
export const getDiets = createSelector(getSignup, signup => signup.diets)
export const getTastes = createSelector(getSignup, signup => signup.tastes)
export const getMealPlans = createSelector(
  getSignup,
  signup => signup.mealPlans
)
export const getAllMealPlans = createSelector(
  getMealPlans,
  mealPlans => mealPlans.allPlans
)
export const getFeaturedMealPlans = createSelector(
  getMealPlans,
  mealPlans => mealPlans.featuredPlans
)
export const getTimeslots = createSelector(
  getSignup,
  signup => signup.timeslots
)
export const getZipcode = createSelector(getSignup, signup => signup.zipcode)
export const getDeliveryDays = createSelector(
  getSignup,
  signup => signup.deliveryDays
)

export const getUpcomingDays = state =>
  pathOr([], ['signup', 'upcomingDays'], state)

export const getErrors = createSelector(getSignup, signup => signup.error)

export const getExperiment = createSelector(
  getSignup,
  signup => signup.userData.experiment
)

export const getUUID = createSelector(getSignup, signup => signup.uuid)
