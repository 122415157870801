export const MENU_VIEW_MODE = {
  CONDENSED: 'condensed',
  REGULAR: 'regular'
}

export const MENU_CATEGORIES = {
  MEALS: 0,
  SIDES: 3632,
  BREAKFAST: 3110,
  SAUCES: 5808,
  DRINKS: 33,
  TREATS: 34
}

export const SORTING_TYPES = {
  FOR_YOU: 'FOR_YOU'
}

export const SURVEY_NAMES = {
  FOR_YOU: 'for_you'
}
