export const PLATFORM = {
  mobileWeb: 'MobileWeb',
  webDesktop: 'WebDesktop'
}

export const SCROLL_POSITION_LIMIT_MOBILE = 30
export const SCROLL_POSITION_LIMIT_WEB = 190

export const PAGE_HEADER_HEIGHT = 48
export const PAGE_HEADER_HEIGHT_MOBILE = 750

export const RESURRECTION_PATH_COOKIE_NAME = 'cu_resu_lp'
