import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { RoutePaths } from '../../../routes/routePaths'

const PublicRouteOnly = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authed ? <Redirect to={RoutePaths.ORDERS} /> : <Component {...props} />
      }
    />
  )
}

export default PublicRouteOnly
