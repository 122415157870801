import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import reducers from './reducers'
import rootSaga from './shared/sagas'

// apiAuthInjector
const sagaMiddleware = createSagaMiddleware()
const middleware = [thunk, sagaMiddleware]
const bannedActions = [] // use this to ban some actions from the redux console

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_ACTION_LOG === 'true'
) {
  middleware.push(
    createLogger({
      predicate: (getState, action) =>
        !bannedActions.some(a => a === action.type)
    })
  )
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middleware))
)
sagaMiddleware.run(rootSaga)

export default store
