import { ADDONS_CATEGORIES } from '../MyPlan/utils'
import { buildImgUrl, getTotalMealsOrder } from '../../utils/utils'
import moment from 'moment'
import tracking from '../../shared/tracking'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const CHEF_IMG_URL_SOURCE =
  process.env.REACT_APP_CHEF_IMG_URL_SOURCE + '/media/avatar-small/thumbnail/'

export const getOrderTitle = items => {
  const { meals, addons } = items.reduce(
    (data, item) =>
      ADDONS_CATEGORIES.includes(item.product.category_id)
        ? {
            ...data,
            addons: data.addons + item.qty
          }
        : {
            ...data,
            meals: data.meals + item.qty
          },
    { meals: 0, addons: 0 }
  )

  return [
    `${meals} ${meals > 1 ? 'meals' : 'meal'}`,
    ...(addons
      ? [`and other ${addons} ${addons > 1 ? 'products' : 'product'}`]
      : []),
    "you've chosen"
  ]
    .join(' ')
    .trim()
}

export const getMeals = items => {
  return items.map(item => {
    return {
      ...item,
      id: +item.product.id,
      mealName: `${item.product.name ? item.product.name : ''}`.trim(),
      mealDescription: `${
        item.product.short_description ? item.product.short_description : ''
      }`.trim(),
      name: `${item.product.name ? item.product.name : ''} ${
        item.product.short_description ? item.product.short_description : ''
      }`.trim(),
      image:
        (item.product.image || '').substr(-12) === 'no_selection'
          ? ''
          : item.product.image,
      chef_firstname: item.product.chef_firstname
        ? item.product.chef_firstname.trim()
        : '',
      chef_lastname: item.product.chef_lastname
        ? item.product.chef_lastname.trim()
        : '',
      chefId: item.product.chef_id,
      is_celebrity_chef: item.product.is_celebrity_chef === '1',
      chefImage: CHEF_IMG_URL_SOURCE + item.product.bannerpic,
      quantity: item.qty,
      meatType: item.product.meat_type,
      calories: +item.product.calories,
      tags: item.product.calories
        ? [{ label: `${item.product.calories} cal` }]
        : [],
      premium_special: item.product ? item.product.premium_special : null
    }
  })
}

export const getMealsFromCart = items => {
  return items.map(item => {
    return {
      ...item,
      id: +item.id || +item.entity_id,
      chefId: item.chef_id,
      mealName: `${item.name ? item.name : ''}`.trim(),
      mealDescription: `${
        item.short_description ? item.short_description : ''
      }`.trim(),
      image: buildImgUrl(IMG_URL_SOURCE, item.image_path, item.image),
      chefImage:
        CHEF_IMG_URL_SOURCE +
        (item.bannerpic
          ? item.bannerpic
              .replace('/media/avatar/', '')
              .replace('/media/avatar-small/thumbnail/', '')
          : ''),
      chef_firstname: item.chef_firstname ? item.chef_firstname.trim() : '',
      chef_lastname: item.chef_lastname ? item.chef_lastname.trim() : '',
      is_celebrity_chef: item.is_celebrity_chef === 'true',
      calories: +item.calories,
      quantity: item.qty,
      meatType: item.meat_type,
      tags: item.calories ? [{ label: `${item.calories} cal` }] : []
    }
  })
}

export const getRecommendations = items => {
  return items.map(item => ({
    ...item,
    id: +item.id,
    mealName: `${item.name ? item.name : ''}`.trim(),
    mealDescription: `${
      item.short_description ? item.short_description : ''
    }`.trim(),
    name: `${item.name ? item.name : ''} ${
      item.short_description ? item.short_description : ''
    }`.trim(),
    image: item.image.full_path,
    chefName: `${item.chef_firstname ? item.chef_firstname : ''} ${
      item.chef_lastname ? item.chef_lastname : ''
    }`.trim(),
    chefId: item.chef_id,
    is_celebrity_chef: item.is_celebrity_chef === 'true',
    quantity: item.qty,
    meatType: item.meat_type,
    tags: item.calories ? [{ label: `${item.calories} cal` }] : [],
    chefImage: CHEF_IMG_URL_SOURCE + item.bannerpic,
    product: {
      mealName: item.name,
      short_description: item.short_description,
      chef_firstname: item.chef_firstname,
      chef_lastname: item.chef_lastname
    },
    chef_img: CHEF_IMG_URL_SOURCE + item.bannerpic,
    premium_special: item.premium_special
  }))
}

export const upcomingDeliverySkeleton = [
  {
    holidayMessage: null,
    available: true,
    canEdit: true,
    date: '2019-12-22',
    displayDate: '2019-12-22',
    skip: false,
    rescheduled: false,
    scheduled: true,
    cutoff: {
      time: '2019-12-20T22:00:00.000Z',
      userTimeZone: 'America/New_York',
      utcOffset: '-300'
    },
    recommendation: null,
    order: {
      id: '303548',
      delivery_date: '2019-12-22',
      time_start: '18:00',
      time_end: '20:00',
      items: [
        {
          product: {
            id: '2630',
            name: 'Buffalo Chicken Wings',
            sku: 'NYBUFFALO-CHICKEN-WINGS-03',
            short_description: 'with chunky Blue Cheese      aolii',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/b/u/buffalo-chicken-wiings-actual2copy.jpeg',
            calories: '1256',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Chris',
            chef_lastname: ' Ratel',
            chef_id: '1471',
            logopic: 'chris-small33951.jpg',
            bannerpic: 'chris-small33951.jpg',
            tags:
              'Free Range, Gluten Free, High Calories Diet, High Fat, High Protein, Hormone Free, Keto Diet, Low Carbs, Spicy',
            meat_type: 'Chicken'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8
          },
          qty: 1
        },
        {
          product: {
            id: '764',
            name: 'Stuffed shells',
            sku: 'STUFFED-SHELLS-764',
            short_description: 'with Ricotta and Spinach',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/s/t/stuffed-shells_.jpeg',
            calories: '634',
            category_id: 4,
            sidedish: null,
            chef_firstname: 'Raymundo',
            chef_lastname: ' Agrazal',
            chef_id: '14414',
            logopic: 'IMG_805115793.jpg',
            bannerpic: 'IMG_805115793.jpg',
            tags:
              'High Fat, Low Sodium, Low Sugar, Nut Free, Soy Free, Super Foods, Vegetarian',
            meat_type: 'Vegetarian'
          },
          price: {
            price: 13.3,
            originalPrice: 13.3
          },
          qty: 1
        },
        {
          product: {
            id: '1525',
            name: 'Greek Chicken',
            sku: 'KETO-GREEK-CHICKEN',
            short_description:
              'Cauliflower tabbouleh with cucumber, tahini sauce',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/g/r/greek-chicken.jpeg',
            calories: '533',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Ruben ',
            chef_lastname: ' Garcia',
            chef_id: '6982',
            logopic: 'garcia-th95905.jpg',
            bannerpic: 'garcia-th95905.jpg',
            tags:
              'Dairy Free, Gluten Free, High Fat, High Protein, Hormone Free, Keto Diet, Less Than 600 calories, Low Carbs, Low Sodium, Nut Free, Paleo',
            meat_type: 'Chicken'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8
          },
          qty: 1
        },
        {
          product: {
            id: '1211',
            name: 'BBQ Turkey and Veggie Meatloaf',
            sku: 'BBQ-TURKEY-ANDRES',
            short_description: 'with parsnip mashed',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/b/b/bbq-turkey.jpeg',
            calories: '709',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Andres',
            chef_lastname: 'Mendez',
            chef_id: '3755',
            logopic: 'andres-small91554.jpg',
            bannerpic: 'andres-small91554.jpg',
            tags: 'Free Range, High Protein, Hormone Free, Nut Free',
            meat_type: 'Turkey'
          },
          price: {
            price: 14,
            originalPrice: 14
          },
          qty: 1
        }
      ],
      tip: null,
      grandTotal: 61.95,
      orderStatus: {
        state: 'processing',
        status: 'complete'
      }
    },
    __typename: 'OrderDay',
    disabled: true
  },
  {
    holidayMessage: null,
    available: true,
    canEdit: true,
    date: '2019-12-23',
    displayDate: '2019-12-23',
    skip: false,
    rescheduled: false,
    scheduled: true,
    cutoff: {
      time: '2019-12-21T22:00:00.000Z',
      userTimeZone: 'America/New_York',
      utcOffset: '-300'
    },
    recommendation: null,
    order: {
      id: '303547',
      delivery_date: '2019-12-23',
      time_start: '18:00',
      time_end: '20:00',
      items: [
        {
          product: {
            id: '2657',
            name: 'Plant Bowl',
            sku: 'PLANT-BOWL',
            short_description: null,
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/p/l/plant-bowl.jpeg',
            calories: '692',
            category_id: 8,
            sidedish: null,
            chef_firstname: 'Andres',
            chef_lastname: 'Mendez',
            chef_id: '3755',
            logopic: 'andres-small91554.jpg',
            bannerpic: 'andres-small91554.jpg',
            tags:
              'Contains Nuts, Dairy Free, Gluten Free, High Fat, Low Sodium, Low Sugar, Soy Free, Vegetarian',
            meat_type: 'Vegan'
          },
          price: {
            price: 13.3,
            originalPrice: 13.3
          },
          qty: 1
        },
        {
          product: {
            id: '715',
            name: 'Herbed Crusted Chicken',
            sku: 'HERBED-CRUSTED-CHICKEN',
            short_description: 'with Sauteed Carrots',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/h/e/herbed-crusted-chicken-ruben-actual.jpeg',
            calories: '550',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Ruben ',
            chef_lastname: ' Garcia',
            chef_id: '6982',
            logopic: 'garcia-th95905.jpg',
            bannerpic: 'garcia-th95905.jpg',
            tags:
              'High Protein, Less Than 600 calories, Low Sodium, Low Sugar, Nut Free, Soy Free, Spicy, Super Foods',
            meat_type: 'Chicken'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8
          },
          qty: 1
        },
        {
          product: {
            id: '764',
            name: 'Stuffed shells',
            sku: 'STUFFED-SHELLS-764',
            short_description: 'with Ricotta and Spinach',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/s/t/stuffed-shells_.jpeg',
            calories: '634',
            category_id: 4,
            sidedish: null,
            chef_firstname: 'Raymundo',
            chef_lastname: ' Agrazal',
            chef_id: '14414',
            logopic: 'IMG_805115793.jpg',
            bannerpic: 'IMG_805115793.jpg',
            tags:
              'High Fat, Low Sodium, Low Sugar, Nut Free, Soy Free, Super Foods, Vegetarian',
            meat_type: 'Vegetarian'
          },
          price: {
            price: 13.3,
            originalPrice: 13.3
          },
          qty: 1
        },
        {
          product: {
            id: '1026',
            name: 'Eggplant Parmigiana',
            sku: 'EGGPLANT-PARMIGIANA',
            short_description: 'with garlic bread and       cheese.',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/e/g/eggplant-parmigiana.jpeg',
            calories: '723',
            category_id: 4,
            sidedish: null,
            chef_firstname: 'Chris',
            chef_lastname: 'Massiah',
            chef_id: '24569',
            logopic: 'chrismassiah278111.jpg',
            bannerpic: 'chrismassiah278111.jpg',
            tags:
              'High Fat, Low Carbs, Low Sodium, Nut Free, Soy Free, Vegetarian',
            meat_type: 'Vegetarian'
          },
          price: {
            price: 13.3,
            originalPrice: 13.3
          },
          qty: 1
        }
      ],
      tip: null,
      grandTotal: 59.55,
      orderStatus: {
        state: 'processing',
        status: 'complete'
      }
    },
    __typename: 'OrderDay',
    disabled: true
  },
  {
    holidayMessage: null,
    available: true,
    canEdit: true,
    date: '2019-12-24',
    displayDate: '2019-12-24',
    skip: false,
    rescheduled: false,
    scheduled: true,
    cutoff: {
      time: '2019-12-22T22:00:00.000Z',
      userTimeZone: 'America/New_York',
      utcOffset: '-300'
    },
    recommendation: null,
    order: {
      id: '303549',
      delivery_date: '2019-12-24',
      time_start: '18:00',
      time_end: '20:00',
      items: [
        {
          product: {
            id: '715',
            name: 'Herbed Crusted Chicken',
            sku: 'HERBED-CRUSTED-CHICKEN',
            short_description: 'with Sauteed Carrots',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/h/e/herbed-crusted-chicken-ruben-actual.jpeg',
            calories: '550',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Ruben ',
            chef_lastname: ' Garcia',
            chef_id: '6982',
            logopic: 'garcia-th95905.jpg',
            bannerpic: 'garcia-th95905.jpg',
            tags:
              'High Protein, Less Than 600 calories, Low Sodium, Low Sugar, Nut Free, Soy Free, Spicy, Super Foods',
            meat_type: 'Chicken'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8
          },
          qty: 1
        },
        {
          product: {
            id: '1033',
            name: 'Salmon  with Berry Glaze',
            sku: 'SALMON-WITH-BERRY-GLAZE',
            short_description: 'and Sauteed Spinach',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/s/a/salmon-with-berry-glaze.jpeg',
            calories: '537',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Chris',
            chef_lastname: 'Massiah',
            chef_id: '24569',
            logopic: 'chrismassiah278111.jpg',
            bannerpic: 'chrismassiah278111.jpg',
            tags:
              'Dairy Free, Gluten Free, High Fat, Less Than 600 calories, Low Carbs, Low Sodium, Nut Free, Soy Free, Super Foods',
            meat_type: 'Salmon'
          },
          price: {
            price: 16.1,
            originalPrice: 16.1
          },
          qty: 1
        },
        {
          product: {
            id: '2395',
            name: 'Italian Meatballs',
            sku: 'NY-PALEO-ITALIAN-MEATBALLS-RAY-02',
            short_description: 'and Braised Greens Bowl',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/i/t/italianmeatballs.jpeg',
            calories: '861',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Raymundo',
            chef_lastname: ' Agrazal',
            chef_id: '14414',
            logopic: 'IMG_805115793.jpg',
            bannerpic: 'IMG_805115793.jpg',
            tags:
              'Dairy Free, Gluten Free, High Fat, High Protein, Hormone Free, Low Carbs, Low Sugar, Paleo',
            meat_type: 'Beef'
          },
          price: {
            price: 14,
            originalPrice: 14
          },
          qty: 1
        },
        {
          product: {
            id: '2932',
            name: 'Turmeric Spiced Chicken',
            sku: 'TURMERIC-SPICED-CHICKEN-5',
            short_description: null,
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/t/u/turmeric-spiced-chicken-actual.jpeg',
            calories: '575',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Raymundo',
            chef_lastname: ' Agrazal',
            chef_id: '14414',
            logopic: 'IMG_805115793.jpg',
            bannerpic: 'IMG_805115793.jpg',
            tags:
              'Contains Nuts, Dairy Free, Gluten Free, High Fat, High Protein, Keto Diet, Less Than 600 calories, Low Carbs, Low Sodium, Paleo',
            meat_type: 'Chicken'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8
          },
          qty: 1
        }
      ],
      tip: null,
      grandTotal: 65,
      orderStatus: {
        state: 'processing',
        status: 'complete'
      }
    },
    __typename: 'OrderDay',
    disabled: true
  },
  {
    available: true,
    canEdit: true,
    date: '2019-10-18',
    displayDate: '2019-10-18',
    skip: false,
    rescheduled: false,
    scheduled: true,
    cutoff: {
      time: '2019-10-16T21:00:00.000Z',
      userTimeZone: 'America/New_York',
      utcOffset: '-240'
    },
    recommendation: null,
    order: {
      id: '289475',
      delivery_date: '2019-10-18',
      time_start: '15:00',
      time_end: '17:00',
      items: [
        {
          product: {
            id: '2745123213',
            name: 'Caraway Pork Loin',
            sku: 'CARAWAY-PORK-LOIN',
            short_description: 'with Cilantro Rice and Braised Red Cabbage',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/a/caraway-pork-loin-main.jpg',
            calories: '570',
            category_id: 2,
            sidedish: null,
            chef_firstname: 'James ',
            chef_lastname: 'Grody',
            chef_id: '60294',
            logopic: 'chef-james-grody7530.jpg',
            bannerpic: 'chef-james-grody7530.jpg',
            tags:
              'Dairy Free, Gluten Free, Less Than 600 calories, Low Fat, Soy Free',
            meat_type: 'Pork'
          },
          price: {
            price: 14.6,
            originalPrice: 14.6
          },
          qty: 1
        },
        {
          product: {
            id: '522',
            name: 'Cider Braised Chicken',
            sku: 'CIDER-BRAISED-CHICKEN',
            short_description: 'with Parsnip Puree',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/i/cider-braised-chicken2.jpg',
            calories: '439',
            category_id: 3,
            sidedish: null,
            chef_firstname: 'Chris',
            chef_lastname: ' Ratel',
            chef_id: '1471',
            logopic: 'chris-small33951.jpg',
            bannerpic: 'chris-small33951.jpg',
            tags:
              'Gluten Free, Hormone Free, Less Than 600 calories, Low Carbs, Low Sodium, Paleo, Seasonal, Soy Free',
            meat_type: 'Chicken',
            __typename: 'ProductType'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8,
            __typename: 'PriceType'
          },
          qty: 1,
          __typename: 'OrderItem'
        },
        {
          product: {
            id: '1385',
            name: 'Enchilada Casserole',
            sku: 'ENCHILADA-CASSEROLE',
            short_description: null,
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/e/n/enchilada-casserole-031-640x480.jpg',
            calories: '726',
            category_id: 4,
            sidedish: '1688',
            chef_firstname: 'Andres',
            chef_lastname: 'Mendez',
            chef_id: '3755',
            logopic: 'andres-small91554.jpg',
            bannerpic: 'andres-small91554.jpg',
            tags:
              'Contains Nuts, Dairy Free, Gluten Free, High Fat, Spicy, Vegan, Vegetarian',
            meat_type: 'Vegan',
            __typename: 'ProductType'
          },
          price: {
            price: 13.3,
            originalPrice: 13.3,
            __typename: 'PriceType'
          },
          qty: 1,
          __typename: 'OrderItem'
        },
        {
          product: {
            id: '2780',
            name: 'Mushroom    Crusted    Turkey Cutlets',
            sku: 'MUSHROOM-CRUSTED-TURKEY CUTLETS',
            short_description: 'with Zucchini Noodles',
            image_path: '/media/catalog/product/cache/x600/',
            image:
              'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/m/u/mushroom-crusted-turkey.jpeg',
            calories: '291',
            category_id: 2,
            sidedish: null,
            chef_firstname: 'James ',
            chef_lastname: 'Grody',
            chef_id: '60294',
            logopic: 'chef-james-grody7530.jpg',
            bannerpic: 'chef-james-grody7530.jpg',
            tags:
              'Dairy Free, Gluten Free, High Protein, Less Than 600 calories, Low Carbs, Low Fat, Low Sodium, Nut Free, Soy Free',
            meat_type: 'Turkey',
            __typename: 'ProductType'
          },
          price: {
            price: 14.8,
            originalPrice: 14.8,
            __typename: 'PriceType'
          },
          qty: 1,
          __typename: 'OrderItem'
        }
      ],
      tip: 1,
      grandTotal: 76.87,
      orderStatus: {
        state: 'processing',
        status: 'complete'
      }
    }
  }
]

export const getUpcomingDeliveryByParams = (upcomingDeliveries, date) => {
  const dateFormat = moment(date, 'YYYYMMDD', true).format('YYYY-MM-DD')
  const upcomingDeliveriesFilted = upcomingDeliveries.filter(
    elem => elem.date === dateFormat
  )
  return upcomingDeliveriesFilted
}

export const getAddress = address => {
  if (address) {
    const { city, region, street } = address
    return `${street}, ${city}, ${region}`
  }
  return ''
}

export const trackEventModalsViewed = modals => {
  if (modals && modals.length) {
    modals.forEach(modal => {
      tracking.track('Overlay Viewed', {
        modal_id: modal.key,
        modal_name: modal.key
      })
    })
  }
}

export const trackEventExperimentsViewed = experiments => {
  if (experiments && experiments.length) {
    experiments.forEach(exp => {
      tracking.track('Experiment Viewed', {
        experimentId: exp.key,
        experimentName: exp.key,
        referrer: window.document.referrer
      })
    })
  }
}

export const trackEventSkipDay = (date, order, reason) => {
  const deliveryTime = order
    ? `${moment(order.time_start, 'h:mm A').format('h:mm A')} ${moment(
        order.time_end,
        'h:mm A'
      ).format('h:mm A')}`
    : '-'

  const numberOfMeals = getTotalMealsOrder(order)

  tracking.track('Order Skipped', {
    order_skip_date: new Date(),
    delivery_date: date,
    skip_reason: reason,
    delivery_time: deliveryTime,
    number_of_meals: numberOfMeals
  })
}

export const trackEventReschedule = (
  selectedDate,
  selectedTimeslot,
  newDate
) => {
  tracking.track('Reschedule', {
    order_id: selectedDate.order ? selectedDate.order.id : '-',
    old_delivery_date: selectedDate.date,
    old_delivery_time: `${moment(selectedTimeslot.start, 'h:mm A').format(
      'h:mm A'
    )} ${moment(selectedTimeslot.end, 'h:mm A').format('h:mm A')}`,
    new_delivery_date: newDate.date,
    new_delivery_time: `${moment(selectedTimeslot.start, 'h:mm A').format(
      'h:mm A'
    )} ${moment(selectedTimeslot.end, 'h:mm A').format('h:mm A')}`
  })
}

export const shouldGetMerchandiseSets = (
  datesToShowMerchandiseMeals,
  merchandiseMeals
) => {
  if (datesToShowMerchandiseMeals && !merchandiseMeals.length) {
    return true
  } else if (merchandiseMeals.length > 0) {
    const daysWithMerchandise = merchandiseMeals.map(elem => elem.deliveryDate)
    if (
      !datesToShowMerchandiseMeals.some(elem =>
        daysWithMerchandise.find(day => day === elem.date)
      )
    ) {
      return true
    }
  }
  return false
}

export const redirectToOnboardingRecommendations = history => {
  history.push('/onboarding/recommendations')
}

export const emptyDay = ({ order, recommendation, date }, carts) =>
  !recommendation && !order && !getCartByDate(carts, date)

export const getCartByDate = (carts, date) =>
  (carts && carts[date] && carts[date].items.length && carts[date].items) ||
  null

const isConfirmed = upcomingDay => !upcomingDay.canEdit

const isSkipped = upcomingDay => upcomingDay.skip

const isPaused = upcomingDay => upcomingDay.isPaused

const isPending = upcomingDay => upcomingDay.order?.id === '0'

const isRequested = upcomingDay => !!upcomingDay.order

const isUndecided = upcomingDay =>
  !upcomingDay.order ||
  !upcomingDay.order.items ||
  !upcomingDay.order.items.length
const isUnknown = () => true

const strategyMap = {
  confirmed: isConfirmed,
  skipped: isSkipped,
  paused: isPaused,
  pending: isPending,
  requested: isRequested,
  undecided: isUndecided,
  unknown: isUnknown
}

const strategyOrder = [
  'confirmed',
  'skipped',
  'paused',
  'pending',
  'requested',
  'undecided',
  'unknown'
]

const calculateStatus = upcomingDay => {
  for (const strategy of strategyOrder) {
    if (strategyMap[strategy](upcomingDay)) {
      return strategy
    }
  }
}

export const getDeliveryDates = upcomingDeliveries => {
  const deliveryDates = []

  for (const ud of upcomingDeliveries) {
    if (ud.menuAvailable && ud.canEdit) {
      deliveryDates.push({
        delivery_date: ud.displayDate,
        order_status: calculateStatus(ud)
      })
    }
  }

  return deliveryDates
}
