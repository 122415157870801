import { useUnleashContext } from '@unleash/proxy-client-react'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'
import { client } from '../..'
import { useSelector } from 'react-redux'
import {
  getStoreId,
  getUserEmail,
  selectCreatedDateUser
} from 'src/redux-api-bridge/selectors'

const UnleashProvider = ({ children }) => {
  const { user } = useAuth0()
  const userStoreId = useSelector(getStoreId)?.toString()
  const userCreatedDate = useSelector(selectCreatedDateUser)
  const userEmail = useSelector(getUserEmail)
  const updateContext = useUnleashContext()

  useEffect(() => {
    const asyncProcess = async () => {
      if (user && userEmail) {
        updateContext({
          userId: user && user?.subscription_id?.toString(),
          storeId: userStoreId,
          userCreatedDate: userCreatedDate && moment(userCreatedDate).unix(),
          userEmail
        })
        client.start()
      }
    }
    asyncProcess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userStoreId, userCreatedDate, userEmail])
  return children
}
export default UnleashProvider
