import '../App.scss'
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css'
import 'front-lib/dist/index.css'

import * as kustomer from '../utils/kustomer'

import React, { useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import MetaTags from '../components/MetaTags'
import Routes from './components/Routes'
import ScrollToTop from '../common/ScrollToTop'
import SmartAppBanner from '../components/SmartAppBanner'
import { Toaster } from '../components/Toaster/Toaster'
import UnleashProvider from '../components/UnleashProvider'
import { WithErrorBoundary } from 'src/hocs/withErrorBoundary'
import { cio_identify } from '../utils/customer'
import { getKustomerToken } from '../api'
import { getUserMenuByDate } from '../redux-api-bridge/menu/effects'
import { initApiStore } from '../redux-api-bridge'
import { isMobileDevice } from '../utils/utils'
import { limitNavegation } from '../routes/utils'
import { parse } from 'qs'
import routes from '../routes'
import { selectSubscriptionStatusEvent } from '../redux-api-bridge/selectors'
import tracking from '../shared/tracking'
import { useAuth0 } from '@auth0/auth0-react'

const loadLibraries = ({ email } = {}) => {
  kustomer.getTokenAndInit(getKustomerToken, { email })
}

const identifyUser = ({ magento_id, subscription_id, email } = {}) => {
  if (email && subscription_id && magento_id) {
    tracking.identify(magento_id, { email, subscription_id })
    tracking.track('Login', {
      email
    })
    cio_identify(email)
  }
}

const App = () => {
  const { isAuthenticated: authed, error, user } = useAuth0()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const eventSubscription = useSelector(selectSubscriptionStatusEvent)
  const { errorMessage } = parse(location.search, {
    ignoreQueryPrefix: true
  })
  const isMobile = isMobileDevice()

  useEffect(() => {
    const initApp = () => {
      loadLibraries(user)
      identifyUser(user)
      prefetchMenu(dispatch)
      dispatch(initApiStore())
    }

    if (authed) initApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed, user, dispatch])

  useEffect(() => {
    limitNavegation(history, eventSubscription, location, routes)
  }, [history, eventSubscription, location])

  useEffect(() => {
    if (isMobile) kustomer.hideChat()
  }, [isMobile])

  if (error && !errorMessage) {
    const returnedError =
      typeof error.message === 'string' && error.message.replace(/\s+/g, '_')
    return <Redirect to={`/logout?errorMessage=${returnedError}`} />
  }

  const prefetchMenu = dispatch => {
    const currentUrl = window.location.href
    const menuRegex = /\/meals\/?\?date=\d{4}-\d{2}-\d{2}/
    if (menuRegex.test(currentUrl)) {
      const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1])
      const dateParam = urlSearchParams.get('date')
      getUserMenuByDate(dateParam, true)(dispatch)
    }
  }

  return (
    <div className="app-routes-container">
      <MetaTags />
      <Toaster />
      <ScrollToTop>
        <UnleashProvider>
          <Routes />
        </UnleashProvider>
      </ScrollToTop>
      <SmartAppBanner />
    </div>
  )
}

export default WithErrorBoundary(App)
