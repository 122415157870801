import moment from 'moment'
import { getTotalMealsOrder } from './utils'
import {
  OrderItemToTrackType,
  OrderToTrackParamsType
} from '../types/tracking-types'

function orderItemsToTrackMapper(items: OrderItemToTrackType[]) {
  return items.map((item: OrderItemToTrackType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...nutritional_info } = item.nutritionalFacts
    return {
      id: item.entity_id,
      chef_name: item.chefName,
      chef_id: item.chef.id,
      category: item.category,
      image_url: item.imageUrl,
      name: item.title,
      nutritional_info,
      price: item.price,
      rating: item.stars,
      sku: item.sku,
      specification: JSON.stringify(
        item.specificationsDetails.map(s => s.label)
      ),
      product_premium: item.premium_special,
      premium: item.is_premium,
      quantity: item.qty
    }
  })
}

export function orderToTrackMapper(params: OrderToTrackParamsType) {
  const { pageName, currentOrder, currentDate, selectedTimeslot } = params

  return {
    page_name: pageName,
    order_id: currentOrder.id,
    delivery_date: currentDate.date,
    delivery_time: `${moment(selectedTimeslot.start, 'h:mm A').format(
      'h:mm A'
    )} ${moment(selectedTimeslot.end, 'h:mm A').format('h:mm A')}`,
    delivery_address: currentOrder.delivery_address,
    shipping_days: currentOrder.shipping_days,
    order_currency: currentOrder.currency,
    order_product_count: getTotalMealsOrder(currentOrder),
    order_total: currentOrder.total,
    order_subtotal: currentOrder.subtotal,
    promotion_coupon: currentOrder.coupon,
    order_discount: currentOrder.discount,
    order_addons_count: currentOrder.addons_count,
    products: orderItemsToTrackMapper(currentOrder.items || [])
  }
}
