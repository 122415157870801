import MenuByAll from '../../../../../assets/icons/menuby/menuby-all.svg'
import MenuAll from '../../../../../assets/icons/menuby/menu-all.svg'
import YouveTried from '../../../../../assets/icons/menuby/you_ve-tried.svg'
import Favorites from '../../../../../assets/icons/menuby/favorites.svg'
import Premium from '../../../../../assets/icons/menuby/menuby-premium.svg'
import Cuisine from '../../../../../assets/icons/menuby/menuby-cuisine.svg'
import CuisineMediterranean from '../../../../../assets/icons/menuby/mediterranean.png'
import CuisineAsian from '../../../../../assets/icons/menuby/asian.png'
import CuisineAmerican from '../../../../../assets/icons/menuby/american.png'
import CuisineItalian from '../../../../../assets/icons/menuby/italian.png'
import CuisineMexican from '../../../../../assets/icons/menuby/mexican.png'
import CuisineIndian from '../../../../../assets/icons/menuby/indian.png'
import CuisineMiddleEastern from '../../../../../assets/icons/menuby/middleEastern.png'
import CuisineAfrican from '../../../../../assets/icons/menuby/african.png'
import CuisineCaribbean from '../../../../../assets/icons/menuby/caribean.png'
import CuisineFrench from '../../../../../assets/icons/menuby/french.png'
import MenuByChef from '../../../../../assets/icons/menuby/menuby-chef.svg'
import MenuByDiets from '../../../../../assets/icons/menuby/menuby-diets.svg'
import Paleo from '../../../../../assets/icons/menuby/paleo.svg'
import Vegetarian from '../../../../../assets/icons/menuby/vegetarian-icon.svg'
import Keto from '../../../../../assets/icons/menuby/keto.svg'
import Vegan from '../../../../../assets/icons/menuby/vegan.svg'
import LowCarbs from '../../../../../assets/icons/menuby/lowCarbs.svg'
import LowCalories from '../../../../../assets/icons/menuby/lowCalories.svg'
import PescatarianDiet from '../../../../../assets/icons/menuby/pescatarianDiet.svg'
import Mediterranean from '../../../../../assets/icons/menuby/mediterraneanDiet.svg'
import MenuByProtein from '../../../../../assets/icons/menuby/menuby-protein.svg'
import MenuByProteinV2 from '../../../../../assets/icons/menuby/menuby-protein2.svg'
import Beef from '../../../../../assets/icons/menuby/protein-beef.svg'
import Lamb from '../../../../../assets/icons/menuby/protein-lamb.svg'
import Poultry from '../../../../../assets/icons/menuby/protein-poultry.svg'
import Pork from '../../../../../assets/icons/menuby/protein-pork.svg'
import Fish from '../../../../../assets/icons/menuby/protein-fish.svg'
import FishV2 from '../../../../../assets/icons/menuby/protein-fish3.svg'
import Shellfish from '../../../../../assets/icons/menuby/protein-shellfish.svg'
import VegetarianProtein from '../../../../../assets/icons/menuby/protein-vegetarian.svg'
import VeganProtein from '../../../../../assets/icons/menuby/protein-vegan.svg'

export const FILTER_IDS = {
  ALL: '0-all',
  YOUVE_TRIED: '1-youvetried',
  FAVORITES: '2-fav',
  PREMIUM: '3-premium',
  CUISINE: '4-cuisine',
  CHEF: '5-chef',
  DIET: '6-diet',
  PROTEIN: '7-protein',
  SIDES: '8-sides',
  BREAKFAST: '9-breakfast',
  MEAL_TYPE_PIZZA: '10-pizza',
  MEAL_TYPE_SALADS_AND_BOWLS: '11-salads-and-bowls',
  MEAL_TYPE_SANDWICH_AND_WRAPS: '12-sandwich-and-wraps',
  MEAL_TYPE_TACOS_AND_BURRITOS: '13-tacos-and-burritos',
  MEAL_TYPE_PASTA_AND_NOODLES: '14-pasta-and-noodles',
  MEAL_TYPE_PROTEIN_STARCH: '15-protein-starch',
  MEAL_TYPE_VEG_MEALS: '16-veg-meals',
  MEAL_TYPE_BALANCED_PLATE: '17-balanced-plate',
  MEAL_TYPE_BURGERS: '18-burgers',
  MEAL_TYPE_SOUPS_AND_STEWS: '19-soups-and-stews',
  MEAL_TYPE_BREAKFAST: '20-breakfast',
  SIDES_ALL: '21-sides-all',
  SIDES_VEGGIES_SALADS: '22-veggies-and-salads',
  SIDES_GRAINS_STARCH: '23-grains-and-starch',
  SIDES_PASTA_BREAD: '24-pasta-and-bread',
  SIDES_FINGER_FOOD: '25-finger-food',
  SIDES_SOUPS: '26-side-soups',
  SIDES_PROTEIN: '27-side-protein',
  BREAKFAST_ALL: '28-breakfast-all',
  BREAKFAST_EGGS: '29-eggs',
  BREAKFAST_PANCAKES_WAFFLES: '30-pancakes-waffles',
  BREAKFAST_YOGURT_GRANOLA: '31-yogurt-granola-oatmeal',
  BREAKFAST_BREAD_PASTRIES: '32-bread-pastries',
  BREAKFAST_SMOOTHIES_JUICES: '33-smoothies-juices',
  BREAKFAST_BARS: '34-bars',
  FOR_YOU: '35-for-you'
}

export const MEAL_TYPES_FILTERS = [
  FILTER_IDS.MEAL_TYPE_PIZZA,
  FILTER_IDS.MEAL_TYPE_SALADS_AND_BOWLS,
  FILTER_IDS.MEAL_TYPE_SANDWICH_AND_WRAPS,
  FILTER_IDS.MEAL_TYPE_TACOS_AND_BURRITOS,
  FILTER_IDS.MEAL_TYPE_PASTA_AND_NOODLES,
  FILTER_IDS.MEAL_TYPE_PROTEIN_STARCH,
  FILTER_IDS.MEAL_TYPE_VEG_MEALS,
  FILTER_IDS.MEAL_TYPE_BALANCED_PLATE,
  FILTER_IDS.MEAL_TYPE_BURGERS,
  FILTER_IDS.MEAL_TYPE_SOUPS_AND_STEWS,
  FILTER_IDS.MEAL_TYPE_BREAKFAST
]

export const CATEGORIES_FILTERS = [FILTER_IDS.SIDES, FILTER_IDS.BREAKFAST]

export const SUBCATEGORIES_FILTERS = [
  FILTER_IDS.SIDES_VEGGIES_SALADS,
  FILTER_IDS.SIDES_GRAINS_STARCH,
  FILTER_IDS.SIDES_PASTA_BREAD,
  FILTER_IDS.SIDES_FINGER_FOOD,
  FILTER_IDS.SIDES_SOUPS,
  FILTER_IDS.SIDES_PROTEIN,
  FILTER_IDS.BREAKFAST_EGGS,
  FILTER_IDS.BREAKFAST_PANCAKES_WAFFLES,
  FILTER_IDS.BREAKFAST_YOGURT_GRANOLA,
  FILTER_IDS.BREAKFAST_BREAD_PASTRIES,
  FILTER_IDS.BREAKFAST_SMOOTHIES_JUICES,
  FILTER_IDS.BREAKFAST_BARS
]

export const FILTER_INDEX = {
  ALL: 0,
  CUISINE: 1,
  CHEF: 2,
  DIET: 3,
  PROTEIN: 4
}

export const FILTER_INDEX_EXPERIMENT = {
  ALL: 0,
  YOUVE_TRIED: 1,
  FAVORITES: 2,
  PREMIUM: 3,
  DIET: 4,
  PROTEIN: 5,
  CUISINE: 6,
  CHEF: 7
}

export const TAB_ALL_IDS = {
  ALL: FILTER_IDS.ALL,
  YOU_HAVE_TRIED: FILTER_IDS.YOUVE_TRIED,
  FAVORITES: FILTER_IDS.FAVORITES,
  PREMIUM: FILTER_IDS.PREMIUM
}

export const MENU_ITEMS = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    image: MenuByAll,
    tabs: [
      {
        id: FILTER_IDS.ALL,
        name: 'All',
        label: 'All',
        image: MenuAll
      },
      {
        id: FILTER_IDS.YOUVE_TRIED,
        name: 'You’ve Tried',
        pattern: 'alreadyRequested',
        label: "You've Tried",
        image: YouveTried
      },
      {
        id: FILTER_IDS.FAVORITES,
        name: 'Favorites',
        pattern: 'favorite',
        label: 'Favorites',
        image: Favorites,
        isFavorite: true
      },
      {
        id: FILTER_IDS.PREMIUM,
        name: 'Premium',
        pattern: 'premium',
        label: 'Premium',
        image: Premium,
        isPremium: true
      }
    ]
  },
  {
    id: FILTER_IDS.CUISINE,
    name: 'Cuisine',
    image: Cuisine,
    tabs: [
      {
        id: 1,
        name: 'Mediterranean',
        pattern: 'mediterranean',
        image: CuisineMediterranean
      },
      {
        id: 2,
        name: 'Asian',
        pattern: 'asian',
        image: CuisineAsian
      },
      {
        id: 3,
        name: 'American',
        pattern: 'american',
        image: CuisineAmerican
      },
      {
        id: 4,
        name: 'Italian',
        pattern: 'italian',
        image: CuisineItalian
      },
      {
        id: 5,
        name: 'Mexican',
        pattern: 'mexican',
        image: CuisineMexican
      },
      {
        id: 6,
        name: 'Indian',
        pattern: 'indian',
        image: CuisineIndian
      },
      {
        id: 7,
        name: 'Middle Eastern',
        pattern: 'middle eastern',
        image: CuisineMiddleEastern
      },
      {
        id: 8,
        name: 'Latin American',
        pattern: 'latin-american',
        image: CuisineMiddleEastern
      },
      {
        id: 9,
        name: 'African',
        pattern: 'african',
        image: CuisineAfrican
      },
      {
        id: 10,
        name: 'Caribbean',
        pattern: 'caribbean',
        image: CuisineCaribbean
      },
      {
        id: 11,
        name: 'French',
        pattern: 'french',
        image: CuisineFrench
      },
      {
        id: 12,
        name: 'European',
        pattern: 'european',
        image: CuisineFrench
      }
    ]
  },
  {
    id: FILTER_IDS.CHEF,
    name: 'Chef',
    image: MenuByChef,
    tabs: []
  },
  {
    id: FILTER_IDS.DIET,
    name: 'Diets',
    image: MenuByDiets,
    tabs: [
      {
        id: 2,
        name: 'Paleo',
        label: 'paleo',
        pattern: 'paleo',
        image: Paleo
      },
      {
        id: 3,
        name: 'Vegetarian',
        label: 'vegetarian',
        pattern: 'vegetarian',
        image: Vegetarian
      },
      {
        id: 4,
        name: 'Keto',
        label: 'keto diet',
        pattern: 'keto diet',
        image: Keto
      },
      {
        id: 8,
        name: 'Vegan',
        label: 'vegan',
        pattern: 'vegan',
        image: Vegan
      },
      {
        id: 14,
        name: 'Low Carbs',
        label: 'low carbs',
        pattern: 'low carbs',
        image: LowCarbs
      },
      {
        id: 15,
        name: 'Low Calories',
        label: 'less than 600 calories',
        pattern: 'less than 600 calories',
        image: LowCalories
      },
      {
        id: 17,
        name: 'Pescatarian',
        label: 'pescatarian diet',
        pattern: 'pescatarian diet',
        image: PescatarianDiet
      },
      {
        id: 16,
        name: 'Mediterranean',
        label: 'mediterranean diet',
        pattern: 'mediterranean diet',
        image: Mediterranean
      }
    ]
  },
  {
    id: FILTER_IDS.PROTEIN,
    name: 'Protein',
    image: MenuByProtein,
    tabs: [
      {
        id: 1,
        name: 'Beef',
        image: Beef,
        types: ['Beef', 'Meat', 'Other Meat']
      },
      {
        id: 2,
        name: 'Lamb',
        image: Lamb,
        types: ['Lamb']
      },
      {
        id: 3,
        name: 'Poultry',
        image: Poultry,
        types: ['Poultry', 'Chicken', 'Duck', 'Turkey']
      },
      {
        id: 4,
        name: 'Pork',
        image: Pork,
        types: ['Pork']
      },
      {
        id: 5,
        name: 'Fish',
        image: Fish,
        types: [
          'Fish',
          'Other Fish',
          'Salmon',
          'Snapper',
          'Sole',
          'Tilapia',
          'Trout',
          'Tuna',
          'Cod',
          'Catfish'
        ]
      },
      {
        id: 6,
        name: 'Shellfish',
        image: Shellfish,
        types: ['Crab', 'Scallop', 'Shrimp', 'Shellfish']
      },
      {
        id: 7,
        name: 'Vegetarian',
        image: VegetarianProtein,
        types: ['Vegetarian']
      },
      {
        id: 8,
        name: 'Vegan',
        image: VeganProtein,
        types: ['Vegan']
      }
    ]
  }
]

const GENERIC_MENU_ITEMS_TABS = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    hasSub: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.YOUVE_TRIED,
    name: 'You’ve Tried',
    pattern: 'alreadyRequested',
    label: "You've Tried",
    image: YouveTried,
    withTabs: false
  },
  {
    id: FILTER_IDS.FAVORITES,
    name: 'Favorites',
    pattern: 'favorite',
    label: 'Favorites',
    image: Favorites,
    isFavorite: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.PREMIUM,
    name: 'Premium',
    pattern: 'premium',
    label: 'Premium',
    image: Premium,
    isPremium: true,
    withTabs: false
  },
  {
    id: FILTER_IDS.DIET,
    name: 'Diets',
    image: MenuByDiets,
    withTabs: true
  },
  {
    id: FILTER_IDS.PROTEIN,
    name: 'Protein',
    image: MenuByProteinV2,
    withTabs: true
  },
  {
    id: FILTER_IDS.CUISINE,
    name: 'Cuisine',
    image: Cuisine,
    withTabs: true
  },
  {
    id: FILTER_IDS.CHEF,
    name: 'Chef',
    image: MenuByChef,
    withTabs: true
  }
]

export const MENU_ITEMS_EXPERIMENT = [
  {
    id: FILTER_IDS.ALL,
    name: 'All',
    label: 'All',
    image: MenuAll,
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.YOUVE_TRIED,
    name: 'You’ve Tried',
    pattern: 'alreadyRequested',
    label: "You've Tried",
    image: YouveTried,
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.FAVORITES,
    name: 'Favorites',
    pattern: 'favorite',
    label: 'Favorites',
    image: Favorites,
    isFavorite: true,
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.PREMIUM,
    name: 'Premium',
    pattern: 'premium',
    label: 'Premium',
    image: Premium,
    isPremium: true,
    tabs: GENERIC_MENU_ITEMS_TABS,
    hasGenericTabs: true,
    isSubMenuItem: false
  },
  {
    id: FILTER_IDS.DIET,
    name: 'Diets',
    withTabs: true,
    image: MenuByDiets,
    hasGenericTabs: false,
    isSubMenuItem: false,
    tabs: [
      {
        id: 2,
        name: 'Paleo',
        label: 'paleo',
        pattern: 'paleo',
        image: Paleo
      },
      {
        id: 3,
        name: 'Vegetarian',
        label: 'vegetarian',
        pattern: 'vegetarian',
        image: Vegetarian
      },
      {
        id: 4,
        name: 'Keto',
        label: 'keto diet',
        pattern: 'keto diet',
        image: Keto
      },
      {
        id: 8,
        name: 'Vegan',
        label: 'vegan',
        pattern: 'vegan',
        image: Vegan
      },
      {
        id: 14,
        name: 'Low Carbs',
        label: 'low carbs',
        pattern: 'low carbs',
        image: LowCarbs
      },
      {
        id: 15,
        name: 'Low Calories',
        label: 'less than 600 calories',
        pattern: 'less than 600 calories',
        image: LowCalories
      },
      {
        id: 17,
        name: 'Pescatarian',
        label: 'pescatarian diet',
        pattern: 'pescatarian diet',
        image: PescatarianDiet
      },
      {
        id: 16,
        name: 'Mediterranean',
        label: 'mediterranean diet',
        pattern: 'mediterranean diet',
        image: Mediterranean
      }
    ]
  },
  {
    id: FILTER_IDS.PROTEIN,
    name: 'Protein',
    withTabs: true,
    image: MenuByProteinV2,
    hasGenericTabs: false,
    isSubMenuItem: false,
    tabs: [
      {
        id: 1,
        name: 'Beef',
        image: Beef,
        types: ['Beef', 'Meat', 'Other Meat']
      },
      {
        id: 2,
        name: 'Lamb',
        image: Lamb,
        types: ['Lamb']
      },
      {
        id: 3,
        name: 'Poultry',
        image: Poultry,
        types: ['Poultry', 'Chicken', 'Duck', 'Turkey']
      },
      {
        id: 4,
        name: 'Pork',
        image: Pork,
        types: ['Pork']
      },
      {
        id: 5,
        name: 'Fish',
        image: FishV2,
        types: [
          'Fish',
          'Other Fish',
          'Salmon',
          'Snapper',
          'Sole',
          'Tilapia',
          'Trout',
          'Tuna',
          'Cod',
          'Catfish'
        ]
      },
      {
        id: 6,
        name: 'Shellfish',
        image: Shellfish,
        types: ['Crab', 'Scallop', 'Shrimp', 'Shellfish']
      },
      {
        id: 7,
        name: 'Vegetarian',
        image: VegetarianProtein,
        types: ['Vegetarian']
      },
      {
        id: 8,
        name: 'Vegan',
        image: VeganProtein,
        types: ['Vegan']
      }
    ]
  },
  {
    id: FILTER_IDS.CUISINE,
    name: 'Cuisine',
    image: Cuisine,
    withTabs: true,
    hasGenericTabs: false,
    isSubMenuItem: false,
    tabs: [
      {
        id: 1,
        name: 'Mediterranean',
        pattern: 'mediterranean',
        image: CuisineMediterranean
      },
      {
        id: 2,
        name: 'Asian',
        pattern: 'asian',
        image: CuisineAsian
      },
      {
        id: 3,
        name: 'American',
        pattern: 'american',
        image: CuisineAmerican
      },
      {
        id: 4,
        name: 'Italian',
        pattern: 'italian',
        image: CuisineItalian
      },
      {
        id: 5,
        name: 'Mexican',
        pattern: 'mexican',
        image: CuisineMexican
      },
      {
        id: 6,
        name: 'Indian',
        pattern: 'indian',
        image: CuisineIndian
      },
      {
        id: 7,
        name: 'Middle Eastern',
        pattern: 'middle eastern',
        image: CuisineMiddleEastern
      },
      {
        id: 8,
        name: 'Latin American',
        pattern: 'latin-american',
        image: CuisineMiddleEastern
      },
      {
        id: 9,
        name: 'African',
        pattern: 'african',
        image: CuisineAfrican
      },
      {
        id: 10,
        name: 'Caribbean',
        pattern: 'caribbean',
        image: CuisineCaribbean
      },
      {
        id: 11,
        name: 'French',
        pattern: 'french',
        image: CuisineFrench
      },
      {
        id: 12,
        name: 'European',
        pattern: 'european',
        image: CuisineFrench
      }
    ]
  },
  {
    id: FILTER_IDS.CHEF,
    name: 'Chef',
    image: MenuByChef,
    withTabs: true,
    tabs: [],
    hasGenericTabs: false,
    isSubMenuItem: false
  }
]

export const MENU_EMPTY_STATES = {
  FAVORITES: {
    title: 'Nothing here yet...',
    subtitle:
      "Use the heart icon to save your favorite meals. Once you do, you'll find them here.",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: true,
    isTopRated: false
  },
  YOU_HAVE_TRIED: {
    title: 'Nothing here yet...',
    subtitle:
      "It's a great opportunity to explore and try new things from our menu!",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: false
  },
  YOU_HAVE_TRIED_NO_ORDER: {
    title: 'Nothing here yet...',
    subtitle:
      "After you receive your first order, we will show you here the meals you've tried.",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: false
  },
  TOP_RATED: {
    title: 'Nothing here yet...',
    subtitle:
      'We will show your top rated meals here. You can start rating them now.',
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'Rate meals',
    isFavorite: false,
    isTopRated: true
  },
  TOP_RATED_NO_ORDER: {
    title: 'Nothing here yet...',
    subtitle:
      "We will show your top rated meals here. You don't have anything to rate yet. ",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: true
  }
}

export const SORTING_NAMES = {
  MY_PREFERENCES: 'My preferences'
}
