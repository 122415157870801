import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { parse, stringify } from 'qs'

import Loader from '../common/Loader'

const withAuthenticationRequired = Component => props => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
    isImpersonating
  } = useAuth0()
  const location = useLocation()
  const { pathname } = location
  const { errorMessage, cuToken, ...search } = parse(location.search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    if (
      isLoading ||
      isAuthenticated ||
      error ||
      pathname === '/impersonate' ||
      isImpersonating ||
      cuToken
    ) {
      return
    }

    const opts = {
      appState: {
        returnTo: `${window.location.pathname}?${stringify(search)}`
      },
      errorMessage
    }
    // This fix is related with https://github.com/auth0/auth0-react/issues/343
    // with this timeout we give to redux an extra time to check the isAuthenticated value and avoid redirect again to the login page
    setTimeout(() => {
      loginWithRedirect(opts)
    }, 3000)
  }, [isLoading, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  if (errorMessage || pathname === '/impersonate') {
    return <Loader />
  }

  return isAuthenticated ? <Component {...props} /> : <Loader />
}

export default withAuthenticationRequired
