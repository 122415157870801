import createReducer from '../../utils/createReducer'

import * as actions from './actions'

export type FastOrderType = 'chooseForMe' | 'lastOrder' | null
export type toastActionType = 'PREFERENCES' | 'OPEN_MODAL' | null

export interface FastOrderingState {
  inFlight: boolean
  isError: boolean
  isSuccess: boolean
  fastOrderType: FastOrderType
  tooltipClosed: boolean
  toast: {
    isOpen: boolean
    message: string | null
    linkText: string | null
    actionType: toastActionType | null
  }
  modal: {
    isOpen: boolean
    title: string
    description: string
    buttons: {
      editPreferences: boolean
      keepBrowsing: boolean
      repeatLastOrder: boolean
    }
  }
}

const initialState: FastOrderingState = {
  inFlight: false,
  isError: false,
  isSuccess: false,
  fastOrderType: null,
  tooltipClosed: false,
  toast: {
    isOpen: false,
    message: '',
    linkText: '',
    actionType: null
  },
  modal: {
    isOpen: false,
    title: '',
    description: '',
    buttons: {
      editPreferences: false,
      keepBrowsing: false,
      repeatLastOrder: false
    }
  }
}

export default createReducer(initialState, {
  [actions.fastOrderStart]: (state: FastOrderingState, action) => ({
    ...state,
    inFlight: true,
    isError: false,
    isSuccess: false,
    tooltipClosed: true,
    fastOrderType: action.payload.fastOrderType,
    modal: {
      ...state.modal,
      isOpen: false
    }
  }),
  [actions.fastOrderSuccess]: (state: FastOrderingState, action) => ({
    ...state,
    inFlight: false,
    isError: false,
    isSuccess: true,
    toast: {
      isOpen: true,
      message: action.payload.toast.fastOrderToastMessage,
      linkText: action.payload.toast.fastOrderToastLinkText,
      actionType: action.payload.toast.actionType
    },
    modal: {
      ...state.modal,
      isOpen: action.payload.modal.isOpen,
      title: action.payload.modal.title,
      description: action.payload.modal.description,
      buttons: {
        editPreferences: action.payload.modal.buttons.editPreferences,
        keepBrowsing: action.payload.modal.buttons.keepBrowsing,
        repeatLastOrder: action.payload.modal.buttons.repeatLastOrder
      }
    }
  }),
  [actions.fastOrderError]: (state: FastOrderingState) => ({
    ...state,
    inFlight: false,
    isError: true,
    isSuccess: false,
    modal: {
      ...state.modal,
      isOpen: true,
      title: `Something went wrong`,
      description: `Please, try again`,
      buttons: {
        editPreferences: false,
        keepBrowsing: true,
        repeatLastOrder: state.fastOrderType === 'chooseForMe'
      }
    }
  }),
  [actions.fastOrderModalOpen]: (state: FastOrderingState, action) => ({
    ...state,
    modal: {
      ...state.modal,
      isOpen: true
    }
  }),
  [actions.fastOrderModalClose]: (state: FastOrderingState) => ({
    ...state,
    modal: {
      ...state.modal,
      isOpen: false
    }
  }),
  [actions.fastOrderTooltipClose]: (state: FastOrderingState) => ({
    ...state,
    tooltipClosed: true
  }),
  [actions.fastOrderToastClose]: (state: FastOrderingState) => ({
    ...state,
    toast: {
      ...state.toast,
      isOpen: false
    }
  }),
  [actions.fastOrderClearState]: (state: FastOrderingState) => ({
    ...initialState,
    tooltipClosed: true
  })
})
