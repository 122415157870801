import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'

import ViewModalLogin from '../../common/ViewModalLogin'
import { RoutePaths } from '../../routes/routePaths'

import { View } from './components'
import Login from './login'

export const LoginPage = () => {
  const { isAuthenticated } = useAuth0()

  if (process.env.NODE_ENV === 'production') {
    window.location.href = process.env.REACT_APP_SITE_BASE_URL
    return null
  }

  if (isAuthenticated) {
    return <Redirect to={RoutePaths.ORDERS} />
  }

  return (
    <View>
      <Login />
    </View>
  )
}

export const LoginModal = ({ isOpen, onClose, onLogin }) => (
  <ViewModalLogin isOpen={isOpen} onClose={onClose}>
    <Login onLogin={onLogin} />
  </ViewModalLogin>
)
