import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import moment from 'moment'

import { formatTime } from '../utils/utils'
import { getSubscriptionStatusEvent } from '../modules/OnHold/selectors'

export const getSubscriberId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'id'], state)

export const getUserPhone = state =>
  pathOr(null, ['api', 'user', 'info', 'phone'], state)

const getAddresses = state =>
  pathOr(null, ['api', 'subscription', 'info', 'addresses'], state)

export const getDeliveryDays = state =>
  pathOr([], ['api', 'subscription', 'info', 'deliveryDays'], state)

export const getPlanId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'plan_id'], state)

export const getRingId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'ring_id'], state)

export const getStoreId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'store_id'], state)

export const getUserStatus = state =>
  pathOr(null, ['api', 'subscription', 'info', 'status'], state)

export const getTimeslots = state =>
  pathOr([], ['api', 'subscription', 'timeslots'], state)

export const getUserDeliveryDays = state =>
  pathOr(null, ['api', 'subscription', 'info', 'deliveryDays'], state)
const getSchedulingStatus = state => state.api.schedulingStatus

export const getUpcomingDays = state =>
  pathOr(null, ['api', 'subscription', 'upcomingDays'], state)

export const getDefaultAddressZipcode = createSelector(
  [getAddresses],
  addresses => {
    if (addresses && addresses.length) {
      return addresses[0].postcode
    }
    return ''
  }
)

export const getUserInfo = state => state.api.user.info

export const getDefaultAddress = createSelector([getAddresses], addresses => {
  if (addresses && addresses.length) {
    return addresses[0]
  }
  return {}
})

export const getUserEmail = state =>
  pathOr(null, ['api', 'subscription', 'info', 'email'], state)

const getCreatedDateUser = state =>
  pathOr(null, ['api', 'subscription', 'info', 'createdAt'], state)

export const getSettings = state =>
  pathOr([], ['api', 'subscription', 'settings'], state)

export const getSetting = key =>
  createSelector([getSettings], settings =>
    settings.reduce((val, setting) => {
      if (setting.key === key) val = setting.value
      return val
    }, null)
  )

export const selectCreatedDateUser = createSelector(
  state => getCreatedDateUser(state),
  date => date
)

export const isSettingPresent = key =>
  createSelector(
    [getSettings],
    settings =>
      settings && settings.findIndex(setting => setting.key === key) > -1
  )

export const isSettingEnabled = key =>
  createSelector([getSettings], settings => {
    const setting = settings.find(setting => setting.key === key)
    if (!setting || !setting.value) return false
    if (setting.value === 'true') {
      return true
    }
    return !!+setting.value
  })

export const getDefaultTimeslot = createSelector(
  [getUserDeliveryDays],
  deliveryDays => {
    if (deliveryDays) {
      const day = deliveryDays.length > 0 ? deliveryDays[0] : null
      const start = day && moment(day.time_start, 'HH:mm').format('h:mm A')
      const end = day && moment(day.time_end, 'HH:mm').format('h:mm A')
      return {
        label: day ? `${start} - ${end}` : '',
        start: day ? day.time_start : '',
        end: day ? day.time_end : ''
      }
    }
    return null
  }
)

export const getAvailableTimeslots = createSelector(
  [getDefaultTimeslot, getTimeslots],
  (defaultTimeslot, timeslots) => {
    if (defaultTimeslot && timeslots) {
      const selectedTimeslot = timeslots.find(
        t => t.start === defaultTimeslot.start && t.end === defaultTimeslot.end
      )

      return selectedTimeslot
        ? timeslots
        : [...timeslots, { ...defaultTimeslot }].sort((a, b) => {
            const aStart = moment(a.start, 'HH:mm')
            const bStart = moment(b.start, 'HH:mm')
            return aStart.isBefore(bStart) ? -1 : 1
          })
    }
    return []
  }
)

export const selectSubscriptionStatusEvent = createSelector(
  state => getSubscriptionStatusEvent(state),
  event => event
)

export const selectUserFFContext = createSelector(
  getSubscriberId,
  getUserEmail,
  getDeliveryDays,
  getPlanId,
  getStoreId,
  getRingId,
  getUserStatus,
  (
    subscriptionId,
    email,
    deliveryDays,
    planId,
    storeId,
    ringId,
    userStatus
  ) => {
    if (subscriptionId) {
      return {
        subscriptionId,
        email,
        userEmail: email,
        deliveryDays,
        planId,
        storeId,
        ringId,
        userStatus
      }
    }
  }
)

export const getCurrentTimeslot = createSelector(
  [getDeliveryDays],
  deliveryDays => {
    if (deliveryDays.length > 0) {
      const { time_start: start, time_end: end } = deliveryDays[0]
      const label = `${formatTime(start)} - ${formatTime(end)}`
      return {
        start,
        end,
        label
      }
    }
    return {}
  }
)

export const activeDeliveryDate = createSelector(
  getSchedulingStatus,
  schedulingStatus => schedulingStatus.newDate
)

export const getUserPromotions = createSelector(
  state => state.api,
  api => api.userPromotions?.data
)

export const isEconomicPlanSelector = state => {
  const planType = state?.api?.subscription?.userPlan?.type
  if (!planType) {
    return false
  }

  return Boolean(planType === 'ECONOMIC')
}

export const isFlexPlanSelector = state => {
  const planType = state?.api?.subscription?.userPlan?.type
  if (!planType) {
    return false
  }

  return Boolean(planType === 'FLEX')
}

export const getRestrictions = state =>
  pathOr(null, ['api', 'preferences', 'restrictions'], state)
