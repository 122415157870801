import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getCurrentCredit = state =>
  pathOr(null, ['api', 'subscription', 'info', 'currentCredit'], state)

export const getPromotions = state =>
  pathOr(null, ['api', 'subscription', 'info', 'promotionActivations'], state)

export const creditsAndPromosIsLoading = createSelector(
  [getCurrentCredit, getPromotions],
  (credit, promotions) => typeof credit !== 'number' || !promotions
)

export const getUserName = state =>
  pathOr(null, ['api', 'subscription', 'info', 'name'], state)

export const getUserFirstName = createSelector([getUserName], userName => {
  if (userName) {
    return userName.split(' ')[0].trim()
  }
  return ''
})
