import { FEATURED_CATEGORY, IMG_URL_SOURCE, MEALS_CATEGORY } from './constants'
import { compose, sortBy, uniqBy } from 'ramda'

import { getCategory } from './selectors'
import { buildImgUrl } from 'src/utils/utils'

export const mapMeals = (meal, cat) => {
  let imageUrl = (meal.image || '').replace('no_selection', '')
  if (imageUrl)
    imageUrl = buildImgUrl(IMG_URL_SOURCE, meal.image_path, imageUrl)
  return {
    ...meal,
    category: getCategory(meal),
    imageUrl,
    sidedish: meal.sidedish && meal.sidedish.id,
    magento_id: +meal.entity_id,
    menu_category_id: cat.id,
    menu_category_label: cat.label,
    reservedStock: meal.qty || 0,
    tags:
      meal.specifications_detail &&
      meal.specifications_detail.filter(tag => tag && tag.label.trim()),
    qty: 1,
    id: meal.id || meal.entity_id,
    isNewMeal: meal.isNewMeal || meal.new_meal
  }
}

export const mapMenuMeals = meal => ({
  ...meal,
  id: meal.id,
  entity_id: meal.id,
  magento_id: meal.id,
  batch_id: meal.batchId,
  short_description: meal.shortDescription,
  calories: +meal.nutritionalFacts.calories,
  image_path: meal.imagePath,
  is_premium: !!meal.premiumFee,
  is_back_in_the_menu: !!meal.isBackInTheMenu,
  premium_fee: meal.premiumFee,
  premium_special: meal.premiumSpecial,
  category_id: meal.categoryId,
  chef_id: meal.chef && meal.chef.id,
  chef_firstname: meal.chef && meal.chef.firstName,
  chef_lastname: meal.chef && meal.chef.lastName,
  is_celebrity_chef: meal.chef && meal.chef.isCelebrityChef,
  warnings: {
    message: meal.warnings && meal.warnings.message,
    restrictions_applied: meal.warnings && meal.warnings.restrictionsApplied,
    diets_not_matching: meal.warnings && meal.warnings.dietsNotMatching,
    allergens_not_matching: meal.warnings && meal.warnings.allergensNotMatching
  },
  nutritional_facts: meal.nutritionalFacts && {
    calories: meal.nutritionalFacts.calories
  },
  logopic: meal.chef && meal.chef.logoPic,
  bannerpic: meal.chef && meal.chef.bannerPic,
  inStock: meal.stock > 0,
  meat_type: meal.meatType,
  user_rating: meal.userRating,
  sidedish: meal.sideDish && {
    id: meal.sideDish.id
  },
  quantity: meal.qty || 0,
  imageUrl: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_meal_image: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_chef_image: `${IMG_URL_SOURCE}${meal.chef.bannerPic}`,
  protein_type: meal.meatType,
  secondary_image: (meal.media && meal.media.secondaryImage) || null,
  specifications_detail: meal.specificationsDetails
    ? meal.specificationsDetails.map(specification => ({
        ...specification
      }))
    : [],
  relatedMeal: meal.relatedMeal
    ? {
        ...meal.relatedMeal,
        entity_id: meal.relatedMeal.id,
        batch_id: meal.relatedMeal.batchId,
        magento_id: meal.relatedMeal.id,
        is_related_meal: true,
        image_path: meal.relatedMeal.imagePath,
        category_id: meal.relatedMeal.categoryId
      }
    : null,
  all_tags: meal.tags
})

export const menuCollectionsMapper = collections => {
  return {
    merchandiseSets: collections.map(collection => {
      let col = {
        ...collection,
        heroPhoto:
          collection.heroPhoto &&
          `${IMG_URL_SOURCE}/media/merchandiseSets/${collection.heroPhoto}`,
        coverPhoto: `${IMG_URL_SOURCE}/media/merchandiseSets/${collection.coverPhoto}`,
        items: collection.meals?.map(meal => mapMenuMeals(meal)) || []
      }
      delete col.meals
      return col
    })
  }
}

export const getCategoryMeals = (
  confirmedOrder,
  currentMenu,
  selectedCategoryId
) => {
  const isEditingOrder = confirmedOrder && confirmedOrder.length

  const selectedCategory =
    currentMenu.find(category => category.id === selectedCategoryId) || {}
  if (selectedCategory && selectedCategory.meals) {
    return isEditingOrder
      ? compose(
          sortBy(
            meal => !confirmedOrder.find(m => m.magento_id === meal.magento_id)
          ),
          uniqBy(meal => meal.magento_id)
        )([...selectedCategory.meals])
      : selectedCategory.meals
  }
  return []
}

export const getAddonsAndFees = (addons, meals) => {
  const premiumFeesTotal = meals.reduce(
    (accumulator, meal) => accumulator + meal.qty * (meal.premium_fee || 0),
    0
  )
  const addonsExtraTotal = addons.reduce(
    (accumulator, addon) =>
      addon.qty && addon.price
        ? accumulator + addon.qty * (addon.price + (addon.premium_fee || 0))
        : accumulator,
    0
  )
  return premiumFeesTotal + addonsExtraTotal
}

export const getItemsPrice = (items, pricePerMeal) => {
  const totalPrice = items.reduce((total, currentItem) => {
    if (isAddOn(currentItem)) {
      const addOnPrice = (+currentItem.price || 0) * currentItem.qty
      return total + addOnPrice
    }

    const itemPrice =
      (+pricePerMeal + +currentItem.premiumFee || 0) * currentItem.qty
    return total + itemPrice
  }, 0)

  return totalPrice || 0
}

export const countItems = items =>
  items.reduce(
    (total, item) => (item.quantity ? total + item.quantity : total),
    0
  )

export const calculateTotalFromSubtotal = ({
  subtotal,
  totalTaxes,
  totalDiscount,
  totalDeliveryFee,
  isFlexPlan
}) => {
  if (isFlexPlan) {
    const totalOrderFlex = (
      +subtotal +
      +totalDeliveryFee +
      +totalTaxes -
      +totalDiscount
    ).toFixed(2)

    return totalOrderFlex
  }

  return subtotal
}

export const calculateTotal = ({
  meals,
  addons,
  totalTaxes = 0,
  totalDiscount = 0,
  totalDeliveryFee = 0,
  plan,
  isFlexPlan
}) => {
  if (isFlexPlan) {
    const pricePerMeal = plan.price / plan.mealsPerDelivery
    const totalMealsFlexible = getItemsPrice(meals, pricePerMeal)
    const totalAddonsFlexible = getItemsPrice(addons, pricePerMeal)

    const totalOrderFlexible = (
      +totalMealsFlexible +
      +totalAddonsFlexible +
      +totalDeliveryFee +
      +totalTaxes -
      +totalDiscount
    ).toFixed(2)

    return totalOrderFlexible
  }

  const totalAddonsAndFees = getAddonsAndFees(addons, meals)
  return totalAddonsAndFees
}

export const getFlexSubtotal = (addons, meals) => {
  const premium_fees = meals.reduce(
    (sub, meal) => sub + meal.qty * (meal.price + (meal.premium_fee || 0)),
    0
  )
  const addons_sub = addons.reduce(
    (sub, addon) =>
      addon.qty && addon.price
        ? sub + addon.qty * (addon.price + (addon.premium_fee || 0))
        : sub,
    0
  )
  return premium_fees + addons_sub
}

export const formatProduct = products => {
  return products.map(elem => {
    return {
      id: elem.entity_id,
      sku: elem.sku,
      category: elem.category,
      name: elem.title,
      chef: elem.chefName,
      price: elem.price,
      url: `/products/${elem.entity_id}`,
      imageUrl: elem.imageUrl
    }
  })
}

export const isFeaturedCategory = category => category === FEATURED_CATEGORY
export const isMealsCategory = category => category === MEALS_CATEGORY

export const createMerchandiseItem = (merchandiseSets, mealsFormated) =>
  merchandiseSets &&
  merchandiseSets.reduce(
    (accum, merchandise) => [
      ...accum,
      {
        ...merchandise,
        items: merchandise.items
          .map(elem =>
            mealsFormated.find(meal => meal.entity_id === elem.entity_id)
          )
          // send meals out of stock to the end of the list
          .sort((meal1, meal2) => {
            if (meal1.stock <= 0) return 1
            if (meal2.stock <= 0) return -1
            return 0
          })
      }
    ],
    []
  )

export const ADDONS_CATEGORIES = [6, 11, 13]

export const isAddOn = meal =>
  ADDONS_CATEGORIES.includes(meal.category_id) ||
  ADDONS_CATEGORIES.includes(meal.categoryId) ||
  (meal.category &&
    meal.category.id &&
    ADDONS_CATEGORIES.includes(meal.category.id))

// Main product => included in plan
export const isMainProduct = meal => !isAddOn(meal)
