import React, { useState } from 'react'
import { getUserLifespan, getUserOrdersCompleted } from '../../../../api/index'

import Analytics from '../../../../utils/analytics'
import { getUserDeliveryInfoSelector } from '../../selectors'
import { pathOr } from 'ramda'
import { usePromotions } from '../../../../hooks/usePromotions'
import { useSelector } from 'react-redux'
import { CuiLink } from 'front-lib'
import { useTranslation } from 'react-i18next'
import Loader from 'src/common/Loader'
import { toaster } from 'src/utils/toaster'

const ID_PROMOTION_CANCELATION = 85
const ECONOMIC_PLAN_SEGMENTS = [4, 6]
const PROMOTION_ECONOMIC_PLAN_IDS = [173, 176]

export const CancelSubscription = ({ isButton = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { storePromotions, availablePromotions } = usePromotions()
  const { t } = useTranslation()

  const user = useSelector(state => pathOr({}, ['api', 'user', 'info'], state))
  const userId = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'id'], state)
  )
  const is_local = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'ring', 'is_local'], state)
  )

  const shippingDays = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'ring', 'shipping_days'], state)
  )

  const magentoId = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'magento_id'], state)
  )

  const mealsPerDelivery = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'userPlan', 'mealsPerDelivery'], state)
  )

  const plan = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'userPlan'], state)
  )

  const diets = useSelector(state =>
    pathOr([], ['api', 'preferences', 'diets'], state)
  )

  const addresses = useSelector(state =>
    pathOr(null, ['api', 'subscription', 'info', 'addresses'], state)
  )

  const storeId = useSelector(state =>
    pathOr(null, ['api', 'subscription', 'info', 'store_id'], state)
  )

  const planChanges = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'planChanges'], state)
  )

  const availableDeliveryDays = useSelector(state =>
    pathOr(0, ['api', 'subscription', 'info', 'availableDeliveryDays'], state)
  )

  const { selectedDeliveryDays } = useSelector(getUserDeliveryInfoSelector)

  const getAddressData = addresses => {
    let addressData = {}
    if (addresses && addresses.length > 0) {
      const { postcode, region, regionId } = addresses[0]
      addressData = { zipCode: postcode, region, regionId }
    }
    return addressData
  }

  const getCompletedOrders = async () => {
    const completedOrders = await getUserOrdersCompleted(userId)
    return pathOr(null, ['data', 'countOrdersCompleted'], completedOrders)
  }

  const getLifespanSegment = async () => {
    const userLifespan = await getUserLifespan()
    return pathOr(
      null,
      ['data', 'weeksSinceFirstDeliveryWhileActive'],
      userLifespan
    )
  }

  const isOfferCancelation = () => {
    return (
      storePromotions &&
      storePromotions.find(
        elem => elem.promotionId === ID_PROMOTION_CANCELATION
      )
    )
  }

  const hadEconomicPlan = () => {
    const promotionsFiltered = storePromotions.filter(promotion =>
      PROMOTION_ECONOMIC_PLAN_IDS.includes(promotion.promotionId)
    )
    const economicPlans = planChanges.filter(planChange =>
      ECONOMIC_PLAN_SEGMENTS.includes(planChange?.plan?.segmentId)
    )
    return promotionsFiltered.length > 0 || economicPlans.length > 0
  }

  const hasAvailablePromotions = () => {
    return availablePromotions && availablePromotions.length > 0
  }

  const acceptsDeliveryDay = indexAvailableDeliveryDays => {
    if (availableDeliveryDays.length === 0) {
      return false
    }

    const availableDeliveryDay = availableDeliveryDays.find(
      availableDeliveryDay =>
        availableDeliveryDay.day === indexAvailableDeliveryDays
    )
    return !!availableDeliveryDay
  }

  const cancelSubscription = async () => {
    if (isLoading || !window.Brightback) {
      return
    }
    setIsLoading(true)

    try {
      const [completedOrders, lifespanSegment] = await Promise.all([
        getCompletedOrders(),
        getLifespanSegment()
      ])
      const brightBackVariables = {
        app_id: process.env.REACT_APP_BRIGHTBACK_ID,
        email: user.email,
        first_name: user.firstname,
        last_name: user.lastname,
        save_return_url: process.env.REACT_APP_BRIGHTBACK_SAVE_URL,
        cancel_confirmation_url: process.env.REACT_APP_BRIGHTBACK_CANCEL_URL,
        account: {
          internal_id: magentoId,
          subscription_id: userId
        },
        custom: {
          offer_eligible: !isOfferCancelation(storePromotions),
          subscription_plan: mealsPerDelivery,
          delivery_type: is_local
            ? `Local ${shippingDays}`
            : `Regional ${shippingDays}`,
          delivery_day: selectedDeliveryDays[0].day,
          completedOrders,
          lifespan_segment: lifespanSegment,
          diets,
          hasEconomicPlan: ECONOMIC_PLAN_SEGMENTS.includes(plan.segmentId),
          hadEconomicPlan: hadEconomicPlan(),
          hasAvailablePromotions: hasAvailablePromotions(),
          acceptsTuesday: acceptsDeliveryDay(2),
          acceptsWednesday: acceptsDeliveryDay(3),
          acceptsThursday: acceptsDeliveryDay(4),
          ...getAddressData(addresses),
          storeId
        }
      }
      const res = await window.Brightback.handleDataPromise(brightBackVariables)

      if (res.valid) {
        Analytics.track('Cancel Plan', {
          email: user.email,
          first_name: user.firstname,
          last_name: user.lastname,
          cancel: true
        })
        window.location.href = res.url
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      toaster.error('Ups! something went wrong')
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="cancel_subscription">
        {isButton ? (
          <CuiLink
            size="large"
            color="dark"
            fill="outline"
            onClick={cancelSubscription}>
            {t('pages.my_plan.cancelSubscriptionButton')}
          </CuiLink>
        ) : (
          <span onClick={cancelSubscription}>
            {' '}
            {t('pages.my_plan.cancelSubscriptionButton')}
          </span>
        )}
      </div>
    </>
  )
}
