import { formatFixedPrice } from '../../utils/utils'
import { ReactComponent as Star } from '../../assets/svg/full-star.svg'
import { ReactComponent as Vegan } from '../../assets/svg/mealcard/Vegan.svg'
import { ReactComponent as Vegetarian } from '../../assets/svg/mealcard/Vegetarian.svg'
import { ReactComponent as Spicy } from '../../assets/svg/mealcard/spicy-icon.svg'
import { ReactComponent as Strict } from '../../assets/svg/mealcard/Strict.svg'
import { ReactComponent as Info } from '../../assets/svg/info-empty-circled.svg'
import { ReactComponent as DietPescatarian } from '../../assets/icons/menuby/pescatarianDiet.svg'
import { ReactComponent as DietPaleo } from '../../assets/icons/menuby/paleo.svg'
import { ReactComponent as DietKeto } from '../../assets/icons/menuby/keto.svg'
import { ReactComponent as DietMediterranean } from '../../assets/icons/menuby/mediterraneanDiet.svg'
import { MENU_CATEGORIES } from 'src/constants/menu'
import { ReactComponent as PremiumIcon } from '../../assets/svg/mealcard/premium-meals.svg'

export const getAddButtonText = (
  meal,
  planIsCompleted,
  isExtra,
  isFlexUser
) => {
  switch (true) {
    case isFlexUser && isExtra:
      return `Add for ${formatFixedPrice(meal.price)}`
    case isExtra:
      return `Add extra for ${formatFixedPrice(meal.price)}`
    case planIsCompleted:
      return `Add meal for ${formatFixedPrice(
        meal.price + (meal.premium_fee || 0)
      )}`
    case isFlexUser && meal.premium_fee > 0:
      return `Add for ${formatFixedPrice(meal.price + (meal.premium_fee || 0))}`
    case meal.premium_fee > 0:
      return `Add meal + ${formatFixedPrice(meal.premium_fee)}`
    case isFlexUser:
      return `Add meal for ${formatFixedPrice(
        meal.price + (meal.premium_fee || 0)
      )}`
    default:
      return 'Add meal'
  }
}

export const getCondensedPriceText = (
  meal,
  planIsCompleted,
  isExtra,
  isFlexUser
) => {
  switch (true) {
    case isFlexUser && isExtra:
      return `Add for ${formatFixedPrice(meal.price)}`
    case isExtra:
      return `Add extra for ${formatFixedPrice(meal.price)}`
    case planIsCompleted:
      return `Add meal for ${formatFixedPrice(
        meal.price + (meal.premium_fee || 0)
      )}`
    case isFlexUser && meal.premium_fee > 0:
      return `Add for ${formatFixedPrice(meal.price + (meal.premium_fee || 0))}`
    case meal.premium_fee > 0:
      return `Add meal + ${formatFixedPrice(meal.premium_fee)}`
    case isFlexUser:
      return `Add meal for ${formatFixedPrice(
        meal.price + (meal.premium_fee || 0)
      )}`
    default:
      return ''
  }
}

export const isNew = meal => meal.isNewMeal
export const isNewChef = meal =>
  meal.feature && meal.feature.name.toUpperCase() === 'NEW_AUTHOR'

export const isBackInTheMenu = meal => !!meal.isBackInTheMenu
export const isRestaurantExclusive = meal => !!meal.isMealRestaurantExclusive
export const isMealWithRecipe = meal => !!meal.hasRecipe

export const findSpecificationDetail = (details, tag) =>
  Array.isArray(details) &&
  details.find(
    d =>
      d &&
      typeof d.label === 'string' &&
      d.label.toLowerCase() === tag.toLowerCase()
  )

export const formatMealRating = stars => stars && stars.toFixed(1)

const PROTEIN_TYPES = {
  vegan: 'Vegan',
  vegetarian: 'Vegetarian'
}

const SPECIFICATIONS = {
  spicy: 'spicy',
  low_carbs: 'low carbs',
  low_calories: 'Less Than 600 calories',
  low_sodium: 'Low Sodium'
}

export const getCharacteristicsL1 = (meal, max) => {
  const result = []
  if (
    meal.warnings?.diets_not_matching?.length > 0 ||
    meal.warnings?.allergens_not_matching?.length > 0
  ) {
    result.push({
      label: 'Restriction',
      icon: <Strict />,
      tooltip: meal.warning,
      color: '#EE5744'
    })
  } else if (meal.warnings?.restrictions_applied?.length > 0) {
    result.push({
      label: '',
      icon: <Info />,
      tooltip: meal.warning,
      color: '#000'
    })
  }
  if (meal.dietsMatching && meal.dietsMatching.length > 0) {
    meal.dietsMatching.forEach(diet => {
      if (SHOW_DIET_TAG.includes(diet?.name)) {
        result.push(DIET_TAGS[diet.name])
      }
    })
  }
  if (meal.protein_type === PROTEIN_TYPES.vegan) {
    result.push({
      label: meal.protein_type,
      icon: <Vegan />,
      color: '#199861'
    })
  } else if (meal.protein_type === PROTEIN_TYPES.vegetarian) {
    result.push({
      label: meal.protein_type,
      icon: <Vegetarian />,
      color: '#199861'
    })
  }
  if (
    findSpecificationDetail(meal.specifications_detail, SPECIFICATIONS.spicy)
  ) {
    result.push({
      label: 'Spicy',
      icon: <Spicy />,
      color: '#EE5744'
    })
  }
  return result?.length > max ? result.splice(0, max) : result
}

const addRating = (meal, result) => {
  if (showRating(meal)) {
    result.push({
      label: formatMealRating(meal.stars),
      icon: <Star />
    })
  }
}

const addSpecification = (meal, result, specification, label) => {
  if (findSpecificationDetail(meal.specifications_detail, specification)) {
    result.push({
      label
    })
  }
}

const addCalories = (meal, result) => {
  if (meal.calories) {
    result.push({
      label: `${meal.calories} cal`
    })
  }
}

export const getCharacteristicsL2 = (meal, max, category) => {
  const result = []
  switch (category) {
    case MENU_CATEGORIES.SIDES:
    case MENU_CATEGORIES.BREAKFAST:
    case MENU_CATEGORIES.SAUCES:
    case MENU_CATEGORIES.DRINKS:
      addRating(meal, result)
      addCalories(meal, result)
      addSpecification(meal, result, SPECIFICATIONS.low_carbs, 'Low carbs')
      addSpecification(meal, result, SPECIFICATIONS.low_sodium, 'Low sodium')
      addSpecification(
        meal,
        result,
        SPECIFICATIONS.low_calories,
        'Low calories'
      )
      break
    case MENU_CATEGORIES.TREATS:
      addRating(meal, result)
      break
    default:
      addRating(meal, result)
      addCalories(meal, result)
      addSpecification(meal, result, SPECIFICATIONS.low_carbs, 'Low carbs')
      addSpecification(meal, result, SPECIFICATIONS.low_sodium, 'Low sodium')
      addSpecification(
        meal,
        result,
        SPECIFICATIONS.low_calories,
        'Low calories'
      )
      break
  }
  return result?.length > max ? result.splice(0, max) : result
}

const showRating = meal =>
  !isNew(meal) && parseInt(meal.reviews) > 0 && parseInt(meal.stars) > 0

export const showProteinOnlyTag = meal =>
  meal.sidesSubCategoryNames?.includes('Protein')

const SHOW_DIET_TAG = [
  'keto diet',
  'paleo',
  'pescatarian diet',
  'mediterranean diet'
]

const DIET_TAGS = {
  'keto diet': {
    label: 'Keto',
    icon: <DietKeto />,
    color: '#3939D8'
  },
  paleo: {
    label: 'Paleo',
    icon: <DietPaleo />,
    color: '#3939D8'
  },
  'pescatarian diet': {
    label: 'Pescatarian',
    icon: <DietPescatarian />,
    color: '#3939D8'
  },
  'mediterranean diet': {
    label: 'Mediterranean',
    icon: <DietMediterranean />,
    color: '#3939D8'
  }
}

export const getProteinTag = proteinType => {
  if (!proteinType) {
    return
  }

  const proteinsMap = {
    glutenfree: { icon: '', label: 'Gluten Free' },
    keto: { icon: '', label: 'Keto Diet' },
    meat: { icon: '', label: 'Meat' },
    paleo: { icon: '', label: 'Paleo' },
    poultry: { icon: '', label: 'Poultry' },
    seafood: { icon: '', label: 'Seafood' },
    vegan: { icon: 'VG', label: 'Vegan' },
    vegetarian: { icon: 'VE', label: 'Vegetarian' }
  }
  return proteinsMap[proteinType.toLowerCase()]
}

const MAIN_TAGS = {
  NEW: {
    label: 'NEW',
    color: 'yellow'
  },
  PREMIUM: {
    label: 'Premium',
    color: 'dark',
    icon: <PremiumIcon />
  },
  ITS_BACK: {
    label: "IT'S BACK!",
    color: 'dark'
  },
  RESTAURANT_EXCLUSIVE: {
    label: 'Restaurant Exclusive',
    color: 'dark'
  },
  INCLUDES_RECIPE: {
    label: 'Includes Recipe',
    color: 'dark'
  },
  CLIMATE_FRIENDLY: {
    label: 'Climate Friendly',
    color: 'dark'
  },
  PROTEIN_ONLY: {
    label: 'Protein Only',
    color: 'dark'
  }
}

const TAG_TYPES = {
  MERCHANDISE: 'Merchandising Labels'
}

export const getMainTags = (
  meal,
  max,
  backInTheMenuEnabled,
  recipesEnabled
) => {
  const result = []
  const showIsBackInTheMenu = backInTheMenuEnabled && isBackInTheMenu(meal)
  if (isNew(meal) && !showIsBackInTheMenu) {
    result.push(MAIN_TAGS.NEW)
  }
  if (showIsBackInTheMenu) {
    result.push(MAIN_TAGS.ITS_BACK)
  }
  if (meal.premium_special) {
    result.push(MAIN_TAGS.PREMIUM)
  }
  if (showProteinOnlyTag(meal)) {
    result.push(MAIN_TAGS.PROTEIN_ONLY)
  }
  if (meal.all_tags) {
    const merchandiseTags = meal.all_tags?.filter(
      tag => tag.type === TAG_TYPES.MERCHANDISE
    )
    merchandiseTags.forEach(tag => {
      result.push({
        label: tag.name,
        color: 'dark'
      })
    })
  }
  if (recipesEnabled && isMealWithRecipe(meal)) {
    result.push(MAIN_TAGS.INCLUDES_RECIPE)
  }
  if (isRestaurantExclusive(meal)) {
    result.push(MAIN_TAGS.RESTAURANT_EXCLUSIVE)
  }
  return result?.length > max ? result.splice(0, max) : result
}
