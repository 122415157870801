import { RoutePaths } from '../../../../routes/routePaths'

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL

interface MenuLink {
  label: string
  pathname: string | null
  icon: string
  url?: string
}

export const getMenuLinks = (isFlexPlan): Array<MenuLink> => {
  const menuLinks = [
    {
      label: 'header.tab.orders',
      pathname: RoutePaths.ORDERS,
      icon: 'archive'
    },
    {
      label: 'header.tab.history',
      pathname: RoutePaths.HISTORY,
      icon: 'timer'
    },
    {
      label: 'header.tab.meal_preferences',
      pathname: RoutePaths.MEAL_PREFERENCES,
      icon: 'toggleRight'
    },
    {
      label: isFlexPlan
        ? 'header.tab.switch_to_weekly_plan'
        : 'header.tab.myPlan',
      pathname: RoutePaths.PLAN,
      icon: 'layers'
    },
    {
      label: 'header.tab.credits_and_promotions',
      pathname: RoutePaths.CREDITS_AND_PROMOTIONS,
      icon: 'percent'
    },
    {
      label: 'header.tab.billing_and_receipts',
      pathname: RoutePaths.BILLING_AND_RECEIPTS,
      icon: 'billing'
    },
    {
      label: 'header.tab.favorites',
      pathname: RoutePaths.FAVORITES,
      icon: 'heart'
    },
    {
      label: 'header.tab.refer_a_friend',
      pathname: RoutePaths.REFER_A_FRIEND,
      icon: 'friend'
    },
    {
      label: 'header.tab.account',
      pathname: RoutePaths.ACCOUNT,
      icon: 'options'
    },
    {
      label: 'header.tab.notifications',
      pathname: RoutePaths.NOTIFICATIONS,
      icon: 'messageSquare'
    },
    {
      label: 'header.tab.nutrition',
      pathname: RoutePaths.NUTRITION,
      icon: 'nutrition'
    },
    {
      label: 'header.tab.how_it_works',
      pathname: null,
      url: `${BASE_URL}${RoutePaths.HOW_IT_WORKS}`,
      icon: 'questionMarkCircle'
    },
    {
      label: 'header.tab.faqs',
      pathname: null,
      url: 'https://support.cookunity.com',
      icon: 'questionMarkSquare'
    }
  ]
  if (isFlexPlan) {
    return menuLinks.filter(link => link.label !== 'header.tab.refer_a_friend')
  }
  return menuLinks
}
