import tracking from 'src/shared/tracking'
import { useHistory } from 'react-router-dom'

export function useTracking() {
  const history = useHistory()

  function track(eventName: string, props = {}) {
    const context = {
      /**
       * Old bug: history.push('...') && track(...) sent the new page object in the track info.
       * Explanation: Default Segment implementation sends the page object after history.push takes effect.
       * Fix: Send page info manually as context.
       * Caveats: In Segment dev tab explorer still says the new page url but if you inspect the event it has the correct page info.
       * Alternative fix: Wait for the event to finish before navigating to the new page. This produces a laggy experience.
       */
      page: {
        path: history.location.pathname,
        search: history.location.search,
        title: history.location.title,
        url: window.location.href
      }
    }
    tracking.track(eventName, props, context)
  }

  return {
    track
  }
}
