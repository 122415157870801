import { handleActions } from 'redux-actions'
import { helperStatus } from '../helpers'
import * as actions from './actions'

const initialState = {
  inFlight: false,
  succeeded: false,
  error: false,
  firstLine: '',
  secondLine: '',
  cta: ''
}

export default handleActions(
  {
    [actions.getReferralConfigStart]: state => {
      return {
        ...state,
        firstLine: '',
        secondLine: '',
        cta: '',
        ...helperStatus({ inFlight: true, succeeded: false, error: false })
      }
    },
    [actions.getReferralConfigSuccess]: (state, { payload }) => {
      return {
        ...state,
        firstLine: payload.firstLine,
        secondLine: payload.secondLine,
        cta: payload.cta,
        ...helperStatus({ inFlight: false, succeeded: true, error: false })
      }
    },
    [actions.getReferralConfigFail]: (state, { payload }) => {
      return {
        ...state,
        firstLine: payload.firstLine,
        secondLine: payload.secondLine,
        cta: payload.cta,
        ...helperStatus({
          inFlight: false,
          succeeded: false,
          error: payload.err
        })
      }
    }
  },
  initialState
)
