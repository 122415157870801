export const subscriptionBackQuery = `
query getPersonalizedMenu($date: String!) {
  personalized(date: $date) {
    available
    categories {
      id
      title
      label
      subtitle
      tag
      cover_title
      cover_text
      cover_image
      cover_image_mobile
      merchandised_new {
        id
        title
        description
        authorId
        authorName
        authorPhoto
        coverPhoto
        position
        isAutomaticCreated
        color
        items {
          entity_id
          name
        }
      }
      meals {
        entity_id
        batch_id
        name
        short_description
        calories
        image
        image_path
        price
        is_premium
        premium_fee
        premium_special
        sku
        entity_id
        category_id
        chef_id
        chef_firstname
        chef_lastname
        is_celebrity_chef
        feature {
          name
          description
          icon
          color
          background
        }
        nutritional_facts {
          calories
          fat
          servingsize
          carbs
          fiber
          protein
          saturated_fat
          sodium
          sugar
        }
        logopic
        bannerpic
        stock
        inStock
        qty
        meat_type
        filter_by
        stars
        reviews
        source
        user_rating
        warning
        warnings {
          message
          restrictions_applied {
            name
          }
        }
        weight
        sidedish {
          id
        }
        specifications_detail {
          label
        }
        ingredients_data {
          entity_id
          name
          value
        }
      }
    }
  }
}
`
export const menuServiceQuery = `
    query getMenu($date: String!) {
      menu(date: $date) {
        categories{
            id
            title
            subtitle
            label
            tag
            icon
            coverInfo {
                title
                text 
                image
                imageMobile
                imageMacro
            }
        }
        meals {
            id
            batchId
            name
            shortDescription
            image
            imagePath
            price
            premiumFee
            premiumSpecial
            sku
            source
            stock
            isNewMeal
            isBackInTheMenu
            isMealRestaurantExclusive
            hasRecipe
            userRating
            warning
            warnings {
              message
              restrictionsApplied {
                name
              }
              dietsNotMatching {
                name
              }
              allergensNotMatching {
                name
              }
            }
            dietsMatching {
              name
            }
            reviews
            stars
            qty
            categoryId
            category{
                id
                title
                label
            }
            chef{
                id
                firstName
                lastName
                bannerPic
                profileImageUrl
            }
            meatType
            meatCategory
            nutritionalFacts{
                calories
                fat
                carbs
                sodium
                fiber
            }
            specificationsDetails{
                label
            }
            sideDish{
                id
                name
            }
            feature {
              name
              description
              icon
              color
              background
            }
            weight
            filter_by
            cuisines
            sidesSubCategoryNames
            media {
              secondaryImage
              gif
            }
            categories {
              label
            }
            relatedMeal {
              id
              categoryId
              name
              shortDescription
              image
              imagePath
              price
              premiumFee
              premiumSpecial
              sku
              stock
              isNewMeal
              userRating
              warning
              batchId
            }
            typesTags
            tags {
              type
              name
            }
            inventories {
              id
            }
        }
        filters{
            selectedChefs
        }
        menuByV3
        sorting {
          type
          sort
        }
      }
    }
  `
export const getMealMenuServiceQuery = `
query getMeal($id: Int!) {
  meal(id: $id) {
    id
    name
    sku
    image
    imagePath
    smallImage
    thumbnail
    stars
    mealStory
    allergenicStatement
    isNewMeal
    isCarbonNeutral
    nutritionalFacts {
        calories
        servingSize
        fat
        carbs
        fiber
        protein
        sodium
        saturatedFat
        sugar
    }
    cookingSteps {
        microwaveSteps
        ovenSteps
    }
    categoryId
    category{
        id
    }
    categories {
      label
    }
    chef{
        id
        firstName
        lastName
        bannerPic
    }
    assortment
    specificationsDetails{
      id
      label
      image
    }
    price
    premiumSpecial
    premiumFee
    reviews
    userReviews{
      id
      customerFirstName
      customerLastName
      feedback
      rating
      createdAt
    }
    ingredientsStatement
    shortDescription
    media {
      detailsImage
      detailsImageMobile
    }
    detailsTitle
    detailsDescription
    worksWellWith {
      description
      image
    }
    feature {
      name
    }
    meatType
    recipes {
      id
      chef_name
      chef_id
      image_url
      title
      description
      chef_image_url
      tags
      servings
      tools_needed
      ingredients
      preparation_time
      cooking_time
      steps
    }
    heatingInstructionsVideos {
      name
      path
    }
  }
}
`

export const getMealsMenuServiceQuery = `
query getMeals($ids: [Int!]) {
  meals(ids: $ids) {
    id
    name
    image
    imagePath
    stars
    chef{
        id
        firstName
        lastName
        bannerPic
    }
    price
    premiumSpecial
    premiumFee
    reviews
    userReviews{
      id
      rating
    }
    detailsTitle
    detailsDescription
  }
}
`

export const getMerchandiseMealsQuery = `
query getCollections($date: String!) {
  collections(date: $date) {
    id
    title
    description
    authorId
    authorName
    authorPhoto
    coverPhoto
    position
    isAutomaticCreated
    color
    __typename
  }
}

`

export const getMenuCollectionsQuery = `
query getCollections($date: String!) {
  collections(date: $date) {
    id
    title
    description
    authorId
    authorName
    authorPhoto
    heroPhoto
    coverPhoto
    position
    isAutomaticCreated
    color
    meals {
        id
        batchId
          name
          shortDescription
          image
          imagePath
          price
          premiumFee
          premiumSpecial
          sku
          stock
          isNewMeal
          hasRecipe
          userRating
          warning
          warnings {
            message
            restrictionsApplied {
              name
            }
            dietsNotMatching {
              name
            }
            allergensNotMatching {
              name
            }
          }
          dietsMatching {
            name
          }
          reviews
          stars
          qty
          categoryId
          category{
              id
              title
          }
          chef{
              id
              firstName
              lastName
              bannerPic
              logoPic
              profileImageUrl
          }
          meatType
          meatCategory
          nutritionalFacts{
              calories
          }
          specificationsDetails{
              label
          }
          sideDish{
              id
              name
          }
          feature {
            name
            description
            icon
            color
            background
          }
          weight
          filter_by
          cuisines
          relatedMeal {
              id
              categoryId
              name
              shortDescription
              image
              imagePath
              price
              premiumFee
              premiumSpecial
              sku
              stock
              isNewMeal
              userRating
              warning
              batchId
          }
          tags {
            type
            name
          }
          inventories {
            id
          }
      __typename
    }
    __typename
  }
}

`

export const getRecipeQuery = `
query getRecipe($id: Int!) {
  recipe(id: $id) {
    id
    chef_name
    chef_id
    image_url
    mealExternalId
    title
    description
    chef_image_url
    tags
    servings
    tools_needed
    ingredients
    preparation_time
    cooking_time
    steps
  }
}
`

export const fastOrderingUserLastOrder = `
query meals($date: String!) {
  userLastOrder(date: $date) {
    meals {
      id
      batchId
      quantity
    },
    cartStatus
  }
}
`

export const fastOrderingUserRecommendation = `
query meals($date: String!) {
  recommendationOnDemand(date: $date) {
    meals {
      id
      batchId
      quantity
    },
    cartStatus
  }
}
`

export const getCrossSellingItems = `
  query getCrossSellingItems($date: String!, $cartItems: [CartItem!]) {
    crossSellingItems(date: $date, cartItems: $cartItems) { 
      meals{
        id
      }
      category
    }
  }`
