import gqlClient from '../gqlClient'
import menuService from '../menuService'

/**
 * Get quick filters
 */
export const getQuickFilters = () =>
  gqlClient.publicQuery(
    `
    query quickFilters {
        quickFilters {
            filters {
                id
                label
                pattern
                image
            }
        }
    }
  `
  )

/**
 * Get already requested meals
 */
export const getUserAlreadyRequestedMeals = date =>
  menuService.query(
    `
    query getMenu($date: String!, $filters: MenuFilters!) {
      menu(date: $date, filters: $filters) {
        categories{
          id
          title
          subtitle
          label
          tag
          coverInfo {
              title
              text 
              image
              imageMobile
          }
      }
      meals {
          id
          batchId
          name
          shortDescription
          image
          imagePath
          price
          premiumFee
          premiumSpecial
          sku
          source
          stock
          isNewMeal
          userRating
          warning
          warnings {
            message
            restrictionsApplied {
              name
            }
          }
          reviews
          stars
          qty
          categoryId
          category{
              id
              title
              label
          }
          chef{
              id
              firstName
              lastName
              bannerPic
              logoPic
              isCelebrityChef
          }
          meatType
          meatCategory
          nutritionalFacts{
              calories
              fat
              carbs
              sodium
              fiber
          }
          specificationsDetails{
              label
          }
          sideDish{
              id
              name
          }
          feature {
            name
            description
            icon
            color
            background
          }
          weight
          filter_by
          cuisines
          sidesSubCategoryNames
          media {
            secondaryImage
          }
            
      }
      filters{
          selectedChefs
      }
    }
  }
`,
    { date, filters: { onlyAlreadyRequested: true } },
    true
  )
