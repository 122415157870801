import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { RetryLink } from 'apollo-link-retry'

import { getTokenSilently, getImpersonator } from '../auth0'
import { getPlatform } from '../utils/utils'

const API_URL = process.env.REACT_APP_SUBSCRIPTION_API_URL
const USER_URL = `${API_URL}/graphql/user`
const PUBLIC_URL = `${API_URL}/graphql/public`

const httpLink = createHttpLink({
  uri: USER_URL,
  credentials: 'include'
})

const authLink = setContext(async (_, { headers }) => {
  const impersonator = getImpersonator()
  if (impersonator) {
    return {
      headers: {
        ...headers,
        'x-cook-impersonation-secret': impersonator.secret,
        'x-cook-impersonation-email': impersonator.email
      }
    }
  }

  const token = await getTokenSilently()
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : ''
    }
  }
})

const customHeaders = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'cu-platform': getPlatform()
    }
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 500,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: error => !!error
  }
})

const client = new ApolloClient({
  link: retryLink
    .concat(authLink)
    .concat(customHeaders)
    .concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

const publicClient = new ApolloClient({
  link: retryLink.concat(
    createHttpLink({
      uri: PUBLIC_URL,
      credentials: 'include'
    })
  ),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  query: (query, variables = {}) =>
    client.query({
      query: gql(query),
      variables
    }),
  publicQuery: (query, variables = {}) =>
    publicClient.query({
      query: gql(query),
      variables
    }),
  publicMutation: (mutation, variables = {}) =>
    publicClient.mutate({
      mutation: gql(mutation),
      variables
    }),
  mutation: (mutation, variables = {}) =>
    client.mutate({
      mutation: gql(mutation),
      variables
    })
}
