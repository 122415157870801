import './styles.scss'

import { useState, Fragment } from 'react'
import { Formik } from 'formik'
import { CuiButton, CuiRadio } from 'front-lib'
import moment from 'moment'

import Form, { ButtonRow, Col, ErrorMessage } from '../../../../components/Form'
import Modal from '../../../../components/NewModal'

import tracking from '../../../../shared/tracking'

import { openChat } from '../../../../utils/kustomer'
import { getUpcomingOrdersAfterCutoff } from '../../../../utils/utils'

import { formOptions } from './constants'

import { getAddressByOrders, getDateByOrders, getFinishDate } from './utils'

export function ModalFeedback({
  doPause,
  isOpen,
  mealsPerWeek,
  onRequestClose,
  pauseDuration,
  sendSatisfactionResponse,
  upcomingOrders
}) {
  const [selectedOption, setSelectedOption] = useState(null)

  const handleClose = () => {
    tracking.track('Pause Modal', {
      action: 'Close Modal'
    })

    onRequestClose()
  }

  const handleSubmit = async values => {
    await sendSatisfactionResponse({
      experience: 1,
      issueId: null,
      issue: values.answer,
      comment: '',
      experiment: 'Pause'
    })

    doPause(values.answer, pauseDuration, mealsPerWeek)

    handleClose()

    tracking.track('Pause Modal', {
      action: 'Send Form'
    })
  }

  return (
    <Fragment>
      <Modal
        mediumPlusSize
        isOpen={isOpen}
        onRequestClose={handleClose}
        className="modal-feedback-pause">
        <Formik
          initialValues={{}}
          isInitialValid={false}
          onSubmit={handleSubmit}>
          {({ values, isSubmitting, setFieldValue, isValid }) => (
            <Form>
              <h1 className="title">Confirm: Pause Plan</h1>
              <div className="pause-sub-title-container">
                <p>
                  You are pausing your plan from{' '}
                  <b>{moment().format(' ddd, MMM DD ')}</b> to
                  <b>{getFinishDate(pauseDuration)}.</b>
                </p>
              </div>
              {getUpcomingOrdersAfterCutoff(upcomingOrders).length > 0 && (
                <div className="pause-note-container">
                  <p>
                    Note:
                    <span>
                      <strong>
                        {` Our chefs are already preparing your
                        ${
                          getUpcomingOrdersAfterCutoff(upcomingOrders).length >
                          1
                            ? 'orders'
                            : 'order'
                        }
                        for `}
                        {getDateByOrders(
                          getUpcomingOrdersAfterCutoff(upcomingOrders)
                        )}
                      </strong>
                      {` ${
                        getAddressByOrders(upcomingOrders).length > 1
                          ? 'Those orders'
                          : 'That order'
                      }
                      will be delivered as scheduled to `}
                      {getAddressByOrders(upcomingOrders)}
                    </span>
                  </p>
                </div>
              )}
              <div className="pause-sub-title-container">
                <p>Can you let us know why you are pausing?</p>
              </div>
              <div className="section-container">
                {formOptions.map(option => {
                  return (
                    <div className="radio-item" key={option.key}>
                      <CuiRadio
                        name="answer"
                        checked={values.answer === option.value}
                        onClick={() => {
                          setFieldValue('answer', option.value)
                          setSelectedOption(option.key)
                        }}>
                        {option.value}
                      </CuiRadio>
                    </div>
                  )
                })}
                {selectedOption === formOptions.length && (
                  <div className="contact-cs">
                    Sorry to hear that! Please{' '}
                    <span onClick={openChat}>contact us</span> and we'll see how
                    can resolve your issue.
                  </div>
                )}
                <ErrorMessage name="answer" component="div" />
              </div>
              <div className="pause-modal-button">
                <ButtonRow>
                  <Col>
                    <CuiButton
                      size="large"
                      fill="outline"
                      onClick={handleClose}>
                      Cancel Pause
                    </CuiButton>
                  </Col>
                  <Col>
                    <CuiButton
                      type="submit"
                      size="large"
                      disabled={!isValid || isSubmitting}>
                      Confirm Pause
                    </CuiButton>
                  </Col>
                </ButtonRow>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  )
}
