import { createSelector } from 'reselect'
import moment from 'moment'
import { pathOr } from 'ramda'

export const pausePlanIsUpdating = state =>
  pathOr(false, ['api', 'pausePlanStatus', 'inFlight'], state)

export const getSubscriptionPlan = state =>
  pathOr(null, ['api', 'subscription', 'userPlan'], state)

export const getAvailablePlans = state =>
  pathOr(
    null,
    ['api', 'subscription', 'availablePlans', 'data', 'featuredPlans'],
    state
  )

export const getUpcomingDays = state =>
  pathOr(null, ['api', 'subscription', 'upcomingDays'], state)

export const getUserDeliveryDays = state =>
  pathOr(null, ['api', 'subscription', 'info', 'deliveryDays'], state)

export const getAvailableDeliveryDays = state =>
  pathOr([], ['api', 'subscription', 'info', 'availableDeliveryDays'], state)

export const getPlanId = state =>
  pathOr(0, ['api', 'subscription', 'info', 'plan_id'], state)

export const getAvailableTimeslots = state =>
  pathOr([], ['api', 'subscription', 'timeslots'], state)

export const userDeliveryInfoIsUpdating = state =>
  pathOr(false, ['api', 'updateSubscriptionDaysStatus', 'inFlight'], state)

export const userDeliveryInfoIsLoading = createSelector(
  [getUserDeliveryDays, userDeliveryInfoIsUpdating],
  (deliveryDays, isUpdating) => !deliveryDays || isUpdating
)

export const planUpdateIsLoading = state =>
  pathOr(false, ['api', 'setSubscriptionPlanStatus', 'inFlight'], state)

export const planIsLoading = createSelector(
  [
    pausePlanIsUpdating,
    getSubscriptionPlan,
    getAvailablePlans,
    userDeliveryInfoIsLoading,
    planUpdateIsLoading
  ],
  (
    pausePlan,
    getSubscriptionPlan,
    availablePlans,
    deliveryInfoLoading,
    planIsUpdating
  ) =>
    pausePlan ||
    !getSubscriptionPlan ||
    !availablePlans ||
    deliveryInfoLoading ||
    planIsUpdating
)

export const getTimeslots = createSelector([getAvailableTimeslots], timeslots =>
  timeslots.map(t => ({
    ...t,
    value: t.label
  }))
)

export const getUserDeliveryInfoSelector = createSelector(
  [getUserDeliveryDays, getAvailableDeliveryDays],
  (deliveryDays, availableDeliveryDays) => {
    if (deliveryDays) {
      const day = deliveryDays.length > 0 ? deliveryDays[0] : null
      const start = day && moment(day.time_start, 'HH:mm').format('h:mm A')
      const end = day && moment(day.time_end, 'HH:mm').format('h:mm A')
      return {
        selectedDeliveryDays: deliveryDays.map(delivery =>
          availableDeliveryDays.find(day => day.day === delivery.day)
        ),
        selectedTimeslot: {
          label: day ? `${start} - ${end}` : '',
          value: day ? `${start} - ${end}` : '',
          start: day ? day.time_start : '',
          end: day ? day.time_end : ''
        }
      }
    }
    return null
  }
)

export const getUpComingOrders = createSelector(
  [getUpcomingDays],
  upcomingDays => {
    const upcomingDaysSorted =
      upcomingDays &&
      upcomingDays.sort((a, b) => {
        const aTime = moment(a.date, 'YYYY-MM-DD')
        const bTime = moment(b.date, 'YYYY-MM-DD')

        if (aTime.isBefore(bTime)) return -1
        if (bTime.isBefore(aTime)) return 1
        return 0
      })
    const currentDate = moment()
    const upcomingOrderFiltered =
      upcomingDaysSorted &&
      upcomingDaysSorted.reduce(
        (accum, elem) =>
          !elem.skip &&
          elem.order &&
          moment(elem.date).isSameOrAfter(currentDate, 'day')
            ? [...accum, { order: elem.order, cutoffTime: elem.cutoff.time }]
            : accum,
        []
      )
    return upcomingOrderFiltered && upcomingOrderFiltered
  }
)
