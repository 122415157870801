import { reject, includes } from 'ramda'
import { format } from 'date-fns'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import gqlClient from '../../api/gqlClient'

import {
  signupData,
  mealPlans,
  reactivateSubscription,
  consumeCoupon,
  getCouponData,
  sampleMenu,
  loginLegacyUser,
  publicAddAddress,
  publicAddCard,
  createSignupUser,
  checkCoupon,
  deliveryDays
} from './queries'

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

function sendToLoggingApi(body) {
  return axios.post(`${process.env.REACT_APP_LOGGIN_API_URL}/logging`, body, {
    headers: {
      Authorization: process.env.REACT_APP_LOGGIN_API_KEY,
      'Content-type': 'application/json'
    }
  })
}

// TODO if you're working with this file, please fix this linter issue and test it with your ticket.
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createSignup: (email, password, subscriptionCharge, payload) =>
    gqlClient
      .publicMutation(createSignupUser, {
        email,
        password,
        subscriptionCharge,
        user: payload
      })
      .then(res => res.data.createSignupUser),
  publicAddCard: payload =>
    gqlClient
      .publicMutation(publicAddCard, payload)
      .then(res => res.data.publicAddCard),
  publicAddAddress: payload =>
    gqlClient
      .publicMutation(publicAddAddress, { ...payload })
      .then(res => res.data.publicAddAddress),
  loginLegacyUser: ({ email, password, firstname, lastname }) =>
    gqlClient
      .publicMutation(loginLegacyUser, { email, password, firstname, lastname })
      .then(res => {
        return res.data.signupLegacyUser
      }),
  getSignupData: zipcode =>
    gqlClient.publicQuery(signupData, { zipcode }).then(res => {
      return {
        ...res.data,
        timeslots: reject(
          slot => includes('ASAP', slot.label),
          res.data.timeslots
        )
      }
    }),
  getMealPlans: zipcode =>
    gqlClient.publicQuery(mealPlans, { zipcode }).then(res => res.data),
  getSampleMenu: () => {
    return gqlClient.publicQuery(sampleMenu, {}).then(res => {
      return res.data.sampleMenu
    })
  },
  getCouponData: () =>
    gqlClient.publicQuery(getCouponData).then(res => res.data.coupon.promotion),
  reactivateSubscription: () =>
    gqlClient
      .mutation(reactivateSubscription, {
        date: format(new Date(), 'YYYY-MM-DD')
      })
      .then(res => res.data),
  consumeCoupon: (coupon, isResurrected = false) =>
    gqlClient
      .mutation(consumeCoupon, {
        coupon,
        isResurrected
      })
      .then(res => res.data),
  applyCouponCode: coupon =>
    gqlClient.publicQuery(checkCoupon, { coupon }).then(res => res.data),
  getDeliveryDays: zipcode =>
    gqlClient.publicQuery(deliveryDays, { zipcode }).then(res => res.data),
  sendSignUpLog: payload => sendToLoggingApi(payload).then(res => res.data)
}
