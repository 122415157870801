import { ADDONS_CATEGORIES } from 'src/modules/MyPlan/utils'

export const getMealsCount = (items, key = '') =>
  items
    ? items
        .filter(
          item =>
            ADDONS_CATEGORIES.indexOf(
              key ? item[key].category_id : item.category_id
            ) === -1
        )
        .reduce((qty, meal) => qty + meal.qty, 0)
    : 0
