import { handleActions } from 'redux-actions'

import * as modalsActions from './actions'

const initialState = {
  skipModal: {
    day: null,
    order: null,
    orderTimeslot: null,
    isOpen: false,
    cutoff: null
  }
}

export default handleActions(
  {
    [modalsActions.toggleModal]: (state, { modal, payload }) => {
      return {
        ...state,
        [modal]: {
          ...payload
        }
      }
    }
  },
  initialState
)
