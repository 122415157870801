const localStorage = window.localStorage || null
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  set(key, value) {
    localStorage && localStorage.setItem(key, value)
  },
  get(key) {
    return (localStorage && localStorage.getItem(key)) || null
  },
  isAvailable() {
    return !!localStorage
  }
}
