import gqlClient from '../gqlClient'

export const getCart = orderDate =>
  gqlClient
    .query(
      `query getCart($date: String!) {
        cart(date: $date){
          entity_id,
          qty: quantity
        }
    }
    `,
      { date: orderDate }
    )
    .then(response => response.data.cart)

export const addProductToCart = ({
  orderDate,
  entity_id,
  batch_id,
  quantity,
  withRelatedMeal = false
}) =>
  gqlClient.mutation(
    `mutation addProductToCart($date: String!, $entity_id: Int!, $batch_id: Int!, $quantity: Int!, $withRelatedMeal: Boolean) {
      addMeal(date: $date, entity_id: $entity_id, batch_id: $batch_id, quantity: $quantity, withRelatedMeal: $withRelatedMeal){
          entity_id,
          qty: quantity
        }
    }
    `,
    { date: orderDate, entity_id, batch_id, quantity, withRelatedMeal }
  )

export const addMultipleProductsToCart = ({ date, entities }) =>
  gqlClient
    .mutation(
      `mutation addMultipleMeals($date: String!, $entities: [MealEntity]) {
        addMultipleMeals(date: $date, entities: $entities) {
            entity_id
            qty: quantity
            __typename
        }
    }`,
      { date, entities }
    )
    .catch(() => null)

export const removeProductFromCart = ({
  orderDate,
  entity_id,
  quantity,
  withRelatedMeal = false
}) =>
  gqlClient.mutation(
    `mutation removeProductFromCart($date: String!, $entity_id: Int!, $quantity: Int!, $withRelatedMeal: Boolean) {
      deleteMeal(date: $date, entity_id: $entity_id, quantity: $quantity, withRelatedMeal: $withRelatedMeal){
          entity_id,
          qty: quantity
        }
    }
    `,
    { date: orderDate, entity_id, quantity, withRelatedMeal }
  )

export const emptyCart = orderDate =>
  gqlClient.mutation(
    `mutation deleteCart($date: String!) {
      deleteCart(date: $date)
  }
    `,
    { date: orderDate }
  )
