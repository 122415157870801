import React from 'react'
import { useLocation } from 'react-router-dom'
import { CuiIcon, CuiSegment } from 'front-lib'
import { useTranslation } from 'react-i18next'

import { RoutePaths } from '../../../../routes/routePaths'
import { getRouteName } from 'src/utils/getRouteName'
import { isMobileDevice } from '../../../../utils/utils'

const HeaderMobile = ({
  toggleUserMenu,
  extraDeliveryDisabled,
  handleToggleSchedulePanel
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isMobile = isMobileDevice()

  const isOrdersPage = location.pathname === RoutePaths.ORDERS

  // In mobile, when you show a product, you have an arrow that overlaps the header
  const hasToHideHeader =
    isMobile && location.pathname.startsWith(RoutePaths.PRODUCTS)
  if (hasToHideHeader) {
    return null
  }

  return (
    <header className="cui-header dark">
      <button onClick={toggleUserMenu}>
        <CuiIcon name="menu" />
      </button>
      <CuiSegment color="light" className="cui-header__title">
        {/* TODO: this has to be send by the router to the page as a prop as 'header.tab.foo' */}
        {getRouteName(location.pathname)}
      </CuiSegment>
      <CuiSegment color="light">
        {isOrdersPage && (
          <button
            onClick={handleToggleSchedulePanel}
            disabled={extraDeliveryDisabled}
            className="cui-segment-button cui-header__extra-delivery">
            +&nbsp;
            <span className="cui-header__extra-delivery__text">
              {t('header.add_extra_delivery.mobile')}
            </span>
          </button>
        )}
      </CuiSegment>
    </header>
  )
}

export default HeaderMobile
