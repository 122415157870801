import {
  put,
  call,
  takeEvery,
  all,
  takeLatest,
  select
} from 'redux-saga/effects'
import { isNil, not, equals, contains } from 'ramda'
import moment from 'moment'
import uuidv4 from 'uuid/v4'

import { cookUnityUuid } from '../../utils/browserCookies'
import {
  getAddress,
  getCards,
  getUserData,
  getUUID,
  getCredentials,
  getCoupon,
  getDiets,
  getExperiment
} from '../reducers/signup'
import {
  INIT_SIGN_UP,
  INIT_SIGN_UP_SUCCESS,
  INIT_SIGN_UP_FAILURE,
  APPLY_COUPON_INIT,
  SIGNUP_LOGIN_INIT,
  SIGNUP_LOGIN_SUCCESS,
  SIGNUP_LOGIN_FAILURE,
  PUBLIC_ADD_ADDRESS_INIT,
  PUBLIC_ADD_ADDRESS_SUCCESS,
  PUBLIC_ADD_ADDRESS_FAILURE,
  PUBLIC_ADD_CARD_INIT,
  PUBLIC_ADD_CARD_SUCCESS,
  PUBLIC_ADD_CARD_FAILURE,
  CREATE_SIGNUP_INIT,
  CREATE_SIGNUP_SUCCESS,
  CREATE_SIGNUP_FAILURE,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  SIGN_UP_LOG,
  SIGN_UP_LOG_SUCCESS,
  SIGN_UP_LOG_FAILURE,
  SET_UUID
} from '../actions/types'
import { GQL } from '../services'

export function* getInitSignup({ payload }) {
  try {
    const signupData = yield call(GQL.getSignupData, payload.zipcode)
    const mealPlansData = yield call(GQL.getMealPlans, payload.zipcode)
    const deliveryDaysData = yield call(GQL.getDeliveryDays, payload.zipcode)

    if (not(isNil(payload.coupon))) {
      yield put({
        type: APPLY_COUPON_INIT,
        payload: payload.coupon
      })
    }

    const upcomingDays = signupData.upcomingDays
      ? signupData.upcomingDays
          .filter(
            day =>
              day.available && // Day has to be available
              moment().isBefore(
                moment(day.cutoff.time).subtract(12, 'hours')
              ) && // any day, cut off 12 hours before
              (moment(day.date).day() !== 5 ||
                moment().isBefore(
                  moment(day.cutoff.time).subtract(36, 'hours')
                )) // just on fridays, cut off 36 before
          )
          .map(day => ({
            ...day,
            day: moment(day.displayDate).day(),
            label: moment(day.displayDate).format('dddd, MMM DD')
          }))
      : []

    // Select first sunday by default
    let selectedDate = upcomingDays.sort((d1, d2) =>
      d1.day < d2.day ? -1 : 1
    )[0] // Always choose as default the first day of the week

    yield put({
      type: INIT_SIGN_UP_SUCCESS,
      payload: {
        ...signupData,
        mealPlans: {
          ...mealPlansData,
          featuredPlans: mealPlansData.featuredPlans.reverse()
        },
        deliveryDays: [...deliveryDaysData.deliveryDays.deliveryDays],
        upcomingDays: upcomingDays,
        selectedPlan: '',
        timeslot: {
          start: signupData.timeslots[0].start,
          end: signupData.timeslots[0].end,
          label: signupData.timeslots[0].label
        },
        days: selectedDate ? [selectedDate.day] : [],
        startDay: selectedDate || {}
      }
    })
  } catch (error) {
    yield put({
      type: INIT_SIGN_UP_FAILURE,
      error
    })
  }
}

export function* signupLogin({ payload }) {
  const { email, password, firstname, lastname } = payload
  try {
    const signupLoginData = yield call(GQL.loginLegacyUser, {
      email,
      password,
      firstname,
      lastname
    })
    if (signupLoginData.error) throw signupLoginData
    yield put({
      type: SIGNUP_LOGIN_SUCCESS,
      payload: { ...signupLoginData, email, password, firstname, lastname }
    })
  } catch (error) {
    yield put({
      type: SIGNUP_LOGIN_FAILURE,
      error
    })
  }
}

export function* publicAddAddress({ payload }) {
  try {
    const { email, password, addressInfo } = payload
    const publicAddAddressData = yield call(GQL.publicAddAddress, {
      email,
      password,
      addressInfo
    })
    if (publicAddAddressData.error) throw publicAddAddressData
    yield put({
      type: PUBLIC_ADD_ADDRESS_SUCCESS,
      payload: {
        ...publicAddAddressData
      }
    })
  } catch (error) {
    yield put({
      type: PUBLIC_ADD_ADDRESS_FAILURE,
      error
    })
  }
}

export function* publicAddCard({ payload }) {
  try {
    const { email, password, cardInfo } = payload
    const publicAddCardData = yield call(GQL.publicAddCard, {
      email,
      password,
      cardInfo
    })
    if (publicAddCardData.error) throw publicAddCardData
    yield put({
      type: PUBLIC_ADD_CARD_SUCCESS,
      payload: { ...publicAddCardData }
    })
    yield put({
      type: CREATE_SIGNUP_INIT
    })
  } catch (error) {
    yield put({
      type: PUBLIC_ADD_CARD_FAILURE,
      error
    })
  }
}

export function* createUser() {
  try {
    const {
      diet,
      days,
      tastes,
      delivery_options,
      timeslot,
      startDay,
      selectedPlan,
      flow,
      dietaryPreferences,
      dietaryRestrictions,
      utm
    } = yield select(getUserData)
    const { id: cardID } = yield select(getCards)
    const { id: addressID } = yield select(getAddress)
    const { email, password, firstname, lastname } = yield select(
      getCredentials
    )
    const { couponCode } = yield select(getCoupon)
    const diets = yield select(getDiets)
    const experiment = yield select(getExperiment)

    const _diet = diet || ''
    const dietName = _diet.split(' ')
    const filtered = diets.find(diet => diet.name === dietName[0])
    const dietID = filtered ? [filtered.id] : []
    const subscriptionCharge = experiment === 'sc' ? true : false

    const userMutation = {
      name: `${firstname} ${lastname}`,
      plan_variety: equals(flow, 'eat-everything'),
      stripe_id: cardID,
      address_id: addressID,
      start_date: startDay.displayDate,
      time_start: timeslot.start,
      time_end: timeslot.end,
      plan: selectedPlan.id,
      diets: dietID,
      utm: utm,
      dietaryPreferences: dietaryPreferences.map(preference => ({
        id: preference,
        strict: contains(preference, dietaryRestrictions)
      })),
      tastes,
      days,
      delivery_options,
      coupon: couponCode
    }
    const createUser = yield call(
      GQL.createSignup,
      email,
      password,
      subscriptionCharge,
      userMutation
    )
    if (createUser.error) throw createUser
    yield put({
      type: CREATE_SIGNUP_SUCCESS,
      payload: { ...createUser }
    })
  } catch (error) {
    yield put({
      type: CREATE_SIGNUP_FAILURE,
      error
    })
  }
}

export function* applyCoupon({ payload }) {
  try {
    const couponData = yield call(GQL.applyCouponCode, payload)
    if (isNil(couponData.coupon)) {
      throw new Error('Invalid Coupon')
    }
    yield put({
      type: APPLY_COUPON_SUCCESS,
      payload: { ...couponData.coupon, couponCode: payload }
    })
  } catch (error) {
    yield put({
      type: APPLY_COUPON_FAILURE,
      error: 'Invalid Coupon'
    })
  }
}

export function* sendSignUpLog({ payload }) {
  try {
    let uuid = yield select(getUUID)

    if (uuid === null) {
      // create a unique id for this user
      uuid = cookUnityUuid
        ? cookUnityUuid
        : uuidv4()
            .substring(0, 6)
            .toUpperCase()
      yield put({
        type: SET_UUID,
        uuid
      })
    }

    const newPayload = {
      uuid,
      type: 'signup log',
      event: payload.event,
      warning: payload.warning,
      data: payload.data
    }

    yield call(GQL.sendSignUpLog, newPayload)
    yield put({
      type: SIGN_UP_LOG_SUCCESS
    })
  } catch (error) {
    yield put({
      type: SIGN_UP_LOG_FAILURE,
      error: 'Invalid'
    })
  }
}

export default function* rootInitialSaga() {
  yield all([
    takeEvery(INIT_SIGN_UP, getInitSignup),
    takeLatest(SIGNUP_LOGIN_INIT, signupLogin),
    takeLatest(PUBLIC_ADD_ADDRESS_INIT, publicAddAddress),
    takeLatest(PUBLIC_ADD_CARD_INIT, publicAddCard),
    takeLatest(CREATE_SIGNUP_INIT, createUser),
    takeLatest(APPLY_COUPON_INIT, applyCoupon),
    takeEvery(SIGN_UP_LOG, sendSignUpLog)
  ])
}
