import './UserMenu.scss'
import React, { useRef } from 'react'
import { CuiFabButton, CuiIcon, CuiLogo } from 'front-lib'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  isEconomicPlanSelector,
  isFlexPlanSelector
} from 'src/redux-api-bridge/selectors'

import { getInitialsSelector, getUserFirstNameSelector } from '../../selectors'
import tracking from '../../../../shared/tracking'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { getMenuLinks } from './MenuLinks'

const UserMenu = ({ isMenuOpen, handleCloseMenu }) => {
  const { t } = useTranslation()

  const initials = useSelector(getInitialsSelector)
  const userFirstName = useSelector(getUserFirstNameSelector)
  const isFlexPlan = useSelector(isFlexPlanSelector)
  const notEligibleToReferral = useSelector(isEconomicPlanSelector)
  const userMenuRef = useRef()
  useOnClickOutside(userMenuRef, handleCloseMenu)

  if (!isMenuOpen) {
    return null
  }

  const onMenuTabClick = (event, link) => {
    tracking.track('User Menu', {
      category: 'User Menu',
      action: `click on ${t(link.label)}`
    })
    handleCloseMenu(event)
  }

  return (
    <div className="cookunity__user_menu" ref={userMenuRef}>
      <div className="menu_header">
        <CuiIcon name="close" onClick={handleCloseMenu} />
        <CuiLogo color="light" />
      </div>
      <div className="menu_section user_info">
        <CuiFabButton showBorder size="large">
          {initials}
        </CuiFabButton>
        <p className="greeting">
          {`${t('header.user_menu.hi_again')}${userFirstName ? ',' : ''}`}
          <br />
          {userFirstName && <span className="user_name">{userFirstName}!</span>}
        </p>
      </div>

      <div className="menu_section">
        {getMenuLinks(isFlexPlan).map(link => {
          if (link.pathname === '/referral' && notEligibleToReferral) {
            return null
          }
          if (!link.pathname) {
            return (
              <button
                className={'menu_item'}
                onClick={event => onMenuTabClick(event, link)}>
                <a
                  key={link.label}
                  href={link.url}
                  className={'menu_item_link'}>
                  <CuiIcon name={link.icon} /> {t(link.label)}
                </a>
              </button>
            )
          }
          return (
            <Link
              key={link.label}
              className={'menu_item'}
              to={link.pathname}
              onClick={event => onMenuTabClick(event, link)}>
              <CuiIcon name={link.icon} /> {t(link.label)}
            </Link>
          )
        })}
      </div>

      <div className="menu_section">
        <Link className="menu_item logout" to="/logout">
          {t('header.user_menu.log_out')}
        </Link>
      </div>
    </div>
  )
}

export default UserMenu
