import { handleActions } from 'redux-actions'
import { uniqWith } from 'ramda'

import * as menuActions from '../menu/actions'
import { getUpcomingDays } from '../actions'

import * as orderActions from './actions'

const initialState = {
  active: null,
  data: {}
}

const orderInitialState = {
  confirmedItems: [],
  items: [],
  editingOrder: false,
  orderDetail: {
    qtyPlanMeals: 0,
    totalPlanPrice: 0.0,
    totalExtraMeals: 0.0,
    totalTaxes: 0.0,
    totalDeliveryFee: 0.0,
    subTotalOrder: 0.0,
    totalPromoDiscount: 0,
    totalOrder: 0
  }
}

const getOrder = (state, date) => state.data[date] || { ...orderInitialState }

const getActiveOrder = state => getOrder(state, state.active)

const updateOrder = (state, date, order) => {
  return {
    ...state,
    data: {
      ...state.data,
      [date]: {
        ...state.data[date],
        ...order
      }
    }
  }
}

const updateActiveOrder = (state, order) =>
  updateOrder(state, state.active, order)

const mealsAreEqual = (a, b) => a.entity_id === b.entity_id

export default handleActions(
  {
    [orderActions.syncProducts]: (state, action) => {
      const { menuMeals, cartProducts } = action.payload
      const meals = menuMeals
        .filter(_meal =>
          cartProducts.map(_product => _product.entity_id).includes(_meal.id)
        )
        .map(_meal => {
          const product = cartProducts.find(
            _product => _product.entity_id === _meal.id
          )
          return {
            ..._meal,
            qty: product.qty,
            quantity: product.qty
          }
        })
      const uniqueMeals = uniqWith(mealsAreEqual, meals)
      return updateActiveOrder(state, {
        editingOrder: true,
        items: [...uniqueMeals]
      })
    },
    [orderActions.addProduct]: (state, action) => {
      const { items } = getActiveOrder(state)
      const { product, quantity } = action.payload

      const itemIndex = items.findIndex(p => p.entity_id === product.entity_id)

      if (itemIndex === -1) {
        return updateActiveOrder(state, {
          editingOrder: true,
          items: [...items, { ...product, qty: quantity }]
        })
      }

      return updateActiveOrder(state, {
        editingOrder: true,
        items: [
          ...items.slice(0, itemIndex),
          { ...items[itemIndex], qty: items[itemIndex].qty + quantity },
          ...items.slice(itemIndex + 1)
        ]
      })
    },
    [orderActions.removeProduct]: (state, { payload }) => {
      const { items } = getActiveOrder(state)
      const { product, quantity } = payload

      const found = items.find(p => product.entity_id === p.entity_id)

      if (found) {
        if (quantity >= found.qty) {
          return updateActiveOrder(state, {
            editingOrder: true,
            items: items.filter(p => product.entity_id !== p.entity_id)
          })
        } else {
          return updateActiveOrder(state, {
            editingOrder: true,
            items: items.map(p => {
              if (product.entity_id === p.entity_id) {
                return {
                  ...p,
                  qty: p.qty - quantity
                }
              }
              return p
            })
          })
        }
      }

      return state
    },
    [menuActions.resetUserMenu]: () => initialState,
    [orderActions.clearOrder]: state =>
      updateActiveOrder(state, { items: [], editingOrder: true }),
    [orderActions.discardOrderChanges]: state =>
      updateActiveOrder(state, {
        items: getActiveOrder(state).confirmedItems,
        editingOrder: false
      }),
    [orderActions.getOrderDetail]: (state, { payload }) => {
      return updateActiveOrder(state, {
        orderDetail: payload
      })
    },
    [getUpcomingDays.succeed.type]: (state, { payload }) => {
      const { upcomingDays } = payload
      state = {
        ...state,
        data: {}
      }
      if (state.active) {
        state.data[state.active] = orderInitialState
      }

      upcomingDays.forEach(upcomingDay => {
        const cart = upcomingDay.cart
        if (cart.length > 0) {
          const orderDate = upcomingDay.date
          const items = cart.map(item => {
            return {
              ...item.product,
              entity_id: +item.product.id,
              qty: item.qty
            }
          })
          state = updateOrder(state, orderDate, {
            ...orderInitialState,
            items
          })
        }
      })

      return state
    },
    [menuActions.getUserMenuSuccess]: (state, action) => {
      const {
        menu: { categories },
        date: orderDate
      } = action.payload

      const order = getOrder(state, orderDate)

      order.confirmedItems = [].concat(
        ...categories.map(c => c.meals.filter(m => m.qty >= 1))
      )

      if (order.confirmedItems.length) {
        order.items = [...order.confirmedItems]
      } else if (order.items.length > 0) {
        order.items = [].concat(
          ...categories.map(c =>
            c.meals
              .filter(product => {
                return order.items.find(
                  item => item.entity_id === product.entity_id
                )
              })
              .map(product => {
                const itemIndex = order.items.findIndex(
                  item => item.entity_id === product.entity_id
                )

                return {
                  ...order.items[itemIndex],
                  ...product,
                  qty: order.items[itemIndex].qty,
                  stock: product.stock,
                  specifications_detail: product.specifications_detail || []
                }
              })
          )
        )
      }

      order.items = uniqWith(mealsAreEqual, order.items)
      order.confirmedItems = uniqWith(mealsAreEqual, order.confirmedItems)

      return {
        ...updateOrder(state, orderDate, order),
        active: orderDate
      }
    }
  },
  initialState
)
