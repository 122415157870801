import * as Sentry from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum'
import ReactGA from 'react-ga'

import history from './shared/history'
import { cookUnityUuid } from './utils/browserCookies'
import { env, ENVS } from './constants/env'

const enableTools = env === ENVS.prod || env === ENVS.qa

if (enableTools) {
  Sentry.init({
    dsn: 'https://a72ca73c2074488bbe5aa2aaa79c64a6@sentry.io/1234987',
    debug: env !== 'production',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: env
  })

  // eslint-disable-next-line import/namespace
  Sentry.configureScope(scope => {
    scope.setUser({ user_anonymous_id: cookUnityUuid })
  })

  // Capture 404's https://docs.sentry.io/platforms/javascript/troubleshooting/#capturing-resource-404s
  document.body.addEventListener(
    'error',
    event => {
      if (!event.target) return

      if (event.target.tagName === 'IMG') {
        // eslint-disable-next-line import/namespace
        Sentry.captureMessage(
          `Failed to load image: ${event.target.src}`,
          'warning'
        )
      } else if (event.target.tagName === 'LINK') {
        // eslint-disable-next-line import/namespace
        Sentry.captureMessage(
          `Failed to load css: ${event.target.href}`,
          'warning'
        )
      }
    },
    true // useCapture - necessary for resource loading errors
  )

  datadogRum.init({
    applicationId: 'd09b2136-e4b9-43a8-a49c-b8a5e4d3bfff',
    clientToken: 'pub60a3538f9e52a9b769f387a1d60282db',
    site: 'datadoghq.com',
    service: 'subscription-front',
    env,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true
  })
}

ReactGA.initialize('UA-46494018-1')
history.listen(location => {
  ReactGA.pageview(location.pathname + location.hash)
})

window.getAppVersion = () => {
  console.info(process.env.REACT_APP_VERSION)
}
