import './MyPlanPage.scss'

import { CuiButton, CuiIcon, CuiSelect, CuiTag } from 'front-lib'
import Form, { ButtonRow, Col, Grid, Label } from '../../components/Form'
import React, { FC, useEffect, useMemo, useState } from 'react'
import SettingsCard, { SettingSection } from '../../components/SettingsCard'
import {
  cleanPausePlanStatus,
  getSubscriptionPlans,
  getUserData,
  pausePlan
} from 'src/redux-api-bridge'
import {
  getExpireDateSelector,
  getSubscriptionPlanSelector,
  getUserSelector,
  myAccountIsLoadingSelector
} from 'src/modules/MyAccount/selectors'
import {
  getUpComingOrders,
  planIsLoading as planIsLoadingSelector
} from './selectors'
import { useDispatch, useSelector } from 'react-redux'

import Analytics from '../../utils/analytics'
import { CancelSubscription } from './components/CancelSubscription/CancelSubscription'
import { ConfirmationPlanChangeModalDesktop } from './components/MyFlexModePlan/components/confirmationPlanChange/ConfirmationPlanChangeModalDesktop'
import { ConfirmationPlanChangeModalMobile } from './components/MyFlexModePlan/components/confirmationPlanChange/ConfirmationPlanChangeModalMobile'
import { EconomicPlanModal } from './components/EconomicPlanModal/EconomicPlanModal'
import { Formik } from 'formik'
import Header from 'src/components/Header/Header'
import Info from '../../assets/icons/info.png'
import Loader from 'src/common/Loader'
import ModalFeedback from './components/ModalFeedback'
import { MyFlexModePlan } from './components/MyFlexModePlan/MyFlexModePlan'
import { RoutePaths } from 'src/routes/routePaths'
import View from '../../modules/MyAccount/components/View'
import { formatNumber } from '../../utils/utils'
import moment from 'moment'
import { toast } from 'react-toastify'
import { toaster } from 'src/utils/toaster'
import tracking from '../../shared/tracking'
import { useHistory } from 'react-router-dom'
import { useMobileDevice } from '../../hooks/useMobileDevice'
import { useQuery } from '../../hooks/useQuery'
import { useTranslation } from 'react-i18next'
import {
  isEconomicPlanSelector,
  isFlexPlanSelector
} from 'src/redux-api-bridge/selectors'
import { isIOSApp } from 'src/utils/utils'
import { PauseSubscription } from './components/PauseSubscription/PauseSubscription'
import { useFlag } from '@unleash/proxy-client-react'
import {
  FF_PAUSE_PLAN_REMOVE_ONE_WEEK,
  FF_PAUSE_FLOW_ENABLED
} from 'src/constants/feature-flags'

const defaultPauseOptions = [
  { label: '1 week', value: 1 },
  { label: '2 weeks', value: 2 },
  { label: '3 weeks', value: 3 },
  { label: '4 weeks', value: 4 },
  { label: '5 weeks', value: 5 },
  { label: '6 weeks', value: 6 },
  { label: '7 weeks', value: 7 },
  { label: '8 weeks', value: 8 }
]

export const MyPlanPage: FC = () => {
  const { t } = useTranslation()
  const isPauseFlowEnabled = useFlag(FF_PAUSE_FLOW_ENABLED)

  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [showEconomicPlanModal, setShowEconomicPlanModal] = useState(false)
  const [showPausePlanSection, setShowPausePlanSection] = useState(false)
  const [
    showConfirmationPlanChangeModal,
    setShowConfirmationPlanChangeModal
  ] = useState(false)
  const [pauseDuration, setPauseDuration] = useState(0)

  const removeFirstWeekFlag = useFlag(FF_PAUSE_PLAN_REMOVE_ONE_WEEK)

  const pauseOptions = useMemo(() => {
    if (removeFirstWeekFlag) {
      return defaultPauseOptions.slice(1)
    }

    return defaultPauseOptions
  }, [removeFirstWeekFlag])

  const user = useSelector(getUserSelector)
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)
  const isEconomicPlan = useSelector(isEconomicPlanSelector)
  const isFlexPlan = useSelector(isFlexPlanSelector)
  const expireDate = useSelector(getExpireDateSelector)
  const myAccountIsLoading = useSelector(myAccountIsLoadingSelector)
  const planIsLoading = useSelector(planIsLoadingSelector)
  const errorPausePlan = useSelector<any>(
    state => state?.api?.pausePlanStatus?.error ?? false
  )
  const successPausePlan = useSelector<any>(
    state => state?.api?.pausePlanStatus?.succeed || false
  )
  const successChangePlan = useSelector<any>(
    state => state?.api?.setSubscriptionPlanStatus?.succeeded
  )
  const upcomingOrders = useSelector(getUpComingOrders)
  const dispatch = useDispatch()
  const isMobileDevice = useMobileDevice()
  const history = useHistory()
  const query = useQuery()

  const deliveriesPerWeek = subscriptionPlan?.deliveries ?? 1
  const mealsPerDelivery = subscriptionPlan?.mealsPerDelivery ?? 1
  const price = subscriptionPlan?.price ?? ''
  const mealsPerWeek = mealsPerDelivery * deliveriesPerWeek
  const pricePerMeal = formatNumber(price / mealsPerWeek)
  const firstName = user?.firstname ?? ''
  const lastName = user?.lastname ?? ''
  const email = user?.email ?? ''
  const userFullName = [
    ...(firstName ? [firstName.trim()] : []),
    ...(lastName ? [lastName.trim()] : [])
  ].join(' ')
  const userEmail = email ? email.trim() : ''

  useEffect(() => {
    if (!user) {
      dispatch(getUserData())
    }
  }, [user, dispatch])

  useEffect(() => {
    dispatch(getSubscriptionPlans())
  }, [dispatch])

  useEffect(() => {
    if (showConfirmationPlanChangeModal && successChangePlan) {
      dispatch(getSubscriptionPlans())
    }
  }, [dispatch, successChangePlan, showConfirmationPlanChangeModal])

  useEffect(() => {
    dispatch(cleanPausePlanStatus())
  }, [dispatch])

  useEffect(() => {
    // Cancel page has a "pause plan" button whose rely on this page to pause the plan.
    if (query.get('pause-plan') !== null) {
      setShowPausePlanSection(true)
    }
  }, [query])

  useEffect(() => {
    if (!showPausePlanSection) {
      return
    }

    tracking.track('Pause Modal', {
      action: 'Open Modal'
    })
  }, [showPausePlanSection])

  useEffect(() => {
    if (successPausePlan || errorPausePlan) {
      const text = successPausePlan
        ? 'The plan was paused successfully.'
        : 'Error while pausing the plan.'
      toaster.default(text, {
        icon: <CuiIcon name="allDone" />,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false
      })
    }
  }, [errorPausePlan, successPausePlan])

  function toggleFeedbackModal() {
    setShowFeedbackModal(prev => !prev)
  }

  function onPausePlanClick(reason, duration, mealsPerWeek) {
    dispatch(pausePlan(duration, reason))

    Analytics.track('Pause-Plan', {
      plan_name: getNamePlan(mealsPerWeek),
      is_paused: true,
      pause_duration: `${duration} Weeks`,
      reason: reason
    })
  }

  function requestFeedbackBeforePausePlan(values, { setSubmitting }) {
    const duration = values.duration.value
    setPauseDuration(duration)
    toggleFeedbackModal()
    setSubmitting(false)
    setShowPausePlanSection(false)
  }

  function onChangePlan() {
    tracking.track('Change Plan')
    history.push(RoutePaths.CHANGE_PLAN)
  }

  function onCancel() {
    setShowPausePlanSection(false)
  }

  function getNamePlan(mealsPerWeek) {
    return `${mealsPerWeek} ${
      mealsPerWeek > 1 ? t('pages.my_plan.meals') : t('pages.my_plan.meal')
    }`
  }

  function getPlanSubtitle(pricePerMeal, deliveriesPerWeek) {
    return `$${pricePerMeal} per Meal  ·  ${deliveriesPerWeek} ${
      deliveriesPerWeek > 1
        ? t('pages.my_plan.deliveries')
        : t('pages.my_plan.delivery')
    } `
  }

  function isPlanWithDeliveryFee(plan) {
    return typeof plan.deliveryFee === 'number'
  }

  if (myAccountIsLoading) {
    return <Loader />
  }

  if (isFlexPlan) {
    return (
      <MyFlexModePlan
        setShowConfirmationPlanChangeModal={setShowConfirmationPlanChangeModal}
      />
    )
  }

  const getPauseButton = (values, isSubmitting, isValid) => {
    const shourldRedirectToBrightback = values?.duration?.value >= 4
    if (isPauseFlowEnabled && shourldRedirectToBrightback) {
      return <PauseSubscription pauseDuration={values.duration} />
    }

    return (
      <CuiButton type="submit" disabled={isSubmitting || !isValid}>
        {t('pages.my_plan.pause')}
      </CuiButton>
    )
  }

  const ConfirmationPlanChangeModal = isMobileDevice
    ? ConfirmationPlanChangeModalMobile
    : ConfirmationPlanChangeModalDesktop

  return (
    <>
      <Header />
      <ModalFeedback
        isOpen={showFeedbackModal}
        onRequestClose={toggleFeedbackModal}
        doPause={onPausePlanClick}
        pauseDuration={pauseDuration}
        upcomingOrders={upcomingOrders}
        mealsPerWeek={mealsPerWeek}
      />

      {showEconomicPlanModal && (
        <EconomicPlanModal
          showEconomicPlanModal={showEconomicPlanModal}
          setShowEconomicPlanModal={setShowEconomicPlanModal}
        />
      )}

      {showConfirmationPlanChangeModal && (
        <ConfirmationPlanChangeModal
          showConfirmationPlanChangeModal={showConfirmationPlanChangeModal}
          setShowConfirmationPlanChangeModal={
            setShowConfirmationPlanChangeModal
          }
        />
      )}

      <div className="cookunity__account_settings_container">
        {!isMobileDevice && (
          <div className="cookunity__account_settings_header">
            <>
              <h1 className="title">{t('pages.my_plan.my_plan')}</h1>
              <p className="subtitle">
                <strong>{userFullName},</strong> {userEmail} |{' '}
                <strong>
                  {`${mealsPerWeek} ${
                    mealsPerWeek > 1
                      ? t('pages.my_plan.meals')
                      : t('pages.my_plan.meal')
                  }, ${deliveriesPerWeek} ${
                    deliveriesPerWeek > 1
                      ? t('pages.my_plan.deliveries')
                      : t('pages.my_plan.delivery')
                  } · `}
                </strong>
              </p>
            </>
            {isEconomicPlan && (
              <div className="plan-tag-container">
                <CuiTag color="blue">
                  {`${
                    subscriptionPlan?.segmentId === 4
                      ? t('pages.my_plan.economic_meal_plan')
                      : t('pages.my_plan.economic_meal_plan_8.5')
                  }`}
                </CuiTag>
                {expireDate && (
                  <div className="expire-date">
                    {`${t('pages.my_plan.expiresOn')} ${moment(
                      expireDate
                    ).format('MM/DD/YY')}`}
                  </div>
                )}
                <div className="plan-info">
                  <img
                    src={Info}
                    className="cui-meal-info"
                    alt="info"
                    onClick={() =>
                      setShowEconomicPlanModal(showModal => !showModal)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="cookunity__account_settings">
          <View hideBack={true}>
            <SettingsCard
              hideTitle={!isMobileDevice}
              title={t('pages.my_plan.my_plan')}
              isLoading={planIsLoading}>
              {isMobileDevice && isEconomicPlan && (
                <div className="plan-tag-container">
                  <CuiTag color="blue">
                    {`${
                      subscriptionPlan?.segmentId === 4
                        ? t('pages.my_plan.economic_meal_plan')
                        : t('pages.my_plan.economic_meal_plan_8.5')
                    }`}
                  </CuiTag>
                  {expireDate && (
                    <div className="expire-date">
                      {`${t('pages.my_plan.expiresOn')} ${moment(
                        expireDate
                      ).format('MM/DD/YY')}`}
                    </div>
                  )}
                  <div className="plan-info">
                    <img
                      src={Info}
                      className="cui-meal-info"
                      alt="info"
                      onClick={() =>
                        setShowEconomicPlanModal(showModal => !showModal)
                      }
                    />
                  </div>
                </div>
              )}
              <SettingSection
                className="cookunity__my_plan_section"
                title={
                  isPlanWithDeliveryFee(subscriptionPlan) ? (
                    <span className="title_new_plan_meals">
                      {getNamePlan(mealsPerWeek)}
                      <span className="title_new_plan_meals_week">{` / ${t(
                        'pages.my_plan.week'
                      )}`}</span>
                    </span>
                  ) : (
                    <span className="title_new_plan_meals">
                      {getNamePlan(mealsPerWeek)}
                      <span className="title_new_plan_meals_week">{` / ${t(
                        'pages.my_plan.weekly'
                      )}`}</span>
                    </span>
                  )
                }
                subtitle={
                  isPlanWithDeliveryFee(subscriptionPlan) ? (
                    <span>
                      <span className="subtitle_new_plan_price">
                        {`$${pricePerMeal} ${t('pages.my_plan.per_meal')}`}
                      </span>
                      <span className="subtitle_new_plan_delivery">
                        {` + ${t('pages.my_plan.taxes_and_fees')}`}
                      </span>
                    </span>
                  ) : (
                    getPlanSubtitle(pricePerMeal, deliveriesPerWeek)
                  )
                }
                withActionButton
                buttonLabel={
                  <>
                    <CuiIcon name="sync" /> {t('pages.my_plan.change_plan')}
                  </>
                }
                onActionClick={onChangePlan}
              />
              {showPausePlanSection ? (
                <Formik
                  isInitialValid
                  initialValues={{
                    duration: pauseOptions[0]
                  }}
                  onSubmit={requestFeedbackBeforePausePlan}>
                  {({ isSubmitting, values, setFieldValue, isValid }) => (
                    <Form className="">
                      <h3 className="subtitle">
                        {t('pages.my_plan.pause_plan')}
                      </h3>
                      <Label strong={undefined} inline={undefined} className="">
                        {t('pages.my_plan.select_a_duration')}
                      </Label>
                      <Grid>
                        <Col>
                          <CuiSelect
                            value={values.duration}
                            isDisabled={isSubmitting}
                            options={pauseOptions}
                            onChange={selection => {
                              setFieldValue('duration', selection, true)
                            }}
                          />
                        </Col>
                      </Grid>
                      <ButtonRow className="">
                        {getPauseButton(values, isSubmitting, isValid)}
                        <CuiButton fill="outline" onClick={onCancel}>
                          {t('pages.my_plan.cancel')}
                        </CuiButton>
                      </ButtonRow>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="cookunity__setting-section-container">
                  <div className="cookunity__setting-section-text-container">
                    <span className="cookunity__setting-section-title">
                      {t('pages.my_plan.need_a_break')}
                    </span>
                    <span className="cookunity__setting-section-subtitle">
                      {t('pages.my_plan.traveling')}
                    </span>
                  </div>
                  <div className="cookunity__setting-section-buttons-container">
                    <button
                      className="cookunity__setting-section-buttons-pause"
                      onClick={() => setShowPausePlanSection(true)}>
                      {t('pages.my_plan.pause_plan_action')}
                    </button>
                    {!isIOSApp() && <CancelSubscription />}
                  </div>
                </div>
              )}
            </SettingsCard>
          </View>
        </div>
      </div>
    </>
  )
}
