import { handleActions } from 'redux-actions'
import { getProfilePreferences, getSelectedProfilePreference } from './helpers'

import * as filtersActions from './actions'

const initialState = {
  filterTypes: null,
  diets: [],
  preferences: [],
  proteins: [],
  cuisines: [],
  chef: [],
  sortBy: null,
  selectedProfilePreference: null,
  isSticky: false,
  isDefaultMenuByFilter: true,
  sortingTypes: null,
  filteredMeals: [],
  selectedCategoryId: 0,
  selectedCategoryLabel: 'meals',
  quickFilters: [],
  quickFilterSelected: null,
  searchInput: '',
  menuSelected: null,
  tabSelected: null,
  menuItems: null
}

export default handleActions(
  {
    [filtersActions.getFilterTypesSuccess]: (state, action) => ({
      ...state,
      filterTypes: { ...action.payload }
    }),
    [filtersActions.clearAllFilters]: state => {
      return {
        ...state,
        diets: [],
        preferences: [],
        proteins: [],
        cuisines: [],
        chef: [],
        quickFilterSelected: null,
        searchInput: '',
        selectedProfilePreference: null
      }
    },
    [filtersActions.setFilters]: (state, action) => {
      const { useAllergens, ...rest } = action.payload

      return {
        ...state,
        ...rest,
        ...getSelectedProfilePreference(state, rest, useAllergens)
      }
    },
    [filtersActions.setChefFilter]: (state, action) => ({
      ...state,
      chef: action.payload
    }),
    [filtersActions.setSortBy]: (state, action) => ({
      ...state,
      sortBy: action.payload
    }),
    [filtersActions.setProfilePreferenceFilter]: (state, action) => {
      const { profile, preferences, useAllergens } = action.payload
      const { restrictions, diets = [], tastes = [] } = profile || {}

      const proteins = state.filterTypes.proteins

      const mappedTastes = tastes.map(
        taste => proteins.find(protein => protein.name === taste.name) || {}
      )

      const profilePreferences = getProfilePreferences(
        preferences,
        restrictions,
        useAllergens
      )

      return {
        ...state,
        selectedProfilePreference: profile,
        diets,
        proteins: mappedTastes,
        preferences: profilePreferences
      }
    },
    [filtersActions.clearSortBy]: state => {
      const sortingTypes = state.sortingTypes

      return {
        ...state,
        sortBy: sortingTypes
          ? [{ ...sortingTypes[0], filterBy: sortingTypes[0].name }]
          : null
      }
    },
    [filtersActions.setSticky]: (state, action) => ({
      ...state,
      isSticky: action.payload
    }),
    [filtersActions.setIsDefaultMenuBy]: (state, action) => ({
      ...state,
      isDefaultMenuByFilter: action.payload
    }),
    [filtersActions.getSortingTypesSuccess]: (state, action) => {
      const sortingTypes = action.payload.sortingTypes

      return {
        ...state,
        sortingTypes,
        sortBy: [{ ...sortingTypes[0], filterBy: sortingTypes[0].name }]
      }
    },
    [filtersActions.setFilteredMeals]: (state, action) => {
      return {
        ...state,
        filteredMeals: action.payload.filteredMeals
      }
    },
    [filtersActions.setCategoryId]: (state, action) => {
      return {
        ...state,
        selectedCategoryId: action.payload.id
      }
    },
    [filtersActions.setCategoryLabel]: (state, action) => {
      return {
        ...state,
        selectedCategoryLabel: action.payload.label
      }
    },
    [filtersActions.getQuickFiltersSuccess]: (state, action) => {
      return {
        ...state,
        quickFilters: action.payload.filters
      }
    },
    [filtersActions.getQuickFiltersFail ||
    filtersActions.getQuickFiltersStart]: state => {
      return {
        ...state,
        quickFilters: []
      }
    },
    [filtersActions.setMenuSelected]: (state, action) => {
      return {
        ...state,
        menuSelected: action.payload
      }
    },
    [filtersActions.setTabSelected]: (state, action) => {
      return {
        ...state,
        tabSelected: action.payload
      }
    },
    [filtersActions.setMenuItems]: (state, action) => {
      const { menuSelected, tabSelected } = state
      return {
        ...state,
        menuItems: action.payload,
        ...(!menuSelected &&
          action.payload && { menuSelected: action.payload[0] }),
        ...(!tabSelected &&
          action.payload && { tabSelected: action.payload[0]?.tabs?.[0] })
      }
    }
  },
  initialState
)
