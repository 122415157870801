import * as R from 'ramda'

import { ADDONS_CATEGORIES } from 'src/modules/MyPlan/utils'
import { activeDeliveryDate, getUpcomingDays } from '../selectors'
import { createSelector } from 'reselect'
import { getMealsCount } from './utils'
import { getMenu } from '../menu/selectors'
import { buildImgUrl } from 'src/utils/utils'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const CHEF_IMG_URL_SOURCE = process.env.REACT_APP_CHEF_IMG_URL_SOURCE

const MEAT_TYPES = [
  'Beef',
  'Chicken',
  'Other fish',
  'Other meat',
  'Pork',
  'Salmon',
  'Seafood',
  'Vegan',
  'Vegetarian'
]

const getCategory = meal => {
  const meatTag =
    meal.specifications_detail &&
    meal.specifications_detail
      .filter(tag => tag && tag.label.trim())
      .find(tag => MEAT_TYPES.indexOf(tag) !== -1)
  const meatLabel =
    meal.meat_type || (typeof meatTag === 'undefined' ? meatTag : null)

  const meats = ['Duck', 'Lamb', 'Other meat', 'Beef', 'Pork']
  const seafood = [
    'Catfish',
    'Cod',
    'Crab',
    'Other fish',
    'Salmon',
    'Scallop',
    'Seafood',
    'Shrimp',
    'Snapper',
    'Sole',
    'Tilapia',
    'Trout',
    'Tuna',
    'fish '
  ]
  const poultry = ['Chicken', 'Turkey']
  const vegan = ['Vegan']
  const vegetarian = ['Vegetarian']

  if (meats.includes(meatLabel)) {
    return 'meat'
  } else if (seafood.includes(meatLabel)) {
    return 'seafood'
  } else if (poultry.includes(meatLabel)) {
    return 'poultry'
  } else if (vegan.includes(meatLabel)) {
    return 'vegan'
  } else if (vegetarian.includes(meatLabel)) {
    return 'vegetarian'
  }

  return null
}

const formatMealData = meal => {
  let imageUrl = (meal.image || '').replace('no_selection', '')
  if (imageUrl)
    imageUrl = buildImgUrl(IMG_URL_SOURCE, meal.image_path, imageUrl)
  return {
    ...meal,
    category: getCategory(meal),
    ...(!meal.hasOwnProperty('menu_category_id') && {
      menu_category_id: meal.category && meal.category.id
    }),
    ...(!meal.hasOwnProperty('menu_category_label') && {
      menu_category_label: meal.category && meal.category.label
    }),
    title: meal.name,
    subtitle: meal.short_description,
    imageUrl,
    magento_id: +meal.entity_id,
    reservedStock: +meal.qty,
    chefName: [
      ...[meal.chef_firstname ? meal.chef_firstname.trim() : ''],
      ...[meal.chef_lastname ? meal.chef_lastname.trim() : '']
    ]
      .join(' ')
      .trim(),
    chef: {
      id: meal.chef_id,
      firstname: meal.chef_firstname,
      lastname: meal.chef_lastname,
      image: CHEF_IMG_URL_SOURCE + meal.logopic
    },
    tags:
      meal.specifications_detail &&
      meal.specifications_detail.filter(tag => tag && tag.label.trim())
  }
}

const sortOrder = (m1, m2) => {
  // If its a m1 is meal is always 1
  if (-1 === ADDONS_CATEGORIES.indexOf(m1.category_id)) {
    if (-1 === ADDONS_CATEGORIES.indexOf(m2.category_id)) {
      return 0
    }
    return -1
  } else {
    if (1 === ADDONS_CATEGORIES.indexOf(m2.category_id)) {
      return 0
    }
    return 1
  }
}

export const getActiveOrderDate = state => state.orders.active

export const getActiveOrder = state =>
  state.orders.data[getActiveOrderDate(state)] || null

export const isActiveOrderConfirmed = state => {
  const activeOrder = getActiveOrder(state)
  if (activeOrder && activeOrder.confirmedItems.length) return true
  return false
}

export const isExtraDeliveryOrder = state => {
  const activeOrder = getActiveOrderDate(state)
  const upcomingDays = getUpcomingDays(state)
  const currentUpcomingDay = upcomingDays.find(
    upcomingDate => upcomingDate.date === activeOrder
  )
  return !currentUpcomingDay.scheduled
}

const getOrderItemsByKey = key =>
  createSelector(
    getActiveOrder,
    order => (order && order[key].map(formatMealData).sort(sortOrder)) || []
  )

export const getCurrentOrder = getOrderItemsByKey('items')
export const getConfirmedOrder = getOrderItemsByKey('confirmedItems')

export const getOrderWithStock = createSelector(
  getCurrentOrder,
  getConfirmedOrder,
  getMenu,
  (currentOrder, confirmedOrder, menu) => {
    const order = currentOrder.filter(item => {
      const isItemConfirmed = confirmedOrder.find(
        itemConfirmed => itemConfirmed.entity_id === item.entity_id
      )
      return (
        isItemConfirmed ||
        menu?.meals.find(meal => +item.id === +meal.id)?.stock > 0
      )
    })

    return order
  }
)

export const getOrderOutOfStock = createSelector(
  getCurrentOrder,
  getConfirmedOrder,
  (currentOrder, confirmedOrder) =>
    currentOrder.filter(item => {
      const isItemConfirmed = confirmedOrder.find(
        itemConfirmed => itemConfirmed.entity_id === item.entity_id
      )
      return !isItemConfirmed && item.stock <= 0
    })
)

export const getOrderMealsCountWithStock = createSelector(
  getOrderWithStock,
  items => getMealsCount(items)
)

export const getCarts = state => state.orders.data

export const shouldSaveToCart = createSelector(
  isActiveOrderConfirmed,
  activeDeliveryDate,
  isExtraDeliveryOrder,
  (isActiveOrderConfirmed, activeDeliveryDate, isExtraDeliveryOrder) => {
    if (
      isActiveOrderConfirmed ||
      activeDeliveryDate.skip ||
      isExtraDeliveryOrder
    )
      return false
    return true
  }
)

export const getQtyPlanMeals = state => {
  return R.path(['orderDetail', 'qtyPlanMeals'], getActiveOrder(state)) || 0
}
export const getTotalPlanMeals = state => {
  const subtotal = R.path(
    ['orderDetail', 'totalPlanPrice'],
    getActiveOrder(state)
  )
  return subtotal ? subtotal.toFixed(2) : 0.0
}
export const getTotalTaxes = state => {
  const taxes = R.path(['orderDetail', 'totalTaxes'], getActiveOrder(state))
  return taxes ? taxes.toFixed(2) : 0.0
}
export const getTotalDeliveryFee = state => {
  const deliveryFee = R.path(
    ['orderDetail', 'totalDeliveryFee'],
    getActiveOrder(state)
  )
  return deliveryFee ? deliveryFee.toFixed(2) : 0.0
}
export const getTotalExtraMeals = state => {
  const extra = R.path(
    ['orderDetail', 'totalExtraMeals'],
    getActiveOrder(state)
  )
  return extra ? extra.toFixed(2) : 0.0
}
export const getTotalDiscount = state => {
  const promo = R.path(
    ['orderDetail', 'totalPromoDiscount'],
    getActiveOrder(state)
  )
  return promo ? promo.toFixed(2) : 0.0
}
export const getTotalOrder = state => {
  const total = R.path(['orderDetail', 'totalOrder'], getActiveOrder(state))
  return total ? total.toFixed(2) : 0.0
}
