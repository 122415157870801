import moment from 'moment'
import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

import { selectScheduleSelectedDate } from 'src/redux-api-bridge/schedule/selectors'
import { ID_COLLECTION_THANKSGIVING, STORE_LA, STORE_NY } from './constants'
import { getDeliveryDates } from './utils'

export const getSubscriptionStatus = state =>
  pathOr(null, ['api', 'subscription', 'info', 'status'], state)

export const getSubscription = state =>
  pathOr(null, ['api', 'subscription', 'info'], state)

export const getRing = state =>
  pathOr(null, ['api', 'subscription', 'info', 'ring'], state)

export const getIsEligible = state =>
  pathOr(null, ['api', 'subscription', 'info', 'isEligible'], state)

export const getStore = state =>
  pathOr(null, ['api', 'subscription', 'info', 'store_id'], state)

export const getUpcomingOrderStatus = state =>
  pathOr({}, ['api', 'upcommingOrderStatus'], state)

export const getSubscriptionDataStatus = state =>
  pathOr({}, ['api', 'getSubscriptionDataStatus'], state)

export const getRescheduleStatus = state =>
  pathOr({}, ['api', 'rescheduleStatus'], state)

export const getSkipOrderStatus = state =>
  pathOr({}, ['api', 'skipOrderStatus'], state)

export const getUpcomingDays = state =>
  pathOr([], ['api', 'subscription', 'upcomingDays'], state)

export const debugMode = state => pathOr(false, ['app', 'debug'], state)

export const getProfilesInfo = state =>
  pathOr(null, ['api', 'subscription', 'info', 'profiles'], state)

export const getProfiles = createSelector(
  [getProfilesInfo],
  profiles => profiles
)

export const isLoading = createSelector(
  [getUpcomingOrderStatus, getSkipOrderStatus, getSubscriptionDataStatus],
  (upcomingOrderStatus, skipOrderStatus, subscriptionData) =>
    subscriptionData.inFlight ||
    upcomingOrderStatus.inFlight ||
    !upcomingOrderStatus.succeeded ||
    skipOrderStatus.inFlight
)

export const showChicagoBanner = createSelector([getStore], storeId => {
  const now = new Date()
  const from = new Date('03-11-2022')
  const to = new Date('04-10-2022')

  return storeId === 4 && now >= from && now <= to
})

export const upcomingDaysSelector = createSelector(
  [getUpcomingDays],
  upcomingDays =>
    upcomingDays.map(day => ({
      ...day,
      disabled: day.scheduled,
      orderCreationPending: !!day.order && +day.order.id === 0
    }))
)

export const isHungry = createSelector(
  [getRing],
  ring => ring && ring.shipping_service && ring.shipping_service === 'hungry'
)

export const isEligibleZeroOrders = createSelector(
  [getIsEligible],
  isEligible => isEligible && isEligible.type === 'ZERO_INVOICES'
)

export const getTrackingEventData = createSelector(
  [getSubscription],
  subscription => {
    if (subscription !== null)
      return {
        userId: subscription.id,
        magentoId: subscription.magento_id,
        userEmail: subscription.email
      }
  }
)

export const getUpcomingDeliveries = createSelector(
  [upcomingDaysSelector],
  upcomingDays =>
    upcomingDays
      .filter(
        day =>
          (day.order !== null ||
            (day.skip && day.scheduled) ||
            day.scheduled ||
            day.rescheduled) &&
          day.available
      )
      .slice(0, 6)
)

export const getDeliveryDatesSelector = createSelector(
  [getUpcomingDeliveries],
  upcomingDeliveries => getDeliveryDates(upcomingDeliveries)
)

export const getReschedulableDays = createSelector(
  [upcomingDaysSelector],
  upcomingDays => {
    return upcomingDays.filter(
      day => day.available && day.canEdit && !day.holidayMessage
    )
  }
)

export const reschedulableDaysFilteredBySelectedDateSelector = createSelector(
  [getReschedulableDays, selectScheduleSelectedDate],
  (reschedulableDays, selectedDate) => {
    const date = pathOr('', ['date'], selectedDate)
    const dateMoment = date ? moment(date) : moment()
    const startOfWeek = dateMoment.clone().startOf('week')
    const endOfWeek = dateMoment.clone().endOf('week')

    return reschedulableDays.filter(day => {
      if (day.date === dateMoment.format('YYYY-MM-DD')) {
        return true
      }
      const date = moment(day.date)
      return date.isSameOrAfter(startOfWeek) && date.isSameOrBefore(endOfWeek)
    })
  }
)

export const getDaysWithMenuAvailable = createSelector(
  [upcomingDaysSelector],
  upcomingDays =>
    upcomingDays.filter(
      day => day.available && !day.holidayMessage && day.menuAvailable
    )
)

export const getAvailableDeliveryDays = createSelector(
  [getDaysWithMenuAvailable, getUpcomingDeliveries],
  (daysWithMenuAvailable, upcomingDeliveries) => {
    const deliveries = upcomingDeliveries.length

    if (deliveries <= 1) {
      return []
    }

    const firstDelivery = moment(upcomingDeliveries[0].date)
    const lastDelivery = moment(upcomingDeliveries[deliveries - 1].date)

    return daysWithMenuAvailable.filter(
      day =>
        day.available &&
        day.canEdit &&
        !day.skip &&
        moment(day.date).isBetween(firstDelivery, lastDelivery, '()')
    )
  }
)

export const getMealsInDelivery = state =>
  pathOr(0, ['api', 'subscription', 'userPlan', 'mealsPerDelivery'], state)

export const orderConfirmedStatus = state => state.api.orderConfirmed

const getSettings = state =>
  pathOr([], ['api', 'subscription', 'settings'], state)

export const getModalsSettings = state => {
  const settings = getSettings(state)
  const modalsSettings = settings.reduce((accum, elem) => {
    if (elem.value === '1' && elem.key.includes('Modal')) {
      return [...accum, { key: elem.key }]
    }
    return accum
  }, [])
  return modalsSettings
}

export const getRedirectToOrderSetting = state => {
  const settings = getSettings(state)
  const redirectToOrderSetting = settings.find(
    setting => setting.key === 'redirectToOrder'
  )
  return redirectToOrderSetting
}

export const isRedirectToOrderEnabled = state => {
  const redirectToOrderSetting = getRedirectToOrderSetting(state)
  return (
    redirectToOrderSetting &&
    (redirectToOrderSetting.value === 'true' ||
      redirectToOrderSetting.value === '1')
  )
}

export const getExperimentsSettings = state => {
  const settings = getSettings(state)
  const experiments = settings.reduce((accum, elem) => {
    if (
      elem.value === '1' &&
      !elem.key.includes('Modal') &&
      !elem.key.includes('Exp/')
    ) {
      return [...accum, { key: elem.key }]
    }
    return accum
  }, [])
  return experiments
}

export const showUpdatePreferencesModal = createSelector(
  [getSettings],
  settings => {
    const showUpdatePreferencesModal = settings.find(
      i => i.key === 'ShowUpdatePreferencesModal'
    )
    return typeof showUpdatePreferencesModal === 'undefined'
  }
)

export const showRecommendationJourney = createSelector(
  [getSettings],
  settings => {
    const showRecommendationJourney = settings.find(
      i => i.key === 'showrecommendationjourney'
    )
    if (showRecommendationJourney && showRecommendationJourney.value === '1') {
      return true
    }
    return false
  }
)

export const getTrackingInfo = state =>
  pathOr(null, ['api', 'trackingInfo', 'payload'], state)

export const trackingInfoInFlight = state =>
  pathOr(true, ['api', 'trackingInfo', 'inFlight'], state)

export const getUserAddresses = state =>
  pathOr(null, ['api', 'subscription', 'info', 'addresses'], state)

export const getAddresses = createSelector([getUserAddresses], addresses => {
  if (addresses) {
    const defaultAddress = addresses.slice(0, 1).map(address => {
      const streetComponents =
        (address.street && address.street.split('\n')) || []
      const street = streetComponents.length > 0 ? streetComponents[0] : ''
      const apt = streetComponents.length > 1 ? streetComponents[1] : ''
      const { city, region } = address
      return `${street ? street : ''} ${apt ? apt : ''}, ${city}, ${region}`
    })

    return defaultAddress.length ? defaultAddress[0] : null
  }

  return null
})

export const getUserCreationDate = state =>
  pathOr(null, ['api', 'subscription', 'info', 'createdAt'], state)

export const userFirstWeek = createSelector([getUserCreationDate], date => {
  if (date) {
    return moment().diff(date, 'days') <= 7
  }
  return false
})

export const showSatisfactionModal = createSelector([getSettings], settings => {
  const satisfactionQuestion = settings.find(
    i => i.key === 'satisfactionQuestion'
  )
  if (
    satisfactionQuestion &&
    satisfactionQuestion.value &&
    satisfactionQuestion.value !== '0'
  ) {
    const parseValue = JSON.parse(satisfactionQuestion.value)
    const showDate = moment(parseValue.firstDelivery, 'YYYYMMDD').add(2, 'days')
    if (showDate <= moment()) {
      return true
    }
  }
  return false
})

export const showCoronavirusNotification = createSelector(
  [getSettings],
  settings => {
    const showCoronavirusNotification = settings.find(
      i => i.key === 'showCoronavirusNotificationWeb'
    )
    return (
      !showCoronavirusNotification || showCoronavirusNotification.value !== '0'
    )
  }
)

export const showSQSkipModal = createSelector([getSettings], settings => {
  const showSQSkipModal = settings.find(i => i.key === 'closeSQModalWeb')
  return (
    showSQSkipModal && showSQSkipModal.value && showSQSkipModal.value !== '0'
  )
})

// get available dates to show Collection Meals Cards / MerchandiseExperiment
export const getDatesToShowMerchandiseMeals = createSelector(
  [getUpcomingDeliveries],
  upcomingDeliveries => {
    const deliveries = upcomingDeliveries.length

    if (deliveries <= 1) {
      return false
    }

    const nextDeliveries = upcomingDeliveries.reduce((accum, delivery) => {
      if (delivery.available && !delivery.order && delivery.canEdit) {
        return [...accum, delivery]
      }
      return accum
    }, [])

    if (nextDeliveries) {
      return nextDeliveries
    }
    return false
  }
)

export const getAllMerchandiseMeals = state =>
  pathOr(null, ['merchandiseSets', 'data'], state)

// Get the next delivery date with collection Thanksgiving
export const getDeliveryDateToThanksgivingBanner = createSelector(
  [getAllMerchandiseMeals, getDatesToShowMerchandiseMeals, getStore],
  (datesCollection, upcomingDeliveries, store) => {
    const isBannerAvailable = store && [STORE_NY, STORE_LA].includes(store)

    // this collection is defined in Boxes/BoxItems
    const datesCollectionThanksgiving =
      datesCollection &&
      datesCollection.filter(col =>
        col.merchandiseSets.find(elem => elem.id === ID_COLLECTION_THANKSGIVING)
      )

    // this validations match deliveryDates and collectionDates defined in Box/BoxItems
    const deliveryDate =
      upcomingDeliveries &&
      upcomingDeliveries.length > 0 &&
      upcomingDeliveries.find(upcominDel =>
        datesCollectionThanksgiving.find(
          elem => elem.deliveryDate === upcominDel.date
        )
      )

    return isBannerAvailable && deliveryDate
  }
)

export const shouldShowMyDeliveriesWithNewLookAndFeel = createSelector(
  [getSettings],
  settings => {
    const setIndex = settings.findIndex(
      setting =>
        setting.key === 'my-deliveries-new-look-and-feel' &&
        setting.value === '1'
    )
    return setIndex !== -1
  }
)

export const getHappyPathInfo = state =>
  pathOr(null, ['happyPath', 'data'], state)

export const getCurrentDeliveryDateSelector = createSelector(
  [getUpcomingDeliveries],
  upcomingDeliveries => {
    const deliveryDates = getDeliveryDates(upcomingDeliveries)
    const firstDate = Object.keys(deliveryDates)[0]
    return deliveryDates[firstDate]
  }
)
